import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { UserType } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFCheckboxGroup,
  RHFSwitch,
  RHFTextField,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import type { SchoolCalendarFilterArray } from '../../@types/calendar';
import type { ReturnTypeFromUseCalendarCustomTags } from '../../api/tags/list';
import { useUpsertCalendarCustomTag } from '../../api/tags/upsert';

type CustomCalendarTagFormState = {
  name: string;
  schoolCalendars: SchoolCalendarFilterArray;
  active: boolean;
};

export type UpsertCustomCalendarTagModalProps = {
  open: boolean;
  onClose: () => void;
  initialState: Partial<ReturnTypeFromUseCalendarCustomTags> | null;
};

const schoolCalendarOptions: SchoolCalendarFilterArray = [
  UserType.Teacher,
  UserType.Contact,
  UserType.Student,
];

const defaultValues: Partial<CustomCalendarTagFormState> = {
  active: true,
};

export function UpsertCustomCalendarTagModal({
  open,
  onClose,
  initialState,
}: UpsertCustomCalendarTagModalProps) {
  const { t } = useTranslation(['common', 'calendar']);

  const { mutateAsync: saveCustomTag, isPending } =
    useUpsertCalendarCustomTag();
  const { resolver, rules } = useFormValidator<CustomCalendarTagFormState>();
  const { control, handleSubmit, reset } = useForm<CustomCalendarTagFormState>({
    resolver: resolver({
      name: rules.required(),
    }),
    defaultValues,
  });

  const onSubmit = handleSubmit(({ name, schoolCalendars, active }) =>
    saveCustomTag(
      {
        id: initialState?.id,
        tag: name,
        archived: !active,
        staffSchoolCalendar: schoolCalendars.includes(UserType.Teacher),
        contactSchoolCalendar: schoolCalendars.includes(UserType.Contact),
        studentSchoolCalendar: schoolCalendars.includes(UserType.Student),
      },
      {
        onSuccess: onClose,
      },
    ),
  );

  useEffect(() => {
    if (initialState?.id) {
      const schoolCalendars: SchoolCalendarFilterArray = [];
      if (initialState?.staffSchoolCalendar)
        schoolCalendars.push(UserType.Teacher);
      if (initialState?.contactSchoolCalendar)
        schoolCalendars.push(UserType.Contact);
      if (initialState?.studentSchoolCalendar)
        schoolCalendars.push(UserType.Student);

      reset({
        ...defaultValues,
        name: initialState?.tag,
        schoolCalendars,
        active: !initialState?.archived,
      });
    } else {
      reset({ ...defaultValues });
    }
  }, [initialState]);

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      maxWidth="xs"
      onClose={onClose}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {t(
            `calendar:${initialState?.id ? 'editCalendarTag' : 'createCalendarTag'}`,
          )}
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} mt={1}>
            <RHFTextField
              label={t('common:name')}
              controlProps={{
                name: 'name',
                control,
              }}
            />

            <RHFCheckboxGroup
              label={t('calendar:includeOnSchoolCalendarFor')}
              controlProps={{ name: 'schoolCalendars', control }}
              options={schoolCalendarOptions}
              getOptionLabel={(option) =>
                t(`calendar:schoolCalendarUserTypes.${option}`)
              }
            />

            <RHFSwitch
              label={t('common:active')}
              controlProps={{ name: 'active', control }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {t('common:actions.cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isPending}>
            {t('common:actions.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
