import type { ValueGetterParams } from '@ag-grid-community/core';
import dayjs, { type Dayjs } from 'dayjs';

export function getDateFilterTableSettings() {
  return {
    filter: 'agDateColumnFilter',
    filterValueGetter: (params: ValueGetterParams) => {
      const id = params.colDef.field || params.colDef.colId;
      return id ? params.getValue(id) : undefined;
    },
    filterParams: {
      inRangeInclusive: true,
      comparator: (filterLocalDateAtMidnight: Date, cellValue: Dayjs) => {
        const filterDate = dayjs(filterLocalDateAtMidnight);

        if (cellValue.isSame(filterDate, 'day')) {
          return 0;
        }

        if (cellValue.isBefore(filterDate, 'day')) {
          return -1;
        }

        return 1;
      },
    },
  };
}
