import {
  Autocomplete,
  type AutocompleteProps,
  RHFAutocomplete,
  type RHFAutocompleteProps,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useYearGroupEnrollments } from '../../api/year-group-enrollments';

export interface YearGroupEnrollmentSelect {
  partyId: number;
  name: string;
  yearGroupId: number;
}

export type RHFYearGroupEnrollmentsAutocompleteProps<
  TField extends FieldValues,
> = Omit<RHFAutocompleteProps<TField, YearGroupEnrollmentSelect>, 'options'> & {
  academicNamespaceId?: number;
};

export type YearGroupEnrollmentsAutocompleteProps = Omit<
  AutocompleteProps<YearGroupEnrollmentSelect>,
  | 'options'
  | 'optionIdKey'
  | 'optionTextKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
>;

export const RHFYearGroupEnrollmentsAutocomplete = <
  TField extends FieldValues,
>({
  academicNamespaceId,
  ...props
}: RHFYearGroupEnrollmentsAutocompleteProps<TField>) => {
  const { t } = useTranslation(['common']);
  const { data: yearGroupData, isLoading } = useYearGroupEnrollments({
    academicNamespaceIds: academicNamespaceId
      ? [academicNamespaceId]
      : undefined,
  });

  const yearGroupOptions = useMemo(
    () =>
      yearGroupData?.map(({ partyId, name, yearGroupId }) => ({
        partyId,
        yearGroupId,
        name,
      })),
    [yearGroupData],
  );

  return (
    <RHFAutocomplete<TField, YearGroupEnrollmentSelect>
      label={t('common:year')}
      {...props}
      fullWidth
      optionIdKey="partyId"
      optionTextKey="name"
      loading={isLoading}
      options={yearGroupOptions ?? []}
    />
  );
};

export const YearGroupEnrollmentsAutocomplete = (
  props: YearGroupEnrollmentsAutocompleteProps,
) => {
  const { t } = useTranslation(['common']);
  const { data: yearGroupData, isLoading } = useYearGroupEnrollments();

  const yearGroupOptions = useMemo(
    () =>
      yearGroupData?.map(({ partyId, name, yearGroupId }) => ({
        partyId,
        yearGroupId,
        name,
      })),
    [yearGroupData],
  );

  return (
    <Autocomplete
      label={t('common:year')}
      fullWidth
      optionIdKey="partyId"
      optionTextKey="name"
      loading={isLoading}
      options={yearGroupOptions ?? []}
      ChipProps={{ size: 'small' }}
      {...props}
    />
  );
};
