import type {
  DateSelectArg,
  EventClickArg,
  EventDropArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
  type EventResizeDoneArg,
} from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import { Box, Card, Fade, IconButton, Stack } from '@mui/material';
import {
  CalendarEventSource,
  CalendarEventType,
  Calender_RootVisibilityType,
  SearchType,
  UserType,
  usePermissions,
  usePreference,
} from '@tyro/api';
import { useAppShellConfig } from '@tyro/app-shell';
import {
  useDebouncedValue,
  useDisclosure,
  useExportReactToPdf,
  useResponsive,
  useToast,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { ChevronLeftIcon } from '@tyro/icons';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CalendarStyle, CalendarToolbar } from '.';
import type {
  CalendarView,
  SchoolCalendarFilterArray,
} from '../../../@types/calendar';
import { useEditCalendarEvents } from '../../../api/edit-events';
import {
  DEFAULT_CALENDAR_TIMES,
  type ExtendedEventInput,
  useCalendarEvents,
} from '../../../api/events';
import { getCalendarEventsForEdit } from '../../../api/events-for-edit';
import type { ReturnTypeFromUseCalendarCustomTags } from '../../../api/tags/list';
import type { CalendarParty } from '../../../hooks/use-calendar-search-props';
import { getCalendarContent } from './calendar-content';
import { getDayHeaderContent } from './day-header-content';
import { CalendarDetailsPopover } from './details-popover';
import {
  CalendarEventDetailsModal,
  type CalendarEventViewProps,
} from './edit-event-details-modal';
import {
  DEFAULT_END_TIME,
  SELECTABLE_EVENT_CONSTRAINT,
} from './edit-event-details-modal/constants';
import { FilterCalendarPanel } from './filter-calendar-panel';

export interface CalendarProps {
  defaultPartys?: CalendarParty[];
  defaultAttendees?: CalendarParty[];
  defaultDate?: Date;
  defaultEventTypes?: CalendarEventType[];
  defaultCalendarView?: CalendarView;
  defaultSchoolCalendar?: 'user' | 'all';
  newEventType?: CalendarEventType;
}

type CurrentEvent = CalendarEventViewProps['initialState'];
export const DEFAULT_EVENT_TYPES = Object.values(CalendarEventType);

function getDefaultSchoolCalendarsValue(
  value: CalendarProps['defaultSchoolCalendar'],
  userType: UserType,
): SchoolCalendarFilterArray {
  switch (value) {
    case 'all':
      return [UserType.Student, UserType.Contact, UserType.Teacher];
    case 'user': {
      const isInOptions = [
        UserType.Student,
        UserType.Contact,
        UserType.Teacher,
      ].includes(userType);
      return [isInOptions ? userType : UserType.Teacher] as (
        | UserType.Student
        | UserType.Contact
        | UserType.Teacher
      )[];
    }
    default:
      return [];
  }
}

export function Calendar({
  defaultPartys = [],
  defaultAttendees = [],
  defaultDate,
  defaultEventTypes = DEFAULT_EVENT_TYPES,
  defaultCalendarView,
  defaultSchoolCalendar,
  newEventType = CalendarEventType.General,
}: CalendarProps) {
  const { toast } = useToast();
  const { t } = useTranslation(['calendar']);
  const { isStaffUserHasWithAtLeastOnePermission, userType } = usePermissions();
  const [showWeekends] = usePreference('calendar.show-weekends', false);
  const isDesktop = useResponsive('up', 'sm');

  const { isNavExpanded } = useAppShellConfig();
  const { exportHtmlToPdf } = useExportReactToPdf();
  const [selectedPartys, setSelectedPartys] = useState(defaultPartys);
  const calendarCardRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<FullCalendar>(null);
  const [date, setDate] = useState(
    defaultDate ?? dayjs().startOf('week').toDate(),
  );
  const [view, setView] = useState<CalendarView>(
    defaultCalendarView || (isDesktop ? 'timeGridWeek' : 'listWeek'),
  );
  const [visibleEventTypes, setVisibleEventTypes] =
    useState<CalendarEventType[]>(defaultEventTypes);
  const [shownSchoolCalendars, setShownSchoolCalendars] =
    useState<SchoolCalendarFilterArray>(
      getDefaultSchoolCalendarsValue(defaultSchoolCalendar, userType!),
    );
  const [filteredCustomTags, setFilteredCustomTags] = useState<
    ReturnTypeFromUseCalendarCustomTags[]
  >([]);

  const isEditable = useMemo(() => {
    switch (newEventType) {
      case CalendarEventType.RoomBooking:
        return isStaffUserHasWithAtLeastOnePermission([
          'ps:1:calendar:room_booking_admin',
          'ps:1:calendar:room_booking_user',
        ]);

      default:
        return isStaffUserHasWithAtLeastOnePermission([
          'ps:1:calendar:administer_calendar',
          'ps:1:calendar:edit_calendar',
        ]);
    }
  }, [newEventType, isStaffUserHasWithAtLeastOnePermission]);

  const {
    isOpen: isFilterCalendarOpen,
    onClose: onCloseFilterCalendar,
    onToggle: onToggleFilterCalendar,
  } = useDisclosure();

  const {
    value: currentEvent,
    debouncedValue: debouncedCurrentEvent,
    setValue: setCurrentEvent,
  } = useDebouncedValue<CurrentEvent>({
    defaultValue: null,
  });

  const [selectedEventElement, setSelectedEventElement] =
    useState<HTMLElement | null>(null);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const useVisibilityFilter = shownSchoolCalendars.length > 0;

  const { data } = useCalendarEvents(
    {
      date,
      resources: {
        partyIds: selectedPartys
          ?.filter((a) => a.type !== SearchType.Room)
          .map((party) => party.partyId),
        roomIds: selectedPartys
          ?.filter((a) => a.type === SearchType.Room)
          .map((party) => party.partyId),
      },
      eventTypeFilter: visibleEventTypes,
      period: view === 'dayGridMonth' ? 'month' : 'week',
      customTagIds:
        filteredCustomTags.length > 0
          ? filteredCustomTags.map((tag) => tag.id)
          : undefined,
      visibility: useVisibilityFilter
        ? {
            types: [
              Calender_RootVisibilityType.Public,
              ...(selectedPartys.length > 0
                ? [Calender_RootVisibilityType.Internal]
                : []),
            ],
            publicFilter: {
              visibleToContacts: shownSchoolCalendars?.includes(
                UserType.Contact,
              ),
              visibleToStudents: shownSchoolCalendars?.includes(
                UserType.Student,
              ),
              visibleToStaff: shownSchoolCalendars?.includes(UserType.Teacher),
            },
          }
        : undefined,
    },
    isEditable,
  );

  const weekHours = useMemo(() => {
    const currentDate = dayjs(date).startOf('week').format('YYYY-MM-DD');
    return data?.weekHours.get(currentDate);
  }, [data?.weekHours, date]);

  const selectedEvent = useMemo(() => {
    if (selectedEventId) {
      return data?.events?.find((_event) => _event.id === selectedEventId);
    }
  }, [data, selectedEventId]);

  const { mutateAsync: editEvent } = useEditCalendarEvents();

  const handleChangeView = (newView: CalendarView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleSelectRange = (arg: DateSelectArg) => {
    const { start, end, allDay } = arg;
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    setCurrentEvent({
      type: newEventType,
      startDate: dayjs(start),
      allDayEvent: allDay,
      startTime: dayjs(start),
      endTime: dayjs(end),
      participants: defaultAttendees.filter(
        (participant) => participant.attendeeType,
      ),
    });
  };

  const handleSelectEvent = async (arg: EventClickArg) => {
    setSelectedEventElement(arg.el);
    setSelectedEventId(arg.event.id);
  };

  const handleUpdateTimeEvent = async (event: {
    eventId: number;
    start: string;
    end: string;
  }) => {
    toast(t('calendar:saving'), { variant: 'info' });

    const eventForEditData = await getCalendarEventsForEdit({
      eventId: [event.eventId],
    });

    const [eventForEdit] = eventForEditData;
    if (!eventForEdit) return;

    const { startDate, endDate, recurrenceRule } = eventForEdit.schedule;

    const differenceDays = dayjs(startDate).diff(endDate, 'days');
    const newStartDate = dayjs(event.start);

    return editEvent({
      calendarId: eventForEdit.calendarIds[0],
      editSource: CalendarEventSource.Manual,
      events: [
        {
          eventId: eventForEdit.eventId,
          schedule: {
            recurrenceRule,
            endTime: dayjs(event.end).format('HH:mm'),
            startTime: newStartDate.format('HH:mm'),
            startDate: newStartDate.format('YYYY-MM-DD'),
            endDate: recurrenceRule
              ? newStartDate
                  .add(Math.abs(differenceDays), 'days')
                  .format('YYYY-MM-DD')
              : null,
          },
        },
      ],
    });
  };

  const handleResizeEvent = async ({ event, revert }: EventResizeDoneArg) => {
    const eventId =
      (event.extendedProps as ExtendedEventInput)?.originalEvent?.eventId ?? 0;

    try {
      await handleUpdateTimeEvent({
        eventId,
        start: event.startStr,
        end: event.endStr,
      });
    } catch (err) {
      revert();
    }
  };

  const handleDropEvent = async ({ event, revert }: EventDropArg) => {
    const eventId =
      (event.extendedProps as ExtendedEventInput)?.originalEvent?.eventId ?? 0;

    try {
      await handleUpdateTimeEvent({
        eventId,
        start: event.startStr,
        end: event.endStr,
      });
    } catch (err) {
      revert();
    }
  };

  const handleAddEvent = () => {
    setCurrentEvent({
      type: newEventType,
      startDate: dayjs(),
      startTime: dayjs(),
      endTime: dayjs().add(DEFAULT_END_TIME, 'minutes'),
      participants: defaultAttendees.filter(
        (participant) => participant.attendeeType,
      ),
    });
  };

  const handleCloseEditModal = () => {
    setSelectedEventElement(null);
    setCurrentEvent(null);
  };

  const renderedCalendar = (
    <FullCalendar
      weekends={showWeekends}
      allDaySlot={false}
      editable={isEditable}
      droppable={isEditable}
      selectable={isEditable}
      events={data?.events || []}
      resources={data?.resources || []}
      ref={calendarRef}
      firstDay={1}
      rerenderDelay={10}
      eventContent={getCalendarContent}
      initialDate={date}
      initialView={view}
      dayMaxEventRows={3}
      eventDisplay="block"
      headerToolbar={false}
      allDayMaintainDuration
      eventResizableFromStart
      select={handleSelectRange}
      eventDrop={handleDropEvent}
      eventClick={handleSelectEvent}
      eventResize={handleResizeEvent}
      eventMinHeight={48}
      slotEventOverlap={false}
      height="auto"
      selectConstraint={SELECTABLE_EVENT_CONSTRAINT}
      slotMinTime={weekHours?.slotMinTime ?? DEFAULT_CALENDAR_TIMES.start}
      slotMaxTime={weekHours?.slotMaxTime ?? DEFAULT_CALENDAR_TIMES.end}
      businessHours={weekHours?.businessHours}
      nowIndicator
      plugins={[
        listPlugin,
        dayGridPlugin,
        timelinePlugin,
        timeGridPlugin,
        interactionPlugin,
        resourceTimelinePlugin,
        resourceTimeGridPlugin,
      ]}
      dayHeaderContent={getDayHeaderContent}
      resourceAreaWidth={200}
      scrollTime={
        weekHours?.businessHours && weekHours.businessHours.length > 0
          ? weekHours.businessHours[0].startTime
          : DEFAULT_CALENDAR_TIMES.start
      }
      schedulerLicenseKey={
        process.env.FULL_CALENDAR_KEY ??
        'CC-Attribution-NonCommercial-NoDerivatives'
      }
    />
  );

  const onPrint = () => {
    const calendarHtml = calendarCardRef.current?.outerHTML ?? '';
    exportHtmlToPdf(
      calendarHtml,
      `
        .fc-col-header, .fc-timegrid-body, .fc-timegrid-cols > table {
          width: 100% !important;
        }

        .toolbar {
          justify-content: center;
        }

        .toolbar > *:nth-child(1), .toolbar > *:nth-child(3), .toolbar > *:nth-child(2) > button:nth-of-type(1), .toolbar > *:nth-child(2) > button:nth-of-type(3) {
          display: none;
        }
      `,
    );
  };

  useEffect(() => {
    setSelectedPartys(defaultPartys);
  }, [setSelectedPartys, defaultPartys]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      if (isDesktop && view === 'listWeek') {
        calendarApi.changeView('timeGridWeek');
        setView('timeGridWeek');
      } else if (!isDesktop && view === 'timeGridWeek') {
        calendarApi.changeView('listWeek');
        setView('listWeek');
      }
    }
  }, [isDesktop]);

  useEffect(() => {
    // Needed to refresh the calendar after edit calendar panel is open/closed
    const resizeTimeout = setTimeout(() => {
      if (calendarRef.current) {
        // @ts-expect-error
        calendarRef.current.calendar.updateSize();
      }
    }, 300);

    return () => clearTimeout(resizeTimeout);
  }, [isFilterCalendarOpen, isNavExpanded]);

  useEffect(() => {
    const calenderApi = calendarRef.current?.getApi();
    if (calenderApi && defaultDate) {
      calenderApi.gotoDate(defaultDate);
      setDate(defaultDate);
    }
  }, [defaultDate]);

  useEffect(() => {
    setShownSchoolCalendars(
      getDefaultSchoolCalendarsValue(defaultSchoolCalendar, userType!),
    );
  }, [defaultSchoolCalendar]);

  useEffect(() => {}, [defaultSchoolCalendar]);

  useEffect(() => {
    handleCloseEditModal();
  }, [data]);

  return (
    <>
      <Card ref={calendarCardRef}>
        <CalendarStyle>
          <CalendarToolbar
            calendarRef={calendarRef}
            date={date}
            setDate={setDate}
            view={view}
            onEditCalendar={onToggleFilterCalendar}
            onAddEvent={handleAddEvent}
            onChangeView={handleChangeView}
            hasMultipleResources={data && data.numberOfResources > 1}
            canAddEvent={isEditable}
            onPrint={onPrint}
          />
          <Stack direction="row" alignItems="stretch">
            <FilterCalendarPanel
              isOpen={isFilterCalendarOpen}
              selectedPartys={selectedPartys}
              onChangeSelectedPartys={setSelectedPartys}
              visableEventTypes={visibleEventTypes}
              setVisableEventTypes={setVisibleEventTypes}
              schoolCalendarsShown={shownSchoolCalendars}
              onChangeSchoolCalendars={setShownSchoolCalendars}
              filteredCustomTags={filteredCustomTags}
              setFilteredCustomTags={setFilteredCustomTags}
            />
            <Box sx={{ flex: 1, position: 'relative' }}>
              {renderedCalendar}
              <Fade in={isFilterCalendarOpen}>
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: -13,
                    top: 12,
                    borderRadius: '50%',
                    padding: 0,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    backdropFilter: 'blur(6px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    zIndex: 5,
                  }}
                  onClick={onCloseFilterCalendar}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Fade>
            </Box>
          </Stack>
        </CalendarStyle>
      </Card>

      <CalendarDetailsPopover
        eventElementRef={selectedEventElement}
        onClose={handleCloseEditModal}
        onEdit={setCurrentEvent}
        event={selectedEvent}
      />

      <CalendarEventDetailsModal
        isOpen={!!currentEvent}
        initialState={currentEvent || debouncedCurrentEvent}
        onClose={handleCloseEditModal}
        allowAddToPublicCalendar={shownSchoolCalendars.length > 0}
      />
    </>
  );
}
