import { ConfirmDialog } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { ReturnTypeFromUseFees } from '../../api/fees';
import { useMigrateFee } from '../../api/migrate-fee';

export interface ConfirmMigrateFeeModalProps {
  open: boolean;
  fee: ReturnTypeFromUseFees | null;
  onClose: () => void;
}

export function ConfirmMigrateFeeModal({
  open,
  fee,
  onClose,
}: ConfirmMigrateFeeModalProps) {
  const { t } = useTranslation(['fees', 'common']);

  const { mutateAsync: migrateFee } = useMigrateFee();

  const onSubmit = async () =>
    migrateFee({ feeId: fee?.id ?? 0 }, { onSuccess: onClose });

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
      title={t('fees:migrateToNextYear')}
      description={t('fees:migrateFeeDescription', {
        feeName: fee?.name ?? '',
      })}
      confirmText={t('fees:migrate')}
    />
  );
}
