import {
  ActionMenu,
  type GridOptions,
  type ICellRendererParams,
  type ReturnTypeDisplayName,
  Table,
  commonActionMenuProps,
  useDisclosure,
  usePreferredNameLayout,
  useToast,
} from '@tyro/core';
import {
  type ReturnTypeFromUseFormatNumber,
  type TFunction,
  useFormatNumber,
  useTranslation,
} from '@tyro/i18n';
import { useCallback, useMemo, useState } from 'react';

import { Box, Fade, useTheme } from '@mui/material';
import { type PaymentFilter, PaymentStatus, ReceiptType } from '@tyro/api';
import { ReceiptIcon, RefundIcon, VerticalDotsIcon } from '@tyro/icons';
import dayjs from 'dayjs';
import {
  type ReturnTypeFromUsePayments,
  usePayments,
} from '../../api/payments';
import { getPrintFees } from '../../api/print-fees';
import { RefundModal } from '../payments/refund-modal';

enum TransactionType {
  Payment = 'PAYMENT',
  Refund = 'REFUND',
}

type Transactions = ReturnTypeFromUsePayments & {
  transactionType: TransactionType;
  rowId: string;
};

const getTransactionColumns = (
  t: TFunction<('common' | 'fees')[], undefined, ('common' | 'fees')[]>,
  formatCurrency: ReturnTypeFromUseFormatNumber['formatCurrency'],
  onRefundPayment: (transaction: Transactions[]) => void,
  onPrintReceipt: (transaction: Transactions) => void,
  displayName: ReturnTypeDisplayName,
): GridOptions<Transactions>['columnDefs'] => [
  {
    field: 'transactionType',
    headerName: t('fees:transactionType'),
    valueGetter: ({ data }) =>
      data?.transactionType
        ? t(`fees:transactionTypes.${data.transactionType}`)
        : '-',
    lockVisible: true,
  },
  {
    field: 'student',
    headerName: t('common:name'),
    valueGetter: ({ data }) =>
      data?.student ? displayName(data?.student?.person) : null,
    valueFormatter: ({ data }) =>
      data?.student ? displayName(data?.student?.person) : '-',
  },
  {
    field: 'fee.name',
    headerName: t('fees:feeName'),
    valueGetter: ({ data }) => data?.fee?.name ?? '-',
  },
  {
    field: 'paymentIntentId',
    headerName: t('fees:stripeId'),
    valueGetter: ({ data }) => data?.paymentIntentId ?? '-',
  },
  {
    field: 'transactionDate',
    headerName: t('fees:transactionDate'),
    valueFormatter: ({ data }) =>
      data?.transactionDate
        ? dayjs(data.transactionDate).format('LLL[:]ss')
        : '-',
    valueGetter: ({ data }) =>
      data?.transactionDate
        ? dayjs(data.transactionDate).format('LLL[:]ss')
        : '-',
    comparator: (dateA: string, dateB: string) =>
      dayjs(dateA).unix() - dayjs(dateB).unix(),
    sort: 'desc',
  },
  {
    field: 'id',
    headerName: t('fees:receiptNo'),
  },
  {
    field: 'amount',
    headerName: t('fees:amount'),
    valueGetter: ({ data }) => data?.amount ?? 0,
    valueFormatter: ({ value }) => formatCurrency(value),
    comparator: (a: number, b: number) => a - b,
    aggFunc: 'sum',
    type: 'numericColumn',
  },
  {
    field: 'serviceFeesAmount',
    headerName: t('fees:serviceFees'),
    valueGetter: ({ data }) =>
      typeof data?.serviceFeesAmount === 'number' &&
      data?.transactionType === TransactionType.Payment
        ? data?.serviceFeesAmount ?? 0
        : null,
    valueFormatter: ({ value }) => formatCurrency(value ?? 0),
    comparator: (a: number, b: number) => a - b,
    aggFunc: 'sum',
    type: 'numericColumn',
  },
  {
    colId: 'total',
    headerName: t('common:total'),
    valueGetter: ({ data }) =>
      (data?.amount ?? 0) + (data?.serviceFeesAmount ?? 0),
    valueFormatter: ({ value }) => formatCurrency(value ?? 0),
    comparator: (a: number, b: number) => a - b,
    aggFunc: 'sum',
    type: 'numericColumn',
  },
  {
    field: 'paymentMethod',
    headerName: t('fees:paymentType'),
    enableRowGroup: true,
    valueGetter: ({ data }) =>
      data?.paymentMethod
        ? t(`fees:paymentMethods.${data.paymentMethod}`)
        : '-',
  },
  {
    field: 'note',
    headerName: t('common:note'),
    valueGetter: ({ data }) => data?.note ?? '-',
  },
  {
    field: 'payee',
    headerName: t('fees:payeeName'),
    valueGetter: ({ data }) => (data?.payee ? displayName(data?.payee) : '-'),
    enableRowGroup: true,
  },
  {
    ...commonActionMenuProps,
    cellRenderer: ({ data }: ICellRendererParams<Transactions>) => {
      if (!data) return null;
      const hasBeenRefunded =
        data.transactionType === TransactionType.Refund ||
        data.amount === data.amountRefunded;

      return (
        <ActionMenu
          iconOnly
          buttonIcon={<VerticalDotsIcon />}
          menuItems={[
            {
              label: t('fees:receipt'),
              icon: <ReceiptIcon />,
              disabled: hasBeenRefunded,
              disabledTooltip: hasBeenRefunded
                ? t('fees:youCantPrintReceipt')
                : undefined,
              onClick: () => onPrintReceipt(data),
            },
            {
              label: t('fees:refund'),
              icon: <RefundIcon />,
              disabled: hasBeenRefunded,
              disabledTooltip: hasBeenRefunded
                ? t('fees:youCanNotRefundAsPaymentHaveAlreadyBeenRefunded')
                : undefined,
              onClick: () => onRefundPayment([data]),
            },
          ]}
        />
      );
    },
  },
];

type FeeTransactionsTableProps = {
  filter: PaymentFilter;
};

export function FeeTransactionsTable({ filter }: FeeTransactionsTableProps) {
  const { t } = useTranslation(['common', 'fees']);
  const { formatCurrency } = useFormatNumber();

  const { toast } = useToast();
  const { palette } = useTheme();

  const { data: paymentsData = [], isLoading } = usePayments({
    ...filter,
    status: PaymentStatus.Succeeded,
  });

  const [selectedPaymentsIds, setSelectedPaymentsIds] = useState<Set<string>>(
    new Set(),
  );

  const [paymentsToRefund, setPaymentsToRefund] = useState<Transactions[]>([]);

  const {
    isOpen: isRefundModalOpen,
    onOpen: onOpenRefundModal,
    onClose: onCloseRefundModal,
  } = useDisclosure();

  const transactions = useMemo<Transactions[]>(
    () =>
      paymentsData.flatMap((payment) => [
        {
          ...payment,
          rowId: JSON.stringify({ paymentId: payment.id }),
          transactionType: TransactionType.Payment,
        },
        ...payment.refunds.map((refund) => ({
          ...payment,
          rowId: JSON.stringify({ paymentId: payment.id, refund: refund.id }),
          amount: -refund.amount,
          serviceFeesAmount: 0,
          transactionDate: refund.refundDate,
          transactionType: TransactionType.Refund,
        })),
      ]),
    [paymentsData],
  );

  const selectedTransactions = useMemo(
    () =>
      transactions.filter((payment) => selectedPaymentsIds.has(payment.rowId)),
    [transactions, selectedPaymentsIds],
  );

  const handleRefundPayments = useCallback((txs: Transactions[]) => {
    setPaymentsToRefund(txs);
    onOpenRefundModal();
  }, []);

  const handlePrintReceipt = useCallback(async (tx: Transactions) => {
    try {
      const printResponse = await getPrintFees({
        feeId: tx.fee.id,
        type: ReceiptType.Transaction,
        studentId: tx.studentPartyId,
        paymentIntentId: tx.paymentIntentId,
      });

      if (printResponse?.print_fees?.url)
        window.open(printResponse.print_fees.url, '_blank', 'noreferrer');
    } catch {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    }
  }, []);

  const somePaymentsHaveBeenRefunded = selectedTransactions.some(
    (payment) =>
      payment.transactionType === TransactionType.Refund ||
      payment.amount === payment.amountRefunded,
  );

  const { displayName } = usePreferredNameLayout();

  const columns = useMemo(
    () =>
      getTransactionColumns(
        t,
        formatCurrency,
        handleRefundPayments,
        handlePrintReceipt,
        displayName,
      ),
    [t, formatCurrency, handleRefundPayments, handlePrintReceipt, displayName],
  );

  return (
    <>
      <Table
        isLoading={isLoading}
        rowData={transactions}
        columnDefs={columns}
        getRowId={({ data }) => data.rowId}
        rowSelection="multiple"
        onRowSelection={(selectedRows) => {
          setSelectedPaymentsIds(new Set(selectedRows.map((row) => row.rowId)));
        }}
        getRowStyle={(params) => {
          if (params.data?.transactionType === TransactionType.Payment) {
            return {
              backgroundColor: palette.slate[100],
            };
          }
        }}
        rightAdornment={
          <Fade in={selectedPaymentsIds.size > 0} unmountOnExit>
            <Box>
              <ActionMenu
                menuItems={[
                  {
                    label: t('fees:refund'),
                    icon: <RefundIcon />,
                    disabled: somePaymentsHaveBeenRefunded,
                    disabledTooltip: somePaymentsHaveBeenRefunded
                      ? t(
                          'fees:youCanNotRefundAsSomePaymentsHaveAlreadyBeenRefunded',
                        )
                      : undefined,
                    onClick: () => handleRefundPayments(selectedTransactions),
                  },
                ]}
              />
            </Box>
          </Fade>
        }
      />
      <RefundModal
        isOpen={isRefundModalOpen}
        payments={paymentsToRefund}
        onClose={onCloseRefundModal}
      />
    </>
  );
}
