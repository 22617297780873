import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
} from '@mui/material';
import { CalendarEventType, UserType, usePermissions } from '@tyro/api';
import { Autocomplete } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { type Dispatch, type SetStateAction, useId } from 'react';
import type { SchoolCalendarFilterArray } from '../../../../@types/calendar';
import {
  type ReturnTypeFromUseCalendarCustomTags,
  useCalendarCustomTags,
} from '../../../../api/tags/list';
import { CalendarSearch, type CalendarSearchProps } from './calendar-search';

interface FilterCalendarPanelProps extends CalendarSearchProps {
  isOpen: boolean;
  visableEventTypes: CalendarEventType[];
  setVisableEventTypes: Dispatch<SetStateAction<CalendarEventType[]>>;
  schoolCalendarsShown: SchoolCalendarFilterArray;
  onChangeSchoolCalendars: Dispatch<SetStateAction<SchoolCalendarFilterArray>>;
  filteredCustomTags: ReturnTypeFromUseCalendarCustomTags[];
  setFilteredCustomTags: Dispatch<
    SetStateAction<ReturnTypeFromUseCalendarCustomTags[]>
  >;
}

export function FilterCalendarPanel({
  isOpen,
  selectedPartys,
  onChangeSelectedPartys,
  visableEventTypes,
  setVisableEventTypes,
  schoolCalendarsShown,
  onChangeSchoolCalendars,
  filteredCustomTags,
  setFilteredCustomTags,
}: FilterCalendarPanelProps) {
  const id = useId();
  const { t } = useTranslation(['calendar']);
  const { hasPermission, userType } = usePermissions();
  const userTypeWithFallback =
    userType === UserType.Tyro ? UserType.Teacher : userType;

  const { data: customTags, isLoading: isCustomTagsLoading } =
    useCalendarCustomTags({}, { enabled: isOpen });

  const toggleVisableEventType = (
    toggledType: CalendarEventType,
    checked: boolean,
  ) => {
    if (checked) {
      setVisableEventTypes((previousTypes) => [...previousTypes, toggledType]);
    } else {
      setVisableEventTypes((previousTypes) =>
        previousTypes.filter((type) => type !== toggledType),
      );
    }
  };

  return (
    <Collapse orientation="horizontal" in={isOpen}>
      <Stack
        sx={{
          width: 280,
          borderTopColor: 'divider',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          p: 2,
          pt: 4,
          overflowY: 'auto',
        }}
        spacing={2}
      >
        <CalendarSearch
          selectedPartys={selectedPartys}
          onChangeSelectedPartys={onChangeSelectedPartys}
        />
        {hasPermission('ps:1:calendar:can_view_all_public_calendars') ? (
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">
              {t('calendar:schoolCalendarFor')}
            </FormLabel>
            <FormGroup>
              {(
                [UserType.Teacher, UserType.Contact, UserType.Student] as const
              ).map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={schoolCalendarsShown.includes(
                        type as SchoolCalendarFilterArray[number],
                      )}
                      onChange={(_, checked) => {
                        onChangeSchoolCalendars((prev) => {
                          const newValue = [...prev];
                          if (checked) {
                            newValue.push(
                              type as SchoolCalendarFilterArray[number],
                            );
                          } else {
                            newValue.splice(
                              newValue.indexOf(
                                type as SchoolCalendarFilterArray[number],
                              ),
                              1,
                            );
                          }
                          return newValue;
                        });
                      }}
                      name={type}
                    />
                  }
                  label={t(`calendar:schoolCalendarUserTypes.${type}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={schoolCalendarsShown.includes(
                  userTypeWithFallback as SchoolCalendarFilterArray[number],
                )}
                onChange={(_, checked) => {
                  onChangeSchoolCalendars(
                    checked
                      ? ([userTypeWithFallback] as SchoolCalendarFilterArray)
                      : [],
                  );
                }}
                name={userTypeWithFallback}
              />
            }
            label={t('calendar:showSchoolCalendar')}
          />
        )}
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">{t('calendar:eventTypes')}</FormLabel>
          <FormGroup>
            {Object.values(CalendarEventType).map((type) => (
              <FormControlLabel
                key={type}
                control={
                  <Checkbox
                    checked={visableEventTypes.includes(type)}
                    onChange={(_, checked) => {
                      toggleVisableEventType(type, checked);
                    }}
                    name={type}
                  />
                }
                label={t(`calendar:calendarEventTypes.${type}`)}
              />
            ))}
          </FormGroup>
        </FormControl>
        <Box>
          <FormLabel htmlFor={`${id}-tags`}>{t('calendar:tags')}</FormLabel>
          <Autocomplete
            id={`${id}-tags`}
            label=""
            options={customTags ?? []}
            loading={isCustomTagsLoading}
            optionIdKey="id"
            optionTextKey="tag"
            multiple
            value={filteredCustomTags}
            onChange={(_, newValue) =>
              setFilteredCustomTags(
                newValue as ReturnTypeFromUseCalendarCustomTags[],
              )
            }
            inputProps={{
              variant: 'filled',
              hiddenLabel: true,
              fullWidth: true,
            }}
          />
        </Box>
      </Stack>
    </Collapse>
  );
}
