import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { NewTemplateIcon } from '@tyro/icons';
import { redirect } from 'react-router-dom';
import { getTemplates } from './api/templates';
import { templatesPaths } from './components/container';

const Templates = lazyWithRetry(() => import('./pages/index'));
const TemplatesContainer = lazyWithRetry(
  () => import('./components/container'),
);
const CreateTemplatePage = lazyWithRetry(() => import('./pages/create'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:management.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'templates',
        title: t('navigation:management.templates.title'),
        icon: <NewTemplateIcon />,
        hasAccess: ({ isStaffUserWithPermission }) =>
          isStaffUserWithPermission('api:templating:read:template'),
        children: [
          {
            type: NavObjectType.MenuLink,
            path: 'letter',
            title: t('navigation:management.templates.letter'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./list'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'list',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('api:templating:read:template'),
                element: <TemplatesContainer />,
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./students'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: ':context',
                    element: <Templates />,
                    loader: ({ params }) => {
                      const context =
                        templatesPaths[params?.context || 'students'];

                      if (!context) {
                        throw404Error();
                      }

                      return getTemplates({ context });
                    },
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'email',
            title: t('navigation:management.templates.email'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./list'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'list',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('api:templating:read:template'),
                element: <TemplatesContainer />,
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./students'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: ':context',
                    element: <Templates />,
                    loader: ({ params }) => {
                      const context =
                        templatesPaths[params?.context || 'students'];

                      if (!context) {
                        throw404Error();
                      }

                      return getTemplates({ context });
                    },
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'sms',
            title: t('navigation:management.sms'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./list'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'list',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('api:templating:read:template'),
                element: <TemplatesContainer />,
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./students'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: ':context',
                    element: <Templates />,
                    loader: ({ params }) => {
                      const context =
                        templatesPaths[params?.context || 'students'];

                      if (!context) {
                        throw404Error();
                      }

                      return getTemplates({ context });
                    },
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'create',
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission('api:templating:write:template'),
            element: <CreateTemplatePage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'edit/:id',
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission('api:templating:write:template'),
            element: <CreateTemplatePage />,
            loader: ({ params }) => {
              const id = getNumber(params.id);

              if (!id) {
                throw404Error();
              }
              return getTemplates({
                ids: [id],
              });
            },
          },
        ],
      },
    ],
  },
];
