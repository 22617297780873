import { Stack } from '@mui/material';
import type { TemplatingContextType } from '@tyro/api';
import { TemplatingContextType as ContextType } from '@tyro/api';
import { IconButtonWithTooltip } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { NewTemplateIcon } from '@tyro/icons';
import type { Dispatch, SetStateAction } from 'react';

interface InsertTemplateButtonProps {
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  contextType: TemplatingContextType | undefined;
}

export function InsertTemplateButton({
  onOpenChange,
  contextType,
}: InsertTemplateButtonProps) {
  const { t } = useTranslation(['common']);

  const isAllowedSelectTemplate =
    contextType === ContextType.Person ||
    contextType === ContextType.Contact ||
    !contextType;

  return (
    <Stack justifyContent="center" alignItems="center">
      <IconButtonWithTooltip
        title={
          contextType
            ? t('common:tooltipTitles.insertTemplate')
            : t('common:tooltipTitles.insertTemplateDisabled')
        }
        placement="top"
        onClick={() => {
          onOpenChange(true);
        }}
        disabled={isAllowedSelectTemplate}
      >
        <NewTemplateIcon />
      </IconButtonWithTooltip>
    </Stack>
  );
}
