import {
  Form_FormFieldItemType,
  type Forms_FormFieldItem,
  type Forms_FormFieldSelectOptions,
  type Forms_FormView,
  type Forms_SubmitFormFieldInput,
} from '@tyro/api';
import dayjs from 'dayjs';
import type { DefaultValues } from 'react-hook-form';

export type FormState = {
  [id: Forms_SubmitFormFieldInput['fieldId']]: unknown;
};

export const sortFields = (formSettings: Forms_FormView | undefined) => {
  if (!formSettings) return [];

  return formSettings.fields
    ?.flatMap<Forms_FormFieldItem>(({ fields }) =>
      fields.flatMap((field) => {
        if (field.__typename === 'Forms_FormFieldItem') {
          return field;
        }

        if (field.__typename === 'Forms_FormFieldSubGroup') {
          return field.fields;
        }

        return [];
      }),
    )
    .map((field) => ({
      ...field,
      options: field.options.sort((a, b) => Number(a.id) - Number(b.id)),
    }));
};

export const convertDefaultValueFromBackendResponse = (
  formSettings: Forms_FormView | undefined,
) => {
  const getDefaultValueFormat = (
    defaultValue: string,
    inputType: Form_FormFieldItemType,
  ) => {
    switch (inputType) {
      case Form_FormFieldItemType.FormConfirmation:
        return String(defaultValue === 'true');
      case Form_FormFieldItemType.Date:
      case Form_FormFieldItemType.Datetime:
        return defaultValue ? dayjs(defaultValue) : undefined;
      case Form_FormFieldItemType.Time:
        return defaultValue ? dayjs(defaultValue, 'HH:mm:ss') : undefined;
      case Form_FormFieldItemType.Inputnumber:
        return Number(defaultValue);
      case Form_FormFieldItemType.Multiselect:
      case Form_FormFieldItemType.Checkbox:
        if (defaultValue?.startsWith('[') && defaultValue.endsWith(']')) {
          const asArray = JSON.parse(defaultValue) as (number | string)[];
          return asArray.map((v) => v.toString());
        }
        return defaultValue?.split(',');
      default:
        return defaultValue ?? '';
    }
  };

  return (
    formSettings?.fields?.reduce<DefaultValues<FormState>>((acc, group) => {
      for (const field of group.fields ?? []) {
        if (field.__typename === 'Forms_FormFieldItem' && field.defaultValue) {
          acc[field.id] = getDefaultValueFormat(field.defaultValue, field.type);
        }

        if (field.__typename === 'Forms_FormFieldSubGroup') {
          for (const subField of field.fields ?? []) {
            if (subField.defaultValue) {
              acc[subField.id] = getDefaultValueFormat(
                subField.defaultValue,
                subField.type,
              );
            }
          }
        }
      }
      return acc;
    }, {}) ?? {}
  );
};

export const convertValueForBackendRequest = (
  formValue: unknown,
  inputType: Form_FormFieldItemType,
) => {
  switch (inputType) {
    case Form_FormFieldItemType.Multiselect:
    case Form_FormFieldItemType.Checkbox: {
      const valueAsArray = Array.isArray(formValue) ? formValue : [formValue];
      return valueAsArray
        .map((value) => {
          if (typeof value === 'string') {
            return value;
          }
          return (value as Forms_FormFieldSelectOptions)?.id;
        })
        .join(',');
    }
    case Form_FormFieldItemType.Radio:
    case Form_FormFieldItemType.Select: {
      if (typeof formValue === 'string') {
        return formValue;
      }

      return (formValue as Forms_FormFieldSelectOptions)?.id ?? null;
    }
    case Form_FormFieldItemType.FormConfirmation:
      return String(formValue === 'true');
    case Form_FormFieldItemType.Date:
      return formValue
        ? dayjs(formValue as dayjs.Dayjs).format('YYYY-MM-DD')
        : null;
    case Form_FormFieldItemType.Datetime:
      return formValue
        ? dayjs(formValue as dayjs.Dayjs).format('YYYY-MM-DDTHH:mm:ss')
        : null;
    case Form_FormFieldItemType.Time:
      return formValue
        ? dayjs(formValue as dayjs.Dayjs).format('HH:mm:ss')
        : null;
    default:
      return String(formValue ?? '');
  }
};
