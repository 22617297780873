import {
  type NavObjectFunction,
  NavObjectType,
  lazyWithRetry,
} from '@tyro/core';
import { BellIcon } from '@tyro/icons';
import { getAdminNotifications } from './api/admin';

const NotificationList = lazyWithRetry(() => import('./pages/index'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:management.title'),
    children: [
      {
        type: NavObjectType.RootLink,
        path: 'notifications',
        hasAccess: ({ isTyroUser }) => isTyroUser,
        loader: () => {
          return getAdminNotifications({
            pagination: {
              limit: 1000,
            },
          });
        },
        title: t('navigation:management.notifications'),
        icon: <BellIcon />,
        element: <NotificationList />,
      },
    ],
  },
];
