import {
  CommentType,
  CommenterUserType,
  ExtraFieldType,
  GradeType,
  type SaveAssessmentResultInput,
  type StudentAssessmentExclusionInput,
  type UsePermissionsReturn,
  getPersonProfileLink,
  usePermissions,
  useUser,
} from '@tyro/api';
import {
  type BulkEditedRows,
  type GridOptions,
  type ICellRendererParams,
  ListNavigatorType,
  type PartyListNavigatorMenuItemParams,
  type ReturnOfUseToast,
  type ReturnTypeDisplayName,
  Table,
  TableBooleanValue,
  TableSwitch,
  type ValueSetterParams,
  useListNavigatorSettings,
  usePreferredNameLayout,
  useToast,
} from '@tyro/core';
import { type TFunction, useFormatNumber, useTranslation } from '@tyro/i18n';
import { StudentTableAvatar } from '@tyro/people';
import set from 'lodash/set';
import { useCallback, useMemo, useRef } from 'react';
import {
  type ReturnTypeFromUseAssessmentResults,
  useAssessmentResults,
  useUpdateAssessmentResult,
} from '../../api/assessment-results';
import {
  type ReturnTypeFromUseAssessmentById,
  useAssessmentById,
} from '../../api/assessments';
import {
  type ReturnTypeFromUseCommentBanksWithComments,
  useCommentBanksWithComments,
} from '../../api/comment-bank';
import {
  type ReturnTypeFromUseGrades,
  useGrades,
} from '../../api/grade-sets/grades';
import { updateStudentAssessmentExclusion } from '../../api/student-assessment-exclusion';
import { getCommentFields } from '../../utils/get-comment-cells';
import { getExtraFields } from '../../utils/get-extra-fields';
import { getResultFields } from '../../utils/get-result-fields';
import { getStudyLevelCell } from '../../utils/get-study-level-cell';
type EditResultsProps = {
  academicNamespaceId?: number;
  assessmentId?: number;
  subjectGroupId?: number;
  subjectGroupName?: string;
  tableId: string;
};

const editAssessmentPermission = 'ps:1:assessment:write_assessment_result';

const getColumnDefs = (
  t: TFunction<
    ('common' | 'assessments')[],
    undefined,
    ('common' | 'assessments')[]
  >,
  permissions: UsePermissionsReturn,
  onBeforeNavigateProfile: () => void,
  displayName: ReturnTypeDisplayName,
  assessmentData: ReturnTypeFromUseAssessmentById | null | undefined,
  gradeById: Map<number, ReturnTypeFromUseGrades>,
  commentBanks: ReturnTypeFromUseCommentBanksWithComments | undefined,
  formatPercent: (value: number) => string,
  toast: ReturnOfUseToast['toast'],
): GridOptions<ReturnTypeFromUseAssessmentResults>['columnDefs'] => {
  const showResult = [GradeType.Percentage, GradeType.Both].includes(
    assessmentData?.gradeType ?? GradeType.None,
  );
  const showGrade = [GradeType.GradeSet, GradeType.Both].includes(
    assessmentData?.gradeType ?? GradeType.None,
  );

  return [
    {
      field: 'student',
      headerName: t('common:name'),
      valueGetter: ({ data }) => displayName(data?.student?.person),
      cellRenderer: ({
        data,
      }: ICellRendererParams<ReturnTypeFromUseAssessmentResults>) =>
        data?.student ? (
          <StudentTableAvatar
            person={data?.student?.person}
            isPriorityStudent={!!data?.student?.extensions?.priority}
            hasSupportPlan={!!data?.student?.extensions?.aen}
            to={getPersonProfileLink(data?.student?.person)}
            onBeforeNavigate={onBeforeNavigateProfile}
          />
        ) : null,
      cellClass: 'cell-value-visible',
      sort: 'asc',
      pinned: 'left',
      lockVisible: true,
    },
    {
      field: 'absent',
      headerName: t('common:absent'),
      editable: permissions.hasPermission(editAssessmentPermission),
      cellClass: ['ag-editable-cell', 'disable-cell-edit-style'],
      cellEditor: TableSwitch,
      cellRenderer: ({
        data,
      }: ICellRendererParams<ReturnTypeFromUseAssessmentResults>) => (
        <TableBooleanValue value={!!data?.absent} />
      ),
      valueSetter: (
        params: ValueSetterParams<ReturnTypeFromUseAssessmentResults, boolean>,
      ) => {
        const { newValue } = params;
        if (newValue && !params?.isEditCheckCall) {
          if (showResult) params?.node?.setDataValue('result', null);
          if (showGrade) params?.node?.setDataValue('gradeId', null);
        }
        set(params.data ?? {}, 'absent', Boolean(params.newValue));
        return true;
      },
    },
    {
      field: 'examinable',
      headerName: t('assessments:examinable'),
      editable: permissions.hasPermission(editAssessmentPermission),
      cellClass: ['ag-editable-cell', 'disable-cell-edit-style'],
      cellEditor: TableSwitch,
      cellRenderer: ({
        data,
      }: ICellRendererParams<ReturnTypeFromUseAssessmentResults>) => (
        <TableBooleanValue value={!!data?.examinable} />
      ),
      valueSetter: (
        params: ValueSetterParams<ReturnTypeFromUseAssessmentResults, boolean>,
      ) => {
        const { newValue } = params;
        if (!newValue && !params?.isEditCheckCall) {
          if (showResult) params?.node?.setDataValue('result', null);
          if (showGrade) params?.node?.setDataValue('gradeId', null);
        }
        set(params.data ?? {}, 'examinable', Boolean(params.newValue));
        return true;
      },
    },
    {
      field: 'studentClassGroup',
      headerName: t('common:class'),
    },
    getStudyLevelCell({
      assessmentData,
      gradeById,
      permissions,
      t,
      editAssessmentPermission,
    }),
    ...getResultFields(
      assessmentData,
      gradeById,
      permissions,
      t,
      formatPercent,
    ),
    ...getCommentFields(assessmentData, permissions, commentBanks, t, toast),
    ...getExtraFields(assessmentData?.extraFields, permissions, commentBanks),
  ];
};

// Used for assessment and class assessment results
export const AssessmentResultsTable = ({
  academicNamespaceId,
  assessmentId,
  subjectGroupId,
  subjectGroupName,
  tableId,
}: EditResultsProps) => {
  const { t } = useTranslation(['assessments', 'common']);
  const permissions = usePermissions();
  const { displayName } = usePreferredNameLayout();
  const { formatPercent } = useFormatNumber();
  const { activeProfile } = useUser();
  const { toast } = useToast();
  const assessmentResultsFilter = {
    assessmentId: assessmentId ?? 0,
    subjectGroupIds: [subjectGroupId ?? 0],
  };
  const visibleDataRef =
    useRef<() => ReturnTypeFromUseAssessmentResults[]>(null);

  const { data: studentResults } = useAssessmentResults(
    academicNamespaceId ?? 0,
    assessmentId ? assessmentResultsFilter : null,
  );
  const { data: assessmentData, isLoading: isLoadingAssessmentData } =
    useAssessmentById({
      academicNameSpaceId: academicNamespaceId ?? 0,
      ids: [assessmentId ?? 0],
    });

  const { data: allGrades } = useGrades(
    {
      gradeSetId: assessmentData?.gradeSets?.[0]?.id ?? 0,
    },
    {
      enabled: !!assessmentData,
    },
  );

  const gradeById = useMemo(
    () => new Map(allGrades?.map((grade) => [grade.id, grade])),
    [allGrades],
  );

  const commentBanksRequired = useMemo(() => {
    if (!assessmentData) return [];

    const collectedCommentBanks =
      assessmentData?.extraFields?.reduce<number[]>((acc, extraField) => {
        if (
          extraField?.extraFieldType === ExtraFieldType.CommentBank &&
          extraField?.commentBankId
        ) {
          acc.push(extraField.commentBankId);
        }

        return acc;
      }, []) ?? [];

    if (assessmentData.commentBank?.commentBankId) {
      collectedCommentBanks.push(assessmentData.commentBank.commentBankId);
    }

    return collectedCommentBanks;
  }, [assessmentData]);

  const { data: commentBanks } = useCommentBanksWithComments({
    ids: commentBanksRequired,
  });

  const { mutateAsync: updateAssessmentResult } = useUpdateAssessmentResult(
    academicNamespaceId ?? 0,
    assessmentResultsFilter,
  );

  const { storeList } =
    useListNavigatorSettings<PartyListNavigatorMenuItemParams>({
      type: ListNavigatorType.Student,
    });

  const onBeforeNavigateProfile = useCallback(() => {
    storeList(
      subjectGroupName,
      visibleDataRef
        .current?.()
        .map(({ student: { person }, studentPartyId, studentClassGroup }) => ({
          id: studentPartyId,
          type: 'person',
          name: displayName(person),
          firstName: person.firstName,
          lastName: person.lastName,
          avatarUrl: person.avatarUrl,
          caption: studentClassGroup,
        })),
    );
  }, [subjectGroupName]);

  const columnDefs = useMemo(
    () =>
      getColumnDefs(
        t,
        permissions,
        onBeforeNavigateProfile,
        displayName,
        assessmentData,
        gradeById,
        commentBanks,
        (value: number) => formatPercent(value),
        toast,
      ),
    [
      t,
      permissions,
      onBeforeNavigateProfile,
      displayName,
      assessmentData,
      gradeById,
      commentBanks,
      academicNamespaceId,
      formatPercent,
      toast,
    ],
  );

  const saveAssessmentResult = async (
    data: BulkEditedRows<
      ReturnTypeFromUseAssessmentResults,
      | 'examinable'
      | 'extraFields'
      | 'result'
      | 'gradeResult'
      | 'targetResult'
      | 'targetGradeResult'
      | 'teacherComment.comment'
      | 'subjectGroup.irePP.examinable'
    >,
  ) => {
    const examinableChanges = Object.entries(data).reduce<
      StudentAssessmentExclusionInput[]
    >((acc, [key, value]) => {
      if (value.examinable) {
        acc.push({
          assessmentId: assessmentId ?? 0,
          studentPartyId: Number(key),
          subjectGroupId: subjectGroupId ?? 0,
          excluded: !value.examinable?.newValue,
        });
      }
      return acc;
    }, []);

    if (examinableChanges.length > 0) {
      await updateStudentAssessmentExclusion(
        academicNamespaceId ?? 0,
        examinableChanges,
      );
    }

    const results = studentResults?.reduce<SaveAssessmentResultInput[]>(
      (acc, result) => {
        const editedColumns = data[result.studentPartyId];
        if (editedColumns) {
          const newResult = {
            ...result,
            subjectGroupId: subjectGroupId ?? 0,
            assessmentId: assessmentData?.id ?? 0,
          };

          for (const [key, { newValue }] of Object.entries(editedColumns)) {
            if (key.startsWith('extraFields')) {
              const splitKey = key.split('.');
              const extraFieldId = Number(splitKey[1]);
              const extraFieldProperty = splitKey[2] as
                | 'commentBankCommentId'
                | 'result';

              if (newResult.extraFields?.[extraFieldId]) {
                set(
                  newResult.extraFields[extraFieldId],
                  extraFieldProperty,
                  newValue ?? null,
                );
              } else {
                set(newResult.extraFields, extraFieldId, {
                  assessmentExtraFieldId: extraFieldId,
                  ...(extraFieldProperty === 'commentBankCommentId'
                    ? {
                        commentBankCommentId: newValue ?? null,
                      }
                    : { result: newValue ?? null }),
                });
              }
            } else {
              set(newResult, key, newValue ?? null);

              if (['gradeId', 'targetGradeId'].includes(key)) {
                const resultKey = key === 'gradeId' ? 'result' : 'targetResult';
                const noHaveResultChange = Object.keys(editedColumns).every(
                  (colKey) => colKey !== resultKey,
                );
                if (noHaveResultChange) {
                  const currentResult = newResult[resultKey];
                  const grade = gradeById.get(newValue as number);

                  if (!grade) {
                    set(newResult, resultKey, null);
                  } else if (
                    !currentResult ||
                    currentResult < grade.start ||
                    currentResult > grade.end
                  ) {
                    set(newResult, resultKey, grade.end);
                  }
                }
              }
            }
          }

          const teacherWrittenComment = newResult.teacherComment?.comment;
          const teacherBankCommentId =
            newResult.teacherComment?.commentBankCommentId;

          if (
            newResult.teacherComment?.comment ||
            newResult.teacherComment?.commentBankCommentId
          ) {
            newResult.teacherComment = {
              ...newResult.teacherComment,
              assessmentId: assessmentData?.id ?? 0,
              studentPartyId: result.studentPartyId,
              commenterUserType: CommenterUserType.Teacher,
              subjectGroupPartyId: subjectGroupId ?? 0,
              commenterPartyId: activeProfile?.partyId ?? 0,
              ...(assessmentData?.commentType === CommentType.CommentBank ||
              newResult.teacherCommentType === CommentType.CommentBank
                ? {
                    comment: null,
                    commentBankCommentId: teacherBankCommentId,
                  }
                : {
                    comment: teacherWrittenComment ?? null,
                    commentBankCommentId: null,
                  }),
            };
          }

          acc.push({
            ...newResult,
            extraFields: Object.values(newResult.extraFields).map(
              (value) => value,
            ),
          });
        }
        return acc;
      },
      [],
    );

    if (!results) return Promise.reject();

    return updateAssessmentResult(results);
  };

  return (
    <Table
      key={academicNamespaceId}
      disableSavePreferences
      visibleDataRef={visibleDataRef}
      rowData={studentResults ?? []}
      autoSave
      isLoading={isLoadingAssessmentData}
      columnDefs={columnDefs}
      getRowId={({ data }) => String(data?.studentPartyId)}
      onBulkSave={saveAssessmentResult}
      statusBar={{
        statusPanels: [
          {
            statusPanel: 'tyroAggStatusPanel',
            statusPanelParams: {
              columns: [
                {
                  id: 'result',
                  aggregationTypes: ['avg'],
                },
                ...(assessmentData?.captureTarget
                  ? [
                      {
                        id: 'targetResult',
                        aggregationTypes: ['avg'],
                        onSelectOnly: true,
                      },
                    ]
                  : []),
              ],
            },
          },
        ],
      }}
      tableId={tableId}
    />
  );
};
