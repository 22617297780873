import {
  Box,
  type BoxProps,
  Stack,
  Typography,
  type TypographyProps,
} from '@mui/material';
import { useMemo } from 'react';
import { type Location, useLocation } from 'react-router-dom';
import { Breadcrumbs, type BreadcrumbsProps } from '../breadcrumbs';
import type { TLink } from '../breadcrumbs/link-item';

export interface PageHeadingProps extends BoxProps {
  title: string;
  titleProps?: TypographyProps<'h1'>;
  breadcrumbs?: BreadcrumbsProps;
  rightAdornment?: React.ReactNode;
}

export const PageHeading = ({
  title,
  titleProps = {},
  breadcrumbs: externalBreadcrumbProps,
  rightAdornment,
  ...props
}: PageHeadingProps) => {
  const location = useLocation();

  const breadcrumbsLinks = useMemo(() => {
    if (!externalBreadcrumbProps?.links) return [];
    const typedLocation = location as Location<{
      breadcrumbs?: TLink[];
    }>;
    const { breadcrumbs } = typedLocation.state ?? {};
    const validLinks =
      breadcrumbs?.every(
        (link) =>
          typeof link.name === 'string' &&
          (typeof link.href === 'string' || typeof link.href === 'undefined'),
      ) ?? false;

    if (breadcrumbs && validLinks) {
      const lastBreadcrumbLink =
        externalBreadcrumbProps.links[externalBreadcrumbProps.links.length - 1];
      return [...breadcrumbs, lastBreadcrumbLink];
    }
    return externalBreadcrumbProps.links;
  }, [externalBreadcrumbProps, location]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      {...props}
    >
      <Stack>
        <Typography variant="h4" component="h1" {...titleProps}>
          {title}
        </Typography>
        {externalBreadcrumbProps && (
          <Breadcrumbs {...externalBreadcrumbProps} links={breadcrumbsLinks} />
        )}
      </Stack>
      {rightAdornment}
    </Box>
  );
};
