import {
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { SearchType } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { CloseIcon } from '@tyro/icons';
import { useMemo } from 'react';
import { useSearchFeatures } from '../../../hooks/use-search-features';
import { SearchFavoriteIcon } from '../favourite-icon';
import { SearchListboxContainer } from '../listbox-container';
import { type SearchOptionData, useSearchProvider } from '../provider';
import { SectionContainer } from '../section-container';
import { GroupOption } from './groups-section';
import { PageOption } from './pages-section';
import { PersonOption } from './people-section';

interface SearchItemProps {
  option: SearchOptionData;
  endIcon?: JSX.Element;
}

function SearchItem({ option, endIcon }: SearchItemProps) {
  if (option.type === 'PAGE') {
    return (
      <PageOption key={option.partyId} option={option} endIcon={endIcon} />
    );
  }

  if (
    option.type === SearchType.Contact ||
    option.type === SearchType.Student ||
    option.type === SearchType.Staff
  ) {
    return (
      <PersonOption key={option.partyId} option={option} endIcon={endIcon} />
    );
  }

  return <GroupOption key={option.partyId} option={option} endIcon={endIcon} />;
}

export function RecentAndFavoriteSearchSection() {
  const { t } = useTranslation(['common']);
  const {
    recentSearches,
    removeRecentSearch,
    favourites,
    favouriteIds,
    toggleFavorite,
  } = useSearchProvider();
  const { options } = useSearchFeatures('');

  const convertedRecentOptions = useMemo(
    () =>
      recentSearches.map((recentSearch) => {
        if (recentSearch.type === 'PAGE') {
          const matchingOption = options.find(
            (option) => option.path === recentSearch?.meta?.path,
          );

          if (!matchingOption) return recentSearch;

          return {
            ...recentSearch,
            meta: {
              ...(recentSearch?.meta ?? {}),
              icon: matchingOption.icon,
            },
          };
        }

        return recentSearch;
      }),
    [recentSearches, options],
  );

  const convertedFavoriteOptions = useMemo(
    () =>
      favourites.map((favourite) => {
        if (favourite.type === 'PAGE') {
          const matchingOption = options.find(
            (option) => option.path === favourite?.meta?.path,
          );

          if (!matchingOption) return favourite;

          return {
            ...favourite,
            meta: {
              ...(favourite?.meta ?? {}),
              icon: matchingOption.icon,
            },
          };
        }

        return favourite;
      }),
    [favourites, options],
  );

  const hasRecentOptions = convertedRecentOptions.length > 0;
  const hasFavoriteOptions = convertedFavoriteOptions.length > 0;

  if (!hasRecentOptions && !hasFavoriteOptions) return null;

  return (
    <>
      <Divider />
      <DialogContent dividers sx={{ px: 2 }}>
        <SearchListboxContainer>
          {hasRecentOptions && (
            <SectionContainer heading={t('common:recent')}>
              {convertedRecentOptions?.map((option) => {
                const isFavorite = favouriteIds.has(option.partyId);
                const endIcon = (
                  <Stack direction="row">
                    <SearchFavoriteIcon
                      isFavorite={isFavorite}
                      onClick={() => toggleFavorite(option.partyId, option)}
                    />
                    <Tooltip
                      title={t('common:actions.remove')}
                      enterDelay={500}
                    >
                      <IconButton
                        color="primary"
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          removeRecentSearch(option.partyId);
                        }}
                        aria-label={t('common:actions.remove')}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                );

                return (
                  <SearchItem
                    key={option.partyId}
                    option={option}
                    endIcon={endIcon}
                  />
                );
              })}
            </SectionContainer>
          )}
          {hasFavoriteOptions && (
            <SectionContainer heading={t('common:bookmarks')}>
              {convertedFavoriteOptions?.map((option) => {
                const endIcon = (
                  <Tooltip title={t('common:actions.remove')} enterDelay={500}>
                    <IconButton
                      color="primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        toggleFavorite(option.partyId, option);
                      }}
                      aria-label={t('common:actions.remove')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                );

                return (
                  <SearchItem
                    key={option.partyId}
                    option={option}
                    endIcon={endIcon}
                  />
                );
              })}
            </SectionContainer>
          )}
        </SearchListboxContainer>
      </DialogContent>
    </>
  );
}
