import { Stack } from '@mui/material';
import type { TemplatingContextType } from '@tyro/api';
import { IconButtonWithTooltip } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { AddColumnIcon } from '@tyro/icons';
import type { Dispatch, SetStateAction } from 'react';

interface InsertVariableButtonProps {
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  contextType: TemplatingContextType | undefined;
}

export function InsertVariableButton({
  onOpenChange,
  contextType,
}: InsertVariableButtonProps) {
  const { t } = useTranslation(['common']);

  return (
    <Stack justifyContent="center" alignItems="center">
      <IconButtonWithTooltip
        title={
          contextType
            ? t('common:tooltipTitles.insertVariable')
            : t('common:tooltipTitles.insertVariableDisabled')
        }
        placement="top"
        onClick={() => {
          onOpenChange(true);
        }}
        disabled={!contextType}
      >
        <AddColumnIcon />
      </IconButtonWithTooltip>
    </Stack>
  );
}
