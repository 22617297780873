import { useQuery } from '@tanstack/react-query';
import {
  type AdminNotificationFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { notificationKeys } from './keys';

const adminNotifications = graphql(/* GraphQL */ `
  query communications_adminNotifications($filter: AdminNotificationFilter) {
    communications_adminNotifications(filter: $filter) {
      id
      title
      text
      notificationType
      sentOn
      sender {
        partyId
        title {
          id
          nameTextId
          name
        }
        firstName
        lastName
        avatarUrl
        type
      }
      numRecipients
      numReadRecipients
    }
  }
`);

const adminNotificationsQuery = (filter: AdminNotificationFilter) => ({
  queryKey: notificationKeys.adminNotifications(filter),
  queryFn: () => gqlClient.request(adminNotifications, { filter }),
});

export function getAdminNotifications(filter: AdminNotificationFilter) {
  return queryClient.fetchQuery(adminNotificationsQuery(filter));
}

export function useAdminNotifications(filter: AdminNotificationFilter) {
  return useQuery({
    ...adminNotificationsQuery(filter),
    select: ({ communications_adminNotifications }) =>
      communications_adminNotifications,
  });
}

export type ReturnTypeFromUseAdminNotifications = UseQueryReturnType<
  typeof useAdminNotifications
>[number];
