import { useMutation } from '@tanstack/react-query';

import {
  type Calendar_UpdateCalendarDayTimesInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { calendarKeys } from './keys';

const updateCalendarDayTimes = graphql(/* GraphQL */ `
  mutation calendar_updateCalendarDayTimes($input: Calendar_UpdateCalendarDayTimesInput!) {
    calendar_updateCalendarDayTimes(input: $input) {
      success
    }
  }
`);

export function useUpdateCalendarDayTimes() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: Calendar_UpdateCalendarDayTimesInput) =>
      gqlClient.request(updateCalendarDayTimes, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: calendarKeys.all });
      toast(t('common:snackbarMessages.updateSuccess'));
    },
  });
}
