import { Button, Stack, Typography } from '@mui/material';
import {
  EditState,
  SaveBarButton,
  SaveBarContainer,
  useResponsive,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import type { StudentAttendance } from '../../../hooks';
import { AttendanceBreakdown } from './breakdown';
import { AttendanceBreakdownModal } from './breakdown-modal';

type SaveBarProps = {
  someStudentsHaveNoAttendance: boolean;
  isAttendanceTaken: boolean;
  editingState: EditState;
  attendance: StudentAttendance;
  updatedAt: string;
  updatedBy: string;
  unsavedChanges: number;
  onSave: () => void;
  onCancel: () => void;
};

export const SaveBar = ({
  someStudentsHaveNoAttendance,
  isAttendanceTaken,
  editingState,
  attendance,
  updatedAt,
  updatedBy,
  unsavedChanges,
  onCancel,
  onSave,
}: SaveBarProps) => {
  const { t } = useTranslation(['common', 'groups']);

  const hasChanges = unsavedChanges > 0;
  const showActions =
    hasChanges || someStudentsHaveNoAttendance || !isAttendanceTaken;
  const isSm = useResponsive('down', 'sm');

  const lastSavedInfo = useMemo(() => {
    if (!updatedAt) {
      return (
        <Typography variant="subtitle2" component="span">
          {t('common:confirmed')}
        </Typography>
      );
    }

    return [
      t('groups:lastSaved', {
        date: dayjs(updatedAt).format('L'),
        time: dayjs(updatedAt).format('LT'),
      }),
      updatedBy && t('groups:updatedBy', { updatedBy }),
    ]
      .filter(Boolean)
      .map((text) => (
        <Typography key={text} variant="subtitle2" component="span">
          {text}
        </Typography>
      ));
  }, [updatedAt, updatedBy]);

  const status = useMemo(() => {
    if (!isAttendanceTaken) {
      return (
        <Typography variant="subtitle2" component="span">
          {t('common:unconfirmed')}
        </Typography>
      );
    }

    if (hasChanges) {
      return (
        <>
          <Typography variant="subtitle2" component="span">
            {t('common:unsavedChanges', { count: unsavedChanges })}
          </Typography>
          <Typography variant="subtitle2" component="span">
            {t('groups:byYou')}
          </Typography>
        </>
      );
    }

    return lastSavedInfo;
  }, [t, isAttendanceTaken, hasChanges, lastSavedInfo]);

  return (
    <SaveBarContainer
      slideProps={{ in: true }}
      containerProps={{
        paddingX: {
          xs: 2,
          md: 2.25,
        },
      }}
      contentProps={{
        minHeight: 62,
        justifyContent: 'space-between',
        px: {
          xs: 1.5,
          md: 3.25,
        },
        gap: {
          xs: 1.5,
          md: 4,
        },
        py: 1,
        flex: 1,
      }}
    >
      {isSm ? (
        <>
          <AttendanceBreakdownModal
            hasChanges={hasChanges}
            showActions={showActions}
            editingState={editingState}
            attendance={attendance}
            onSave={onSave}
          />
          {!showActions && (
            <Stack flexDirection="column">{lastSavedInfo}</Stack>
          )}
        </>
      ) : (
        <Stack gap={0.5}>
          <Stack flexDirection="row" justifyContent="space-between">
            {status}
          </Stack>
          <AttendanceBreakdown
            padding={0.75}
            borderRadius={5}
            attendance={attendance}
          />
        </Stack>
      )}

      {showActions && (
        <Stack flexDirection="row" gap={2}>
          {hasChanges && (
            <Button
              variant="soft"
              color="primary"
              disabled={editingState === EditState.Saving}
              onClick={onCancel}
            >
              {t('common:actions.cancel')}
            </Button>
          )}
          <SaveBarButton
            editingState={editingState}
            label={t(`common:actions.${hasChanges ? 'save' : 'confirm'}`)}
            onClick={onSave}
          />
        </Stack>
      )}
    </SaveBarContainer>
  );
};
