import type {
  Reporting_TableFilter,
  Reporting_TableFilterInput,
} from '@tyro/api';
import { useMemo, useState } from 'react';

import type { InnerReportFilter } from '../api/keys';
import { useRunReports } from '../api/run-report';
import { DynamicForm } from '../components/dynamic-form';
import {
  ReportTableView,
  type ReportTableViewProps,
} from '../components/table-view';

export type InteractiveValues = {
  metric?: string;
  groupings?: string[];
  timeGrouping?: string;
};

type ReportWithFiltersProps = {
  topReportId: InnerReportFilter['topReportId'];
  reportFilter: InnerReportFilter['filter'] & {
    fixedFilters?: InnerReportFilter['filter']['filters'];
  };
  reportTableViewProps?: Partial<ReportTableViewProps>;
  customFilters?: Partial<Reporting_TableFilter & { hidden?: boolean }>[];
  onUpdateFilters?: (filters: Reporting_TableFilterInput[]) => void;
};

export function ReportWithFilters({
  topReportId,
  customFilters,
  reportFilter,
  reportTableViewProps,
  onUpdateFilters,
}: ReportWithFiltersProps) {
  const { fixedFilters, ...restReportFilter } = reportFilter;

  const [filters, setFilters] = useState([
    ...(fixedFilters || []),
    ...(restReportFilter.filters || []),
  ]);

  const [interactiveValues, setInteractiveValues] = useState<InteractiveValues>(
    {
      metric: restReportFilter?.metric ?? undefined,
      groupings: restReportFilter?.groupings ?? undefined,
      timeGrouping: restReportFilter?.timeGrouping ?? undefined,
    },
  );

  const {
    data: reportData,
    isFetching,
    isLoading,
  } = useRunReports({
    topReportId,
    filter: {
      ...restReportFilter,
      filters,
      ...interactiveValues,
    },
  });

  const mappedFilterValues = useMemo(
    () =>
      reportData?.filters?.map((dataFilter) => {
        const filterValue = filters.find(
          (filter) => filter?.filterId === dataFilter.id,
        )?.filterValue;

        const currentFilter = customFilters?.find(
          (customFilter) => customFilter.id === dataFilter.id,
        );

        return {
          ...dataFilter,
          defaultValue: filterValue ?? dataFilter.defaultValue,
          ...currentFilter,
        };
      }),
    [filters, customFilters, reportData?.filters],
  );

  const mappedPreFilterFields = useMemo(() => {
    return {
      stats: reportData?.metrics
        ? {
            ...reportData.metrics,
            defaultValue:
              interactiveValues.metric ?? reportData.metrics.defaultValue,
          }
        : undefined,
    };
  }, [interactiveValues.metric, reportData?.metrics]);

  const mappedGroupingFields = useMemo(() => {
    return {
      groupBy: reportData?.groupBy
        ? {
            ...reportData.groupBy,
            defaultValue:
              interactiveValues.groupings?.[0] ??
              reportData.groupBy.defaultValue,
          }
        : undefined,
      timeGroupBy: reportData?.timeGroupBy
        ? {
            ...reportData.timeGroupBy,
            defaultValue:
              interactiveValues.timeGrouping ??
              reportData.timeGroupBy.defaultValue,
          }
        : undefined,
    };
  }, [
    interactiveValues.groupings,
    interactiveValues.timeGrouping,
    reportData?.groupBy,
    reportData?.timeGroupBy,
  ]);

  const updateValues = ({
    filters,
    ...newValues
  }: {
    filters: Reporting_TableFilterInput[];
  } & InteractiveValues) => {
    const updatedFilters = [...(fixedFilters || []), ...filters];
    const defaultInteractiveValues = {
      metric: reportData?.metrics?.defaultValue ?? undefined,
      groupings: reportData?.groupBy?.defaultValue ?? undefined,
      timeGrouping: reportData?.timeGroupBy?.defaultValue ?? undefined,
    };

    const uniqueFilters = Array.from(
      new Map(
        updatedFilters.flatMap((filter) =>
          filter ? [[filter?.filterId, filter]] : [],
        ),
      ).values(),
    );

    for (const key of Object.keys(
      defaultInteractiveValues,
    ) as (keyof typeof defaultInteractiveValues)[]) {
      if (newValues[key] && newValues[key] !== defaultInteractiveValues[key]) {
        uniqueFilters.push({
          filterId: key,
          filterValue: newValues[key],
        });
      }
    }

    onUpdateFilters?.(uniqueFilters);

    setFilters(uniqueFilters);
    setInteractiveValues({
      metric: newValues.metric,
      groupings: newValues.groupings,
      timeGrouping: newValues.timeGrouping,
    });
  };

  return (
    <>
      <DynamicForm
        isFetching={isFetching}
        filters={mappedFilterValues ?? []}
        onValueChange={updateValues}
        sql={reportData?.debug?.sql}
        isInteractiveReport={!!reportData?.info.isInteractive}
        preFilterFields={mappedPreFilterFields}
        groupingFields={mappedGroupingFields}
      />
      <ReportTableView
        isLoading={isLoading}
        reportData={reportData}
        {...reportTableViewProps}
      />
    </>
  );
}
