import {
  type InfiniteData,
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
} from '@tanstack/react-query';
import {
  type Communications_NotificationsQuery,
  type NotificationFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { shellKeys } from '../keys';

const DEFAULT_PAGINATION_LIMIT = 50;

const notifications = graphql(/* GraphQL */ `
  query communications_notifications($filter: NotificationFilter) {
    communications_notifications(filter: $filter) {
      id
      title
      text
      notificationType
      sentOn
      sender {
        partyId
        title {
          id
          name
          nameTextId
        }
        firstName
        lastName
        avatarUrl
        type
      }
      recipients {
        recipientPartyId
        readOn
        status
        metaData {
          partyId
          feeId
          mailId
          notificationType
          optionId
          studentPartyId
          substitutionDate
          schoolActivityId
          assessmentId
          formId
        }
      }
    }
  }
`);

type NotificationPageParam = {
  lastId: number;
  lastMessage: string;
};

const notificationsQuery = (filter: NotificationFilter) => ({
  queryKey: shellKeys.notifications.list(filter),
  initialPageParam: undefined,
  queryFn: async ({
    pageParam,
  }: QueryFunctionContext<QueryKey, NotificationPageParam | undefined>) =>
    gqlClient.request(notifications, {
      filter: {
        ...filter,
        pagination: {
          limit: DEFAULT_PAGINATION_LIMIT,
          ...filter.pagination,
          ...pageParam,
        },
      },
    }),
});

export function useNotifications(filter: NotificationFilter) {
  return useInfiniteQuery({
    ...notificationsQuery(filter),
    getNextPageParam: ({ communications_notifications: notificationsData }) => {
      const lastNotification = notificationsData[notificationsData.length - 1];

      return notificationsData.length === DEFAULT_PAGINATION_LIMIT &&
        lastNotification
        ? ({
            lastId: lastNotification.id,
            lastMessage: lastNotification.sentOn,
          } as NotificationPageParam)
        : undefined;
    },
    refetchInterval: 1000 * 60 * 2,
    select: (data: InfiniteData<Communications_NotificationsQuery>) => ({
      ...data,
      pages: data.pages.flatMap(
        ({ communications_notifications }) => communications_notifications,
      ),
    }),
  });
}

export function getNotifications(filter: NotificationFilter) {
  return queryClient.fetchQuery(notificationsQuery(filter));
}

export type ReturnTypeFromNotifications = UseQueryReturnType<
  typeof useNotifications
>['pages'][number];
