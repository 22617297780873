import { NotificationType } from '@tyro/api';
import { getInfoRequestFormList } from '@tyro/info-requests';
import { useEffect, useState } from 'react';
import type { ReturnTypeFromNotifications } from '../api/notifications/list';

async function getNotificationUrl(
  notificationMeta: ReturnTypeFromNotifications['recipients'][number]['metaData'],
) {
  switch (notificationMeta?.notificationType) {
    case NotificationType.Mail: {
      return notificationMeta.mailId
        ? `/mail/inbox/view/${notificationMeta.mailId}`
        : '/mail/inbox';
    }
    case NotificationType.Fee: {
      return '/fees';
    }
    case NotificationType.InfoRequest: {
      const { formId } = notificationMeta;

      const { forms_listInformationRequestForms: requestsData = [] } =
        await getInfoRequestFormList({});

      const currentRequest = requestsData.find(
        ({ id }) => id.objectId === formId,
      );

      if (!currentRequest) return '/info-requests';
      const { provider, forPartyId, objectId } = currentRequest.id;
      return `/info-requests/view?provider=${provider}&forPartyId=${
        forPartyId ?? 0
      }&objectId=${objectId ?? 0}`;
    }
    case NotificationType.Substitution: {
      return `/calendar?date=${notificationMeta.substitutionDate}`;
    }
    case NotificationType.SchoolActivityUpdated: {
      return `/school-activity/school-activity/${notificationMeta.schoolActivityId}/students?viewStatus=true`;
    }
    case NotificationType.SchoolActivityCreated: {
      return `/school-activity/school-activity/${notificationMeta.schoolActivityId}`;
    }
    case NotificationType.Assessment: {
      const { studentPartyId, assessmentId } = notificationMeta;
      return `people/students/${studentPartyId}/assessment?assessmentId=${assessmentId}`;
    }
    default:
      return null;
  }
}

async function getNotificationUrls(
  notifications: ReturnTypeFromNotifications[],
) {
  const linkPromises = notifications.map(async (notification) => {
    const url = await getNotificationUrl(notification.recipients[0].metaData);
    return {
      ...notification,
      url,
    };
  });

  return Promise.all(linkPromises);
}

export function useNotificationLinks(
  notifications: ReturnTypeFromNotifications[] | undefined,
) {
  const [notificationUrls, setNotificationUrls] = useState<Map<number, string>>(
    new Map(),
  );

  useEffect(() => {
    getNotificationUrls(notifications ?? []).then((urls) => {
      const newMap = new Map(
        urls
          .filter(({ url }) => !!url)
          .map((notification) => [notification.id, notification.url]),
      );
      setNotificationUrls(newMap as Map<number, string>);
    });
  }, [notifications]);

  return notificationUrls;
}
