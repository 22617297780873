import { useQuery } from '@tanstack/react-query';
import {
  type Calendar_CustomTagFilter,
  type QueryAdditionalOptions,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { calendarKeys } from '../keys';

const calendarCustomTags = graphql(/* GraphQL */ `
  query calendar_customTags($filter: Calendar_CustomTagFilter) {
    calendar_customTags(filter: $filter) {
      id
      tag
      staffSchoolCalendar
      contactSchoolCalendar
      studentSchoolCalendar
      archived
    }
  }
`);

const calendarTagsCustomQuery = (filter: Calendar_CustomTagFilter) => ({
  queryKey: calendarKeys.tags(filter),
  queryFn: async () =>
    gqlClient.request(calendarCustomTags, {
      filter,
    }),
});

export function getCalendarCustomTags(filter: Calendar_CustomTagFilter) {
  return queryClient.fetchQuery(calendarTagsCustomQuery(filter));
}

export function useCalendarCustomTags(
  filter: Calendar_CustomTagFilter,
  additionalOptions?: Omit<QueryAdditionalOptions, 'academicNamespaceId'>,
) {
  return useQuery({
    ...calendarTagsCustomQuery(filter),
    ...additionalOptions,
    select: ({ calendar_customTags }) => calendar_customTags ?? [],
  });
}

export type ReturnTypeFromUseCalendarCustomTags = UseQueryReturnType<
  typeof useCalendarCustomTags
>[number];
