import { DayType } from '@tyro/api';

type StyledValues = {
  color: string;
  bgColor: string;
};

type GetColourBasedOnDayTypeFn = (dayType?: DayType) => StyledValues;

export const getColourBasedOnDayType: GetColourBasedOnDayTypeFn = (dayType) => {
  switch (dayType) {
    case DayType.Holiday:
      return {
        bgColor: 'rose.500',
        color: 'white',
      };
    case DayType.Partial:
      return {
        bgColor: 'amber.500',
        color: 'white',
      };
    case DayType.StaffDay:
      return {
        bgColor: 'amber.500',
        color: 'white',
      };
    case DayType.SchoolDay:
      return {
        bgColor: 'green.500',
        color: 'white',
      };
    case DayType.Extracurricular:
      return {
        bgColor: 'violet.500',
        color: 'white',
      };
    default:
      return {
        bgColor: 'zinc.300',
        color: 'zinc.700',
      };
  }
};
