import { useQuery } from '@tanstack/react-query';
import {
  type Forms_ViewSubmissionFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { formsKeys } from './keys';

const viewSubmissions = graphql(/* GraphQL */ `
  query forms_viewSubmission($filter: Forms_ViewSubmissionFilter!) {
    forms_viewSubmission(filter: $filter) {
      id {
        provider
        forPartyId
        objectId
      }
      title
      description
      allowPartialCompletion
      gridWidth {
        xs
        sm
        md
        lg
        xl
      }
      readOnly
      fields {
        header
        fields {
          __typename
          ... on Forms_FormFieldSubGroup {
            formFieldType
            header
            fields {
              formFieldType
              id
              label
              type
              required
              options {
                id
                name
              }
              gridWidth {
                xs
                sm
                md
                lg
                xl
              }
            }
            gridWidth {
              xs
              sm
              md
              lg
              xl
            }
          }
          ... on Forms_FormFieldItem {
            formFieldType
            id
            label
            type
            required
            options {
              id
              name
            }
            gridWidth {
              xs
              sm
              md
              lg
              xl
            }
            defaultValue
          }
        }
      }
    }
  }
`);

const viewSubmissionsQuery = (filter: Forms_ViewSubmissionFilter) => ({
  queryKey: formsKeys.viewSubmissions(filter),
  queryFn: () => gqlClient.request(viewSubmissions, { filter }),
});

export function useViewSubmissions(filter: Forms_ViewSubmissionFilter) {
  return useQuery({
    ...viewSubmissionsQuery(filter),
    select: ({ forms_viewSubmission }) => forms_viewSubmission,
  });
}

export function getViewSubmissions(filter: Forms_ViewSubmissionFilter) {
  return queryClient.fetchQuery(viewSubmissionsQuery(filter));
}

export type ReturnTypeFromUseViewSubmissions = UseQueryReturnType<
  typeof useViewSubmissions
>;
