import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, InputLabel, Stack, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';
import {
  type RecipientInput,
  type SendSmsInput,
  TemplatingArea,
} from '@tyro/api';
import { SmsRecipientType, TemplatingContextType } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  RHFCheckboxGroup,
  RHFEditorTextField,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useVariablesQuery } from '@tyro/templates';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSendSms } from '../../../api/send-sms';
import { useSmsCostPerMessage } from '../../../api/sms-cost';
import { analyzeSmsTextString } from '../../../utils/analyze-sms-text-string';
import { getContextFromRecipient } from '../../../utils/get-context-from-recipient';
import { getCustomNodeValuesFromHtml } from '../../../utils/get-variables-from-sms-message';
import { SmsSummary } from '../sms-summary';
import { RecipientList, type RecipientsForSmsModal } from './recipient-list';
export type { RecipientsForSmsModal } from './recipient-list';
import { useTemplates } from '@tyro/templates';

interface SendSmsModalProps {
  isOpen: boolean;
  onClose: () => void;
  recipients: RecipientsForSmsModal;
  possibleRecipientTypes: {
    type: RecipientInput['recipientPartyType'];
    label: string;
  }[];
  hideRecipientTypes?: boolean;
}

interface SmsFormState {
  recipients: RecipientsForSmsModal;
  recipientTypes: RecipientInput['recipientPartyType'][];
  message: string;
}

export function SendSmsModal({
  isOpen,
  onClose,
  recipients,
  possibleRecipientTypes,
  hideRecipientTypes = false,
}: SendSmsModalProps) {
  const { t } = useTranslation(['common']);
  const [editor, setEditor] = useState<Editor | null>(null);
  const [hasSmsMessageVariables, setHasSmsMessageVariables] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(false);
  const { resolver, rules } = useFormValidator<SmsFormState>();
  const { reset, control, handleSubmit, setValue, watch, trigger } =
    useForm<SmsFormState>({
      resolver: resolver({
        recipients: rules.required(),
        recipientTypes: rules.required(),
        message: [
          rules.required(),
          rules.validate((_value, throwError, formValues) => {
            const textContent = (formValues.message ?? '')
              .replace(/<[^>]*>/g, '')
              .trim();

            if (!textContent.length) {
              throwError(t('common:errorMessages.required'));
            }
          }),
        ],
      }),
      defaultValues: {
        recipients: [],
        recipientTypes:
          possibleRecipientTypes.length === 1
            ? possibleRecipientTypes.map((recipientType) => recipientType.type)
            : [],
      },
    });
  const [recipientList, message, recipientTypes] = watch([
    'recipients',
    'message',
    'recipientTypes',
  ]);

  const contextType = useMemo(() => {
    if (recipientTypes.length === 1) {
      return getContextFromRecipient(recipientTypes[0]);
    }
    if (recipientTypes.length > 1) {
      return TemplatingContextType.Person;
    }
    return undefined;
  }, [recipientTypes]);

  const { data: variablesData, isLoading } = useVariablesQuery({
    context: contextType,
  });

  const { data: templatesData, isLoading: isTemplatesLoading } = useTemplates({
    context: contextType,
    area: TemplatingArea.Sms,
  });

  const { numberOfMessages } = analyzeSmsTextString(message);
  const fullRecipientList = useMemo(
    () =>
      recipientList.reduce<
        NonNullable<NonNullable<SendSmsInput['recipients']>>
      >((acc, recipient) => {
        for (const recipientType of recipientTypes) {
          const formatRecipientType =
            recipientType === 'CONTACT'
              ? SmsRecipientType.Person
              : recipientType;
          acc.push({
            recipientPartyId: recipient.id,
            recipientPartyType: formatRecipientType,
          });
        }

        return acc;
      }, []),
    [recipientList, recipientTypes],
  );

  const { data: costPerMessage } = useSmsCostPerMessage({
    recipients: fullRecipientList,
  });
  const { mutateAsync: sendSms, isPending } = useSendSms();

  const removeRecipient = (recipientId: number) => {
    setValue(
      'recipients',
      recipientList.filter((recipient) => recipient.id !== recipientId),
    );
  };

  const onCancel = () => {
    onClose();
    reset();
  };

  const onSubmit = (data: SmsFormState) => {
    sendSms(
      {
        text: data.message,
        recipients: fullRecipientList,
        mobileNumbers: [],
        canReply: false,
      },
      {
        onSuccess: () => {
          onCancel();
        },
      },
    );
  };

  useEffect(() => {
    const recipientKeys = new Set<string>();
    const filteredArray = recipients
      .filter(({ id, type }) => {
        const key = `${id}-${type}`;
        const isDuplicateRecipient = recipientKeys.has(key);

        recipientKeys.add(key);
        return !isDuplicateRecipient;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    setValue('recipients', filteredArray);
  }, [isOpen]);

  useEffect(() => {
    if (message || templateSelected) {
      const variableValues = getCustomNodeValuesFromHtml(message);
      setHasSmsMessageVariables(variableValues.length > 0);
    }
  }, [message, templateSelected]);

  useEffect(() => {
    if (editor && templateSelected) {
      setValue('message', editor.getHTML());
      trigger('message');
      setTemplateSelected(false);
    }
  }, [templateSelected, setValue, editor, trigger]);

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row">
          <Box sx={{ flex: 1.2 }}>
            <DialogTitle>{t('common:sendSms')}</DialogTitle>

            <Stack spacing={3} sx={{ p: 3, pt: 0 }}>
              {!hideRecipientTypes &&
                (possibleRecipientTypes.length > 1 ? (
                  <RHFCheckboxGroup<
                    SmsFormState,
                    (typeof possibleRecipientTypes)[number]
                  >
                    label={`${t('common:sendTo')}:`}
                    controlProps={{ name: 'recipientTypes', control }}
                    options={possibleRecipientTypes}
                    getOptionLabel={(option) => option.label}
                    optionIdKey="type"
                  />
                ) : (
                  <Box component="dl" m={0}>
                    <InputLabel component="dt">
                      {t('common:sendTo')}:
                    </InputLabel>
                    <Typography component="dd" variant="body2" m="0">
                      {possibleRecipientTypes[0].label}
                    </Typography>
                  </Box>
                ))}
              <RHFEditorTextField
                label={t('common:message')}
                variant="white-filled-outlined"
                controlProps={{
                  name: 'message',
                  control,
                }}
                setEditor={setEditor}
                editor={editor}
                textFieldProps={{
                  fullWidth: true,
                  size: 'small',
                  enableExtensions: {
                    undoRedo: true,
                    textFormatting: true,
                    listItems: true,
                    links: true,
                    variables: true,
                    templates: true,
                    variablesData: {
                      variables: variablesData,
                      isLoading: isLoading,
                    },
                    templatesData: {
                      templates: templatesData,
                      isLoading: isTemplatesLoading,
                    },
                    contextType: contextType,
                  },
                }}
              />
            </Stack>
          </Box>
          <RecipientList
            onClose={onClose}
            recipients={recipientList}
            initialRecipientAmount={recipients.length}
            removeRecipient={removeRecipient}
          />
        </Stack>

        <DialogActions
          sx={{
            borderTopColor: 'slate.200',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            p: '0 !important',
          }}
        >
          <Stack direction="row" sx={{ py: 1.5, flex: 1 }}>
            <Box sx={{ flex: 1.2, display: 'flex', alignItems: 'center' }}>
              <SmsSummary
                sx={{ flex: 1, px: 3 }}
                message={message}
                costPerSms={0.06}
                totalCost={(costPerMessage ?? 0) * numberOfMessages}
                smsEditorConfig={{
                  hasSmsMessageVariables,
                  templateSelected,
                  editor,
                }}
              />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1.5}
                sx={{ px: 3, flex: 1 }}
              >
                <Button variant="soft" onClick={onCancel}>
                  {t('common:actions.cancel')}
                </Button>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isPending}
                >
                  {t('common:actions.send')}
                </LoadingButton>
              </Stack>
            </Box>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
}
