import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, Stack } from '@mui/material';
import type {
  Forms_FormView,
  Forms_SubmitFormInput,
  Forms_SubmitFormResponse,
} from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useBreakpointValue } from '../../../hooks/use-breakpoint-value';
import { useFormValidator } from '../../../hooks/use-form-validator';
import { LoadingPlaceholderContainer } from '../../loading-placeholder';
import { FieldGroup } from './group';
import {
  type FormState,
  convertDefaultValueFromBackendResponse,
  convertValueForBackendRequest,
  sortFields,
} from './utils';

export interface DynamicFormProps {
  formSettings: Forms_FormView | undefined;
  onSubmit?: (
    data: Forms_SubmitFormInput['fields'],
  ) => Promise<Omit<Forms_SubmitFormResponse, 'id'>>;

  onCancel?: () => void;
}

export const DynamicForm = ({
  formSettings,
  onSubmit,
  onCancel,
}: DynamicFormProps) => {
  const [globalErrorRef, setGlobalErrorRef] = useState<HTMLDivElement | null>(
    null,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);

  const formGridWidth = useBreakpointValue({
    base: 12,
    ...formSettings?.gridWidth,
  });
  const { t } = useTranslation(['common']);

  const { resolver, rules } = useFormValidator();

  const sortedFields = sortFields(formSettings);

  const resolverFields = sortedFields.reduce<Parameters<typeof resolver>[0]>(
    (acc, field) => {
      acc[field.id] =
        !formSettings?.allowPartialCompletion && field.required
          ? [rules.required()]
          : [];
      return acc;
    },
    {},
  );

  const { control, handleSubmit, setError } = useForm<FormState>({
    defaultValues: convertDefaultValueFromBackendResponse(formSettings),
    resolver: resolver(resolverFields),
  });

  const requestSubmit = handleSubmit(async (data) => {
    if (!onSubmit) return;

    try {
      if (formSettings?.readOnly) return;
      setIsSubmitting(true);

      const convertedData = sortedFields.reduce(
        (fields, field) => {
          fields[field.id] = {
            fieldId: field.id,
            value: convertValueForBackendRequest(data[field.id], field.type),
          };

          return fields;
        },
        {} as Record<string, Forms_SubmitFormInput['fields'][number]>,
      );

      const { validations } = await onSubmit(Object.values(convertedData));

      const newGlobalErrors = validations?.globalErrors || [];
      const hasGlobalErrors = newGlobalErrors.length > 0;

      if (hasGlobalErrors) {
        setGlobalErrors(newGlobalErrors);
      }

      if (validations?.fieldErrors) {
        const typedErrors = validations?.fieldErrors as Record<string, string>;

        const sortedIdsWithErrors = sortedFields.filter(
          (field) => typedErrors[field.id],
        );

        if (sortedIdsWithErrors.length > 0) {
          sortedIdsWithErrors.forEach((field, index) => {
            setError(
              field.id,
              { message: typedErrors[field.id] },
              { shouldFocus: index === 0 && !hasGlobalErrors },
            );
          });
        }
      }
    } catch (e) {
      setGlobalErrors([t('common:snackbarMessages.errorFailed')]);
    } finally {
      setIsSubmitting(false);
    }
  });

  useEffect(() => {
    if (globalErrorRef) {
      globalErrorRef.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [globalErrorRef]);

  return (
    <LoadingPlaceholderContainer isLoading={!formSettings}>
      <Stack
        component={formSettings?.readOnly ? 'div' : 'form'}
        onSubmit={requestSubmit}
        gap={3}
        sx={{
          width: `${(100 / 12) * Math.min(formGridWidth ?? 12, 12)})%`,
        }}
      >
        {globalErrors.length > 0 && (
          <Box ref={(ref: HTMLDivElement) => setGlobalErrorRef(ref)}>
            <Alert severity="error">
              <AlertTitle>Please fix the following errors</AlertTitle>

              <Box component="ul" sx={{ m: 0, pl: '14px' }}>
                {globalErrors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </Box>
            </Alert>
          </Box>
        )}
        {formSettings?.fields?.map((fieldGroup) => (
          <FieldGroup
            key={fieldGroup?.header}
            group={fieldGroup}
            control={control}
            readOnly={formSettings.readOnly}
          />
        ))}
        {(onSubmit || onCancel) && (
          <Stack direction="row" gap={2} justifyContent="flex-end">
            {!!onCancel && (
              <Button
                variant="soft"
                size="large"
                color="primary"
                onClick={onCancel}
              >
                {t('common:actions.cancel')}
              </Button>
            )}
            {onSubmit && (
              <LoadingButton
                variant="contained"
                size="large"
                type="submit"
                loading={isSubmitting}
              >
                {t('common:actions.save')}
              </LoadingButton>
            )}
          </Stack>
        )}
      </Stack>
    </LoadingPlaceholderContainer>
  );
};
