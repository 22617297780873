import { InputAdornment } from '@mui/material';
import type { Content, Editor } from '@tiptap/react';
import { TemplatingArea, type TemplatingContextType } from '@tyro/api';
import { Autocomplete } from '@tyro/core';
import { SearchIcon } from '@tyro/icons';
import { useTemplates } from '@tyro/templates';

type TemplatesAutocompleteProps = {
  contextType: TemplatingContextType | undefined;
  setIsTemplateSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editor: Editor | null;
};

export function TemplatesAutocomplete({
  contextType,
  setIsTemplateSearchOpen,
  editor,
}: TemplatesAutocompleteProps) {
  const { data: templates, isLoading } = useTemplates({
    area: TemplatingArea.Mail,
    context: contextType,
  });

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleTemplateSelect = (_event: any, selectedTemplate: any) => {
    if (selectedTemplate?.template && editor) {
      editor.commands.setContent(selectedTemplate.template as Content);
      setIsTemplateSearchOpen(false);
    }
  };

  return (
    <Autocomplete
      fullWidth
      optionIdKey="id"
      optionTextKey={'name'}
      loading={isLoading}
      options={templates ?? []}
      onChange={handleTemplateSelect}
      ChipProps={{ size: 'small' }}
      inputProps={{
        sx: {
          '& .MuiInputBase-root': {
            ml: 1,
            backgroundColor: 'background.paper',
            border: 'none',
            height: '40px',
          },
          '& fieldset': {
            border: 'none',
          },
        },
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
