import { type SearchMeta, type SearchType, usePreference } from '@tyro/api';
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

interface SearchOptionMeta extends SearchMeta {
  path?: string;
  breadcrumbs?: string[];
}

export interface SearchOptionData {
  partyId: string | number;
  type: 'PAGE' | SearchType;
  text: string;
  meta: SearchOptionMeta;
}

interface SearchFocusContextValue {
  focusedOptionId: string | null;
  setFocusedOptionId: Dispatch<SetStateAction<string | null>>;
  recentSearches: SearchOptionData[];
  removeRecentSearch: (id: string | number) => void;
  navigateToSelectedOption: () => void;
  nextOption: () => void;
  previousOption: () => void;
  addOptionRef: (
    id: string,
    ref: { node: HTMLLIElement; data: SearchOptionData },
  ) => void;
  removeOptionRef: (id: string) => void;
  favourites: SearchOptionData[];
  favouriteIds: Set<string | number>;
  toggleFavorite: (id: string | number, optionData: SearchOptionData) => void;
}

interface SearchFocusProps {
  children: ReactNode;
  data: unknown;
}

const SearchFocusContext = createContext<SearchFocusContextValue | undefined>(
  undefined,
);

const optionRefs = new Map<
  string,
  { node: HTMLLIElement; data: SearchOptionData }
>();

function getNthFromOption(id: string | null, diff: number) {
  const keys = Array.from(optionRefs.keys());

  const index = id ? keys.indexOf(id) : -1;
  const nextIndex = index + diff;

  const newKey = keys[nextIndex];

  if (index >= 0 && newKey) {
    return newKey;
  }
  if (diff > 0) {
    return keys[0];
  }
  return keys[keys.length - 1];
}

function focusToOption(id: string | null) {
  if (id) {
    const ref = optionRefs.get(id);
    if (ref) {
      ref.node.scrollIntoView({ block: 'nearest' });
    }
  }
}

export function SearchProvider({ children, data }: SearchFocusProps) {
  const [focusedOptionId, setFocusedOptionId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [recentSearches, setRecentSearches] = usePreference(
    'omni-search.recent-searches',
    [],
  );
  const [favourites, setFavorites] = usePreference(
    'omni-search.favourites',
    [],
  );

  const addSearchToRecentSearch = useCallback(
    (id: number | string, optionData: SearchOptionData) => {
      setRecentSearches((searches) => {
        const searchIndex = searches.findIndex((item) => item.partyId === id);
        if (searchIndex !== -1) {
          searches.splice(searchIndex, 1);
        } else if (searches.length >= 5) {
          searches.pop();
        }
        searches.unshift(optionData);

        return [...searches];
      });
    },
    [setRecentSearches],
  );

  const removeRecentSearch = useCallback(
    (id: number | string) => {
      setRecentSearches((searches) => {
        const searchIndex = searches.findIndex((item) => item.partyId === id);
        if (searchIndex !== -1) {
          searches.splice(searchIndex, 1);
        }

        return [...searches];
      });
    },
    [setRecentSearches],
  );

  const toggleFavorite = useCallback(
    (id: number | string, optionData: SearchOptionData) => {
      const newFavorites = [...favourites];
      const favouriteIndex = favourites.findIndex(
        (item) => item.partyId === id,
      );
      if (favouriteIndex === -1) {
        newFavorites.push(optionData);
      } else {
        newFavorites.splice(favouriteIndex, 1);
      }

      setFavorites((searches) => {
        const newFavorites = [...searches];

        const favouriteIndex = favourites.findIndex(
          (item) => item.partyId === id,
        );
        if (favouriteIndex === -1) {
          newFavorites.push(optionData);
        } else {
          newFavorites.splice(favouriteIndex, 1);
        }

        return newFavorites;
      });
    },
    [favourites, setFavorites],
  );

  const navigateToSelectedOption = useCallback(() => {
    if (focusedOptionId) {
      const ref = optionRefs.get(focusedOptionId);
      const url = ref?.node ? ref.node.children[0].getAttribute('href') : null;

      if (ref?.data) {
        addSearchToRecentSearch(ref.data.partyId, ref?.data);
      }

      if (url) {
        navigate(url);
      }
    }
  }, [focusedOptionId, navigate]);

  const nextOption = useCallback(() => {
    setFocusedOptionId((id) => {
      if (optionRefs.size > 0) {
        const nextKey = getNthFromOption(id, 1);

        focusToOption(nextKey);
        return nextKey;
      }

      return null;
    });
  }, [setFocusedOptionId]);

  const previousOption = useCallback(() => {
    setFocusedOptionId((id) => {
      if (optionRefs.size > 0) {
        const previousKey = getNthFromOption(id, -1);

        focusToOption(previousKey);
        return previousKey;
      }

      return null;
    });
  }, [setFocusedOptionId]);

  const addOptionRef = useCallback(
    (id: string, ref: { node: HTMLLIElement; data: SearchOptionData }) => {
      optionRefs.set(id, ref);
    },
    [],
  );

  const removeOptionRef = useCallback((id: string) => {
    optionRefs.delete(id);
  }, []);

  useEffect(() => {
    if (data) {
      setFocusedOptionId(null);
    }
  }, [data, setFocusedOptionId]);

  const value = useMemo<SearchFocusContextValue>(
    () => ({
      focusedOptionId,
      setFocusedOptionId,
      recentSearches,
      removeRecentSearch,
      navigateToSelectedOption,
      nextOption,
      previousOption,
      addOptionRef,
      removeOptionRef,
      favourites,
      favouriteIds: new Set(favourites.map(({ partyId }) => partyId)),
      toggleFavorite,
    }),
    [
      focusedOptionId,
      setFocusedOptionId,
      recentSearches,
      removeRecentSearch,
      navigateToSelectedOption,
      nextOption,
      previousOption,
      addOptionRef,
      removeOptionRef,
      favourites,
      toggleFavorite,
    ],
  );

  return (
    <SearchFocusContext.Provider value={value}>
      {children}
    </SearchFocusContext.Provider>
  );
}

export function useSearchProvider() {
  const context = useContext(SearchFocusContext);
  if (context === undefined) {
    throw new Error('useSearchProvider must be used within a SearchProvider');
  }
  return context;
}
