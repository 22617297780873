import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { type VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { NotificationType } from '@tyro/api';
import {
  Avatar,
  usePreferredNameLayout,
  useRelativeDateFormat,
} from '@tyro/core';

import { useTranslation } from '@tyro/i18n';
import {
  CalendarAddIcon,
  DocEditIcon,
  GraduateHatLoadingIcon,
  HouseSpeechHeartIcon,
  MailPostLetterIcon,
  SchoolBagIcon,
  SchoolExamACircleIcon,
  SwapHorizontalIcon,
  UserIcon,
  WalletWithMoneyIcon,
  XHalfDashCircleIcon,
} from '@tyro/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { ReturnTypeFromNotifications } from '../../../api/notifications/list';
import { useNotificationLinks } from '../../../hooks/use-notification-links';

dayjs.extend(relativeTime);

const NotificationIconMap = {
  [NotificationType.Absence]: {
    bgColor: 'pink.100',
    color: 'pink.500',
    Icon: XHalfDashCircleIcon,
  },
  [NotificationType.Assessment]: {
    bgColor: 'emerald.100',
    color: 'emerald.500',
    Icon: SchoolExamACircleIcon,
  },
  [NotificationType.Fee]: {
    bgColor: 'orange.100',
    color: 'orange.500',
    Icon: WalletWithMoneyIcon,
  },
  [NotificationType.Individual]: {
    bgColor: 'yellow.100',
    color: 'yellow.500',
    Icon: UserIcon,
  },
  [NotificationType.Mail]: {
    bgColor: 'blue.100',
    color: 'blue.600',
    Icon: MailPostLetterIcon,
  },
  [NotificationType.Substitution]: {
    bgColor: 'green.100',
    color: 'green.500',
    Icon: GraduateHatLoadingIcon,
  },
  [NotificationType.Timetable]: {
    bgColor: 'purple.100',
    color: 'purple.500',
    Icon: CalendarAddIcon,
  },
  [NotificationType.Wellbeing]: {
    bgColor: 'rose.100',
    color: 'rose.600',
    Icon: HouseSpeechHeartIcon,
  },
  [NotificationType.InfoRequest]: {
    bgColor: 'purple.100',
    color: 'purple.500',
    Icon: DocEditIcon,
  },
  [NotificationType.SignInOutRequest]: {
    bgColor: 'sky.100',
    color: 'sky.600',
    Icon: SwapHorizontalIcon,
  },
  [NotificationType.SchoolActivityUpdated]: {
    bgColor: 'emerald.100',
    color: 'emerald.600',
    Icon: SchoolBagIcon,
  },
  [NotificationType.SchoolActivityCreated]: {
    bgColor: 'emerald.100',
    color: 'emerald.600',
    Icon: SchoolBagIcon,
  },
} as const;

type VirtualizedNotificationsListProps = {
  containerRef: HTMLDivElement | null;
  notifications: ReturnTypeFromNotifications[];
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  onNotificationClick: (
    e: React.MouseEvent,
    notification: ReturnTypeFromNotifications,
  ) => void;
};

export function VirtualizedNotificationsListInner({
  containerRef,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  notifications,
  onNotificationClick,
}: VirtualizedNotificationsListProps) {
  const { displayName } = usePreferredNameLayout();
  const { getRelativeDateFormat } = useRelativeDateFormat();
  const { t } = useTranslation(['common', 'calendar']);
  const notificationUrls = useNotificationLinks(notifications);

  const groupedNotifications = useMemo(() => {
    if (!notifications) return [];

    const groupedData = notifications.reduce((acc, notification) => {
      const sentOn = dayjs(notification.sentOn);
      const notificationDate = sentOn.calendar(null, {
        sameDay: `[${t('calendar:today')}]`,
        lastDay: `[${t('calendar:yesterday')}]`,
        lastWeek: `[${t('calendar:lastWeek')}]`,
        sameElse: 'LL',
      });

      const notificationsForDate = acc.get(notificationDate) ?? [];
      notificationsForDate.push(notification);
      acc.set(notificationDate, notificationsForDate);

      return acc;
    }, new Map<string, ReturnTypeFromNotifications[]>());

    const sortedKeys = [...groupedData.keys()].sort((keyA, keyB) => {
      const [firstDataA] = groupedData.get(keyA) || [];
      const [firstDataB] = groupedData.get(keyB) || [];

      return dayjs(firstDataB.sentOn).unix() - dayjs(firstDataA.sentOn).unix();
    });

    return sortedKeys.flatMap((date) => {
      const notificationsForDate = groupedData.get(date) ?? [];

      return [
        date,
        ...notificationsForDate.sort(
          (notificationA, notificationB) =>
            dayjs(notificationB.sentOn).unix() -
            dayjs(notificationA.sentOn).unix(),
        ),
      ];
    });
  }, [notifications, t]);

  const virtualizer = useVirtualizer({
    count: groupedNotifications.length ?? 0,
    getScrollElement: () => containerRef,
    estimateSize: (index) => {
      const item = groupedNotifications[index];
      if (typeof item === 'string') return 37;
      return 126;
    },
    overscan: 10,
  });

  const renderItem = useCallback(
    (
      virtualRow: VirtualItem,
      notification: ReturnTypeFromNotifications | string,
    ) => {
      if (typeof notification === 'string') {
        return (
          <ListSubheader
            key={virtualRow.key}
            data-index={virtualRow.index}
            ref={virtualizer.measureElement}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${virtualRow.start}px)`,
            }}
          >
            {notification}
          </ListSubheader>
        );
      }

      const { id, title, text, sender, notificationType } = notification;
      const notificationIcon = NotificationIconMap[notificationType];
      const isUnread = !notification.recipients?.[0].readOn;
      const link = notificationUrls.get(id);
      const hasLink = !!link;

      return (
        <ListItem
          key={virtualRow.key}
          data-index={virtualRow.index}
          ref={virtualizer.measureElement}
          disablePadding
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${virtualRow.start}px)`,
          }}
        >
          <ListItemButton
            component={hasLink ? Link : 'div'}
            to={link}
            selected={isUnread}
            onClick={(e: React.MouseEvent) =>
              onNotificationClick(e, notification)
            }
          >
            {Object.keys(sender).length > 0 && (
              <ListItemAvatar>
                <Avatar
                  name={displayName(sender)}
                  person={sender}
                  src={sender.avatarUrl}
                />
              </ListItemAvatar>
            )}
            <ListItemText
              primary={title}
              secondary={
                <Stack component="span">
                  <span>{text}</span>
                  <Typography variant="caption" color="text.secondary">
                    {getRelativeDateFormat(notification.sentOn)}
                  </Typography>
                </Stack>
              }
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 36,
                minWidth: 36,
                height: 36,
                borderRadius: '50%',
                backgroundColor: notificationIcon.bgColor,
                color: notificationIcon.color,

                '& svg': {
                  width: 20,
                  height: 20,
                },
              }}
            >
              <notificationIcon.Icon />
            </Box>
          </ListItemButton>
        </ListItem>
      );
    },
    [notificationUrls],
  );

  const virtualNotifications = virtualizer.getVirtualItems();
  const lastVirtualItemIndex =
    virtualNotifications[virtualNotifications.length - 1]?.index;

  useEffect(() => {
    if (
      lastVirtualItemIndex === groupedNotifications.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    lastVirtualItemIndex,
    groupedNotifications.length,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  ]);

  return (
    <List
      disablePadding
      sx={{
        backgroundColor: 'transparent',
        '& .MuiListSubheader-root': {
          color: 'text.primary',
          fontWeight: 'bold',
          backgroundColor: 'transparent',
          py: 1,
          lineHeight: 1.5,
        },
        '& .MuiListItemButton-root': {
          border: 'solid 1px',
          borderColor: 'divider',
          px: 1.25,
          mx: 1,
          my: 0.5,
          borderRadius: 2,
        },
        position: 'relative',
        height: virtualizer.getTotalSize(),
      }}
    >
      {virtualNotifications.map((virtualRow) =>
        renderItem(virtualRow, groupedNotifications[virtualRow.index]),
      )}
      {groupedNotifications?.length === 0 && (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            py: 4,
          }}
        >
          <Typography variant="h2" component="span">
            🔔
          </Typography>
          <Typography variant="body1" component="span" color="text.secondary">
            {t('common:youreAllCaughtUp')}
          </Typography>
        </Stack>
      )}
    </List>
  );
}

export const VirtualizedNotificationsList = memo(
  VirtualizedNotificationsListInner,
);
