import {
  type CellRange,
  type CellRangeParams,
  type ColDef,
  type ColumnRowGroupChangedEvent,
  type ColumnState,
  type FillOperationParams,
  type GetContextMenuItems,
  type GetContextMenuItemsParams,
  type GetMainMenuItemsParams,
  type GetQuickFilterTextParams,
  type GridApi,
  type MenuItemDef,
  ModuleRegistry,
  type RowSelectionOptions,
  type ValueFormatterFunc,
  type ValueGetterParams,
} from '@ag-grid-community/core';
import {
  type ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { LicenseManager } from '@ag-grid-enterprise/core';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridReact, type AgGridReactProps } from '@ag-grid-community/react';

import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';

import '@ag-grid-community/styles/ag-grid.css';
import {
  Box,
  type BoxProps,
  type CardProps,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material';

import './styles.css';
import { usePreference } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { CollapseIcon, ExpandIcon } from '@tyro/icons';
import omit from 'lodash/omit';
import { useLocation } from 'react-router';
import { useMeasure } from 'react-use';
import { useDisclosure } from '../../../hooks/use-disclosure';
import { useExportReactToPdf } from '../../../hooks/use-export-react-to-pdf';
import { useMergeRefs } from '../../../hooks/use-merge-refs';
import { useRouteMatch } from '../../../hooks/use-route-match';
import { normaliseForeignCharacters } from '../../../utils/normalise-foreign-characters';
import { ExpandableCard } from '../../expandable-card';
import { SearchInput } from '../../search-input';
import {
  type ReturnTypeUseEditableState,
  type UseEditableStateProps,
  useEditableState,
} from '../hooks/use-editable-state';
import { getFullTableComponent } from '../utils/get-full-table-component';
import { BulkEditSaveBar } from './bulk-edit-save-bar';
import { TableLoadingOverlay } from './loading-overlay';
import { pdfExportIcon } from './pdf-export-icon';
import { TyroAggStatusPanel } from './tyro-agg-status-panel';

if (process.env.NODE_ENV === 'development') {
  // Monkey patching errors in dev to stop the console from being spammed with license errors
  const consoleError = console.error;
  const errorsToFilterOut = [
    '****************************************************************************************************************',
    'If you want to hide the watermark, please email info@ag-grid.com for a trial license',
    'This is an evaluation only version, it is not licensed for development projects intended for production',
    'All AG Grid Enterprise features are unlocked',
    '****************************************** License Key Not Found ***********************************************',
    '***************************************** AG Grid Enterprise License *******************************************',
    'If you want to hide the watermark please email info@ag-grid.com for a trial license key',
  ];

  console.error = function filteredErrors(...original) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [message] = original;
    if (
      typeof message !== 'string' ||
      !errorsToFilterOut.some((error) => message.includes(error))
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      consoleError(...original);
    }
  };
}

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ClipboardModule,
  ExcelExportModule,
  RangeSelectionModule,
  MenuModule,
  RowGroupingModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  StatusBarModule,
]);

export type {
  GridOptions,
  ICellRendererParams,
  CellValueChangedEvent,
  ICellEditorParams,
  ValueGetterParams,
  ValueFormatterParams,
  NewValueParams,
  ProcessCellForExportParams,
  ValueGetterFunc,
  CellClassParams,
  HeaderClassParams,
  IRowNode,
  StatusPanelDef,
  KeyCreatorParams,
  EditableCallbackParams,
  IAggFuncParams,
} from '@ag-grid-community/core';
export type {
  ReturnTypeUseEditableState as ReturnTypeTableUseEditableState,
  ValueSetterParams,
} from '../hooks/use-editable-state';

export type { AgGridReact } from '@ag-grid-community/react';

if (process.env.AG_GRID_KEY) {
  LicenseManager.setLicenseKey(process.env.AG_GRID_KEY);
}

export interface TableProps<T>
  extends Omit<AgGridReactProps<T>, 'rowSelection' | 'getContextMenuItems'> {
  tableId?: string | number;
  rowData: T[];
  getRowId: AgGridReactProps<T>['getRowId'];
  onBulkSave?: UseEditableStateProps<T>['onBulkSave'];
  onBulkSaveCanceled?: UseEditableStateProps<T>['onBulkSaveCanceled'];
  onRowSelection?: (selectedRows: T[]) => void;
  sx?: CardProps['sx'];
  tableContainerSx?: BoxProps['sx'];
  rightAdornment?: React.ReactNode;
  toolbar?: React.ReactNode;
  editingStateRef?: React.Ref<ReturnTypeUseEditableState<T>>;
  isLoading?: boolean;
  additionalEditBarElements?: React.ReactNode;
  externalSearchValue?: string;
  visibleDataRef?: React.Ref<() => T[]>;
  visibleRows?: number;
  autoSave?: boolean;
  additionalPdfExportCSS?: string;
  rowSelection?: 'single' | 'multiple' | RowSelectionOptions<T>;
  disableSavePreferences?: boolean;
  getContextMenuItems?: (
    params: Omit<GetContextMenuItemsParams<T>, 'defaultItems'> & {
      defaultItems: (string | MenuItemDef<T>)[];
    },
  ) => (string | MenuItemDef<T>)[];
}

const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  cellStyle: {
    alignItems: 'center',
  },
  useValueFormatterForExport: true,
  cellClass: (params) => {
    if (params.colDef.editable) {
      return 'ag-editable-cell';
    }
  },
  cellRendererSelector: ({ colDef, node }) => {
    if (node.group && colDef?.colId === 'ag-Grid-AutoColumn') {
      return { component: 'agGroupCellRenderer' };
    }
    return undefined;
  },
};

const quickFilterMatcher = (
  quickFilterParts: string[],
  rowQuickFilterAggregateText: string,
) => {
  const normalisedRow = normaliseForeignCharacters(rowQuickFilterAggregateText);

  const regex = new RegExp(
    quickFilterParts
      .map((value) => value?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|'),
    'i',
  );

  return regex.test(normalisedRow);
};

const setFillValue = ({
  initialNonAggregatedValues,
  currentIndex,
}: FillOperationParams) => {
  if (
    initialNonAggregatedValues.length === 2 &&
    initialNonAggregatedValues.every(
      (value) => value !== null && !Number.isNaN(Number(value)),
    )
  ) {
    const firstNumber = Number(initialNonAggregatedValues[0]);
    const secondNumber = Number(initialNonAggregatedValues[1]);
    const diff = secondNumber - firstNumber;
    const valueMultiplier = currentIndex + 1;
    return secondNumber + diff * valueMultiplier;
  }

  const fillIndex = currentIndex % initialNonAggregatedValues.length;
  return initialNonAggregatedValues[fillIndex];
};

const TOOLBAR_HEIGHT = 72;
const MIN_TABLE_HEIGHT = 460;

function TableInner<T extends object>(
  {
    onFirstDataRendered,
    onBulkSave,
    onBulkSaveCanceled,
    tableContainerSx,
    sx,
    onRowSelection,
    rightAdornment,
    autoGroupColumnDef,
    rowHeight = 56,
    rowSelection,
    toolbar,
    editingStateRef,
    isLoading,
    additionalEditBarElements,
    visibleDataRef,
    tableId,
    externalSearchValue = '',
    visibleRows,
    autoSave,
    additionalPdfExportCSS,
    disableSavePreferences = false,
    ...props
  }: TableProps<T>,
  ref: React.Ref<AgGridReact<T>>,
) {
  const initialColumnStateRef = useRef<ColumnState[]>();
  const { t } = useTranslation(['common']);
  const { palette } = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const tableRef = useRef<AgGridReact<T>>();
  const refs = useMergeRefs(tableRef, ref);
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const handledApplyPrefColsOnDefChange = useRef(false);
  const [tableContainerRef, { height: tableContainerHeight }] = useMeasure();
  const tableLocationKey = routeMatch ? routeMatch : location.pathname;
  const [preferredColumnState, setPreferredColumnState] = usePreference(
    `tables.${tableId ? tableLocationKey + tableId : tableLocationKey}`,
  );
  const preferredColumnStateByColId = useMemo(() => {
    if (!preferredColumnState) return new Map();
    return new Map(preferredColumnState.map((col) => [col.colId, col]));
  }, [JSON.stringify(preferredColumnState)]);

  const {
    isOpen: isExpanded,
    onToggle: onToggleExpansion,
    onClose: onCollapseExpansion,
  } = useDisclosure();
  const { exportReactToPdf } = useExportReactToPdf();

  const heightBasedOnRows = (props.rowData.length + 1) * rowHeight;
  const minTableHeight = visibleRows
    ? visibleRows * rowHeight
    : MIN_TABLE_HEIGHT;

  const innerContainerHeight = Math.max(
    Math.min(heightBasedOnRows, tableContainerHeight),
    minTableHeight,
  );

  const [tableHeight, setTableHeight] = useState(innerContainerHeight);

  const editingUtils = useEditableState<T>({
    autoSave,
    tableId,
    tableRef,
    onBulkSave,
    onBulkSaveCanceled,
  });
  const {
    isEditing,
    editingState,
    numberOfEdits,
    onSave,
    onCancel,
    onCellValueChanged,
    applyUpdatesToTable,
    onCellEditingStarted,
    onCellEditingStopped,
  } = editingUtils;

  useImperativeHandle(editingStateRef, () => editingUtils, [editingUtils]);
  useImperativeHandle(
    visibleDataRef,
    () => () => {
      const listData: Array<T> = [];
      tableRef?.current?.api?.forEachNodeAfterFilterAndSort?.((node) => {
        if (node.data) {
          listData.push(node.data);
        }
      });

      return listData;
    },
    [],
  );

  const mergedDefaultColDef = useMemo<ColDef>(
    () => ({
      ...defaultColDef,
      ...props?.defaultColDef,
    }),
    [JSON.stringify(props?.defaultColDef)],
  );

  const mappedRowSelection = useMemo<RowSelectionOptions<T> | undefined>(() => {
    const isMultiRowObject =
      typeof rowSelection === 'object' && rowSelection.mode === 'multiRow';
    if (rowSelection === 'multiple' || isMultiRowObject) {
      return {
        mode: 'multiRow',
        groupSelects: 'filteredDescendants',
        selectAll: 'filtered',
        checkboxes: ({ data }) => Boolean(data),
        ...(isMultiRowObject ? rowSelection : {}),
      };
    }

    const isSingleRowObject =
      typeof rowSelection === 'object' && rowSelection.mode === 'singleRow';
    if (rowSelection === 'single' || isSingleRowObject) {
      return {
        mode: 'singleRow',
        checkboxes: ({ data }) => Boolean(data),
        ...(isSingleRowObject ? rowSelection : {}),
      };
    }

    return rowSelection;
  }, [rowSelection]);

  const compareAndSaveState = useCallback(
    (columnState: ColumnState[]) => {
      const initialGridState = initialColumnStateRef.current;

      if (!initialGridState) {
        setPreferredColumnState(columnState);
        return;
      }

      if (JSON.stringify(columnState) !== JSON.stringify(initialGridState)) {
        setPreferredColumnState(columnState);
      } else {
        setPreferredColumnState(undefined);
      }
    },
    [setPreferredColumnState],
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRows = tableRef?.current?.api?.getSelectedRows();
    if (onRowSelection && selectedRows) {
      onRowSelection(selectedRows);
    }
  }, []);

  const defaultAutoGroupColumnDef =
    rowSelection === 'multiple'
      ? {
          cellRendererParams: {
            checkbox: true,
          },
        }
      : undefined;

  const onColumnRowGroupChanged = useCallback(
    ({ column, api }: ColumnRowGroupChangedEvent) => {
      if (column) {
        const colDef = column.getColDef();
        const sort = column.getSort();
        const colId = column.getColId();

        if (colDef.sortable && sort === undefined) {
          api.applyColumnState({
            state: [{ colId, sort: 'asc' }],
            defaultState: { sort: null },
          });
        }
      }
    },
    [],
  );

  const calculateTableHeight = useCallback(() => {
    const hasAutoHeight = (props.columnDefs || []).some(
      (columnDef: ColDef<T>) => columnDef.autoHeight,
    );

    if (hasAutoHeight) {
      const nodes = tableRef.current?.api.getRenderedNodes() || [];
      const rowIds = nodes.flatMap((row) => (row.id ? [row.id] : []));

      const currentTableHeight = rowIds.reduce((height, rowId) => {
        const currentRow = document.querySelector(`[row-id="${rowId}"]`);
        const { clientHeight = 0 } = currentRow || {};

        return height + clientHeight;
      }, TOOLBAR_HEIGHT);

      setTableHeight(currentTableHeight);
    }
  }, [props.columnDefs]);

  const getColumnMenuItems = useCallback(
    (params: GetMainMenuItemsParams<T>): (string | MenuItemDef)[] => {
      const gridApi = params.api;
      if (!gridApi) return params.defaultItems || [];

      const column = params.column;
      if (!column) return params.defaultItems;

      const columnId = column.getColId();
      const rangeSelections = gridApi.getCellRanges();

      const isColumnFullySelected = (range: CellRange): boolean => {
        return (
          range.columns.length === 1 &&
          range.columns[0].getColId() === columnId &&
          range.startRow?.rowIndex === 0 &&
          range.endRow?.rowIndex === gridApi.getDisplayedRowCount() - 1
        );
      };

      const isColumnSelected = rangeSelections?.some(isColumnFullySelected);

      const selectMenuItem = {
        name: isColumnSelected
          ? t('common:unhighlightColumn')
          : t('common:highlightColumn'),
        action: () => {
          if (isColumnSelected) {
            gridApi.clearCellSelection();
          } else {
            const cellRange: CellRangeParams = {
              rowStartIndex: 0,
              rowEndIndex: gridApi.getDisplayedRowCount() - 1,
              columns: [column],
            };
            gridApi.addCellRange(cellRange);
          }
        },
      };

      return [...(params.defaultItems || []), selectMenuItem];
    },
    [t],
  );

  const getContextMenuItems = useCallback<GetContextMenuItems<T>>(
    ({ defaultItems: originalDefaultItems, ...params }) => {
      const defaultItems = [
        'cut',
        'copy',
        'copyWithHeaders',
        'copyWithGroupHeaders',
        'paste',
        'separator',
        {
          name: 'Export',
          icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
          subMenu: [
            'csvExport',
            'excelExport',
            {
              name: 'PDF Export',
              icon: pdfExportIcon,
              action: () => {
                const gridApi = params.api;
                exportReactToPdf(
                  () => getFullTableComponent(gridApi),
                  `
                  table {
                    border-collapse: collapse;
                    border: 1px solid ${palette.slate[300]};
                    font-size: 14px;
                  }

                  th {
                    background-color: ${palette.slate[50]};
                  }

                  th, td {
                    border: 1px solid ${palette.slate[300]};
                    text-align: left;
                    padding: 4px 8px;
                  }

                  a {
                    color: ${palette.text.primary} !important;
                    pointer-events: none !important;
                    font-weight: normal !important;
                  }
                  ${additionalPdfExportCSS ?? ''}
                `,
                );
              },
            },
          ],
        },
      ];

      return typeof props.getContextMenuItems === 'function'
        ? props.getContextMenuItems({
            defaultItems,
            ...params,
          })
        : defaultItems;
    },
    [
      exportReactToPdf,
      palette,
      additionalPdfExportCSS,
      props.getContextMenuItems,
    ],
  );

  const getMergedColDef = useCallback(
    (columnStates: (ColumnState | ColDef<T>)[], api?: GridApi<T>) => {
      const preferredColumnStateKeys = Array.from(
        preferredColumnStateByColId.keys(),
      );

      return columnStates
        .map((columnState) => {
          const colId = columnState.colId || (columnState as ColDef).field!;
          const preferredColumn = preferredColumnStateByColId.get(colId);
          const lockVisible =
            api?.getColumnDef(colId)?.lockVisible ??
            (columnState as ColDef)?.lockVisible;

          if (preferredColumn) {
            return {
              colId: columnState.colId,
              ...columnState,
              ...(omit(preferredColumn, ['aggFunc']) as typeof preferredColumn),
              hide:
                lockVisible && columnState?.hide !== true
                  ? false
                  : preferredColumn.hide,
            };
          }

          return columnState;
        })
        .sort((a, b) => {
          const aPreviousIndex = preferredColumnStateKeys.indexOf(a.colId);
          const bPreviousIndex = preferredColumnStateKeys.indexOf(b.colId);

          if (aPreviousIndex === -1 || bPreviousIndex === -1) {
            return 0;
          }

          return aPreviousIndex - bPreviousIndex;
        });
    },
    [preferredColumnStateByColId],
  );

  const applyPreferredColumnState = useCallback(
    (api: GridApi<T>) => {
      const initialColumnState = initialColumnStateRef.current;

      handledApplyPrefColsOnDefChange.current = true;
      if (initialColumnState && preferredColumnStateByColId.size > 0) {
        api.applyColumnState({
          state: getMergedColDef(initialColumnState || [], api),
          applyOrder: true,
          defaultState: {
            sort: null,
            rowGroup: null,
            pivot: null,
            pinned: null,
          },
        });
      }
    },
    [getMergedColDef, preferredColumnStateByColId],
  );

  useEffect(() => {
    handledApplyPrefColsOnDefChange.current = false;
  }, [props.columnDefs]);

  useEffect(() => {
    if (externalSearchValue !== searchValue) {
      setSearchValue(externalSearchValue);
    }
  }, [externalSearchValue]);

  useEffect(() => {
    return () => {
      for (const popup of Array.from(document.querySelectorAll('.ag-popup'))) {
        popup.remove();
      }
    };
  }, []);

  const internalColumnDefs = useMemo(() => {
    const initialColumnDefs =
      props?.columnDefs?.map((colDef) => {
        const isGroupColDef = 'children' in colDef;
        if (!isGroupColDef) {
          const valueGetter =
            typeof colDef?.valueGetter === 'function'
              ? colDef.valueGetter
              : null;

          let getQuickFilterText = colDef.getQuickFilterText;

          if (
            typeof colDef?.valueFormatter === 'function' &&
            !colDef.getQuickFilterText
          ) {
            getQuickFilterText = (
              params: GetQuickFilterTextParams<T, unknown>,
            ) =>
              (colDef.valueFormatter as ValueFormatterFunc<T, unknown>)({
                ...params,
                value: valueGetter?.({
                  ...params,
                  getValue: () => null,
                }),
              });
          }

          let filterValueGetter = colDef.filterValueGetter;
          if (
            typeof colDef?.valueFormatter === 'function' &&
            !colDef.filterValueGetter
          ) {
            filterValueGetter = (params: ValueGetterParams<T, unknown>) =>
              (colDef.valueFormatter as ValueFormatterFunc<T, unknown>)({
                ...params,
                value: valueGetter?.(params),
              });
          }

          return {
            ...colDef,
            getQuickFilterText,
            filterValueGetter,
          };
        }

        return colDef;
      }) || [];

    if (disableSavePreferences === false) {
      return getMergedColDef(initialColumnDefs || []);
    }

    return initialColumnDefs;
  }, [disableSavePreferences, props.columnDefs]);

  const maxHeight = Math.max(minTableHeight, heightBasedOnRows, tableHeight);

  return (
    <>
      <ExpandableCard
        isExpanded={isExpanded}
        onCollapse={onCollapseExpansion}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          '&:not(.expanded)': {
            minHeight: minTableHeight + TOOLBAR_HEIGHT,
            maxHeight: maxHeight + TOOLBAR_HEIGHT,
          },
          ...sx,
        }}
      >
        {toolbar || (
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            p={2}
          >
            <SearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              {rightAdornment}
              <IconButton onClick={onToggleExpansion}>
                {isExpanded ? (
                  <CollapseIcon sx={{ color: 'primary.main' }} />
                ) : (
                  <ExpandIcon sx={{ color: 'primary.main' }} />
                )}
              </IconButton>
            </Stack>
          </Stack>
        )}
        <Box
          ref={tableContainerRef}
          className="ag-theme-tyro"
          sx={{
            ...tableContainerSx,
          }}
        >
          <Box flex={1}>
            {isLoading ? (
              <TableLoadingOverlay />
            ) : (
              <AgGridReact<(typeof props.rowData)[number]>
                ref={refs}
                quickFilterText={searchValue}
                undoRedoCellEditing
                undoRedoCellEditingLimit={20}
                popupParent={document.body}
                cellSelection={{
                  suppressMultiRanges: true,
                  handle: {
                    mode: 'fill',
                    direction: 'y',
                    setFillValue,
                  },
                }}
                allowContextMenuWithControlKey
                onSelectionChanged={onSelectionChanged}
                rowHeight={rowHeight}
                rowSelection={mappedRowSelection}
                autoGroupColumnDef={
                  autoGroupColumnDef || defaultAutoGroupColumnDef
                }
                stopEditingWhenCellsLoseFocus
                processCellForClipboard={({ value }) => {
                  if (value === null || value === undefined) {
                    return '';
                  }

                  if (typeof value === 'object') {
                    return JSON.stringify(value);
                  }

                  return value as unknown;
                }}
                {...props}
                getContextMenuItems={getContextMenuItems}
                onRowDataUpdated={(params) => {
                  if (params.api.getSelectedRows().length > 0) {
                    params.api.deselectAll();
                  }
                  props.onRowDataUpdated?.(params);
                }}
                columnDefs={internalColumnDefs}
                onCellEditingStarted={(params) => {
                  onCellEditingStarted();
                  props.onCellEditingStarted?.(params);
                }}
                onCellEditingStopped={(params) => {
                  onCellEditingStopped();
                  props.onCellEditingStopped?.(params);
                }}
                defaultColDef={mergedDefaultColDef}
                components={{
                  ...props.components,
                  tyroAggStatusPanel: TyroAggStatusPanel,
                }}
                getMainMenuItems={getColumnMenuItems}
                quickFilterMatcher={quickFilterMatcher}
                onCellValueChanged={(args) => {
                  onCellValueChanged(args);
                  props.onCellValueChanged?.(args);
                }}
                onFirstDataRendered={(params) => {
                  const { api } = params;
                  api.autoSizeAllColumns(false);
                  const columnWidths = props.columnDefs
                    ?.filter(
                      (column: ColDef<T>) =>
                        column?.width && (column?.field || column?.colId),
                    )
                    ?.map((column: ColDef<T>) => ({
                      key: (column.field || column.colId) as string,
                      newWidth: column.width as number,
                    }));

                  if (columnWidths) {
                    api.setColumnWidths(columnWidths);
                  }

                  applyUpdatesToTable('newValue');

                  onFirstDataRendered?.(params);
                  calculateTableHeight();
                  initialColumnStateRef.current = api.getColumnState();
                  if (disableSavePreferences === false) {
                    applyPreferredColumnState(api);
                  }
                }}
                onModelUpdated={(params) => {
                  props.onModelUpdated?.(params);
                  calculateTableHeight();
                }}
                onDisplayedColumnsChanged={(params) => {
                  applyUpdatesToTable('newValue');
                  props?.onDisplayedColumnsChanged?.(params);
                }}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
                onStateUpdated={(event) => {
                  const allPinnedCols =
                    event.state.columnPinning?.leftColIds ?? [];
                  const hasPinnedCols =
                    allPinnedCols.filter((col) => !col.startsWith('ag-Grid'))
                      .length > 0;
                  const isCheckboxPinned = allPinnedCols.includes(
                    'ag-Grid-ControlsColumn',
                  );

                  if (!isCheckboxPinned && hasPinnedCols) {
                    event.api.setColumnsPinned(
                      ['ag-Grid-ControlsColumn'],
                      'left',
                    );
                  } else if (isCheckboxPinned && !hasPinnedCols) {
                    event.api.setColumnsPinned(
                      ['ag-Grid-ControlsColumn'],
                      null,
                    );
                  }

                  const columnStateKeys = [
                    'aggregation',
                    'columnOrder',
                    'columnPinning',
                    'columnSizing',
                    'columnVisibility',
                    'pivot',
                    'rowGroup',
                    'sort',
                  ];
                  const isColumnStateChange = event.sources.some((source) =>
                    columnStateKeys.includes(source),
                  );

                  if (isColumnStateChange && disableSavePreferences === false) {
                    if (handledApplyPrefColsOnDefChange.current) {
                      compareAndSaveState(event.api.getColumnState());
                    } else {
                      applyPreferredColumnState(event.api);
                    }
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </ExpandableCard>
      <BulkEditSaveBar
        isEditing={isEditing}
        editingState={editingState}
        numberOfEdits={numberOfEdits}
        onSave={onSave}
        onCancel={onCancel}
        additionalEditBarElements={additionalEditBarElements}
      />
    </>
  );
}

export const Table = forwardRef(TableInner) as <T>(
  props: TableProps<T> & { ref?: ForwardedRef<AgGridReact<T>> | null },
) => ReturnType<typeof TableInner>;
