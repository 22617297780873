import { ClickAwayListener } from '@mui/material';
import { Fade, Paper } from '@mui/material';
import { Box, Stack, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';
import type { TemplatingContextType } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { NewTemplateIcon } from '@tyro/icons';
import type { TemplatesData } from '../../toolbar/template-variable-search-wrapper/templates-autocomplete';
import type { VariablesData } from '../../toolbar/template-variable-search-wrapper/variables-autocomplete';
import { TemplatesAutocomplete } from '../template-variable-search-wrapper/templates-autocomplete';
import { VariablesAutocomplete } from '../template-variable-search-wrapper/variables-autocomplete';

type TemplateVariableSearchProps = {
  isVariableSearchOpen: boolean;
  setIsVariableSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isTemplateSearchOpen: boolean;
  setIsTemplateSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contextType: TemplatingContextType;
  editor: Editor | null;
  variablesData: VariablesData;
  templatesData: TemplatesData;
};
export function TemplateVariableSearch({
  isVariableSearchOpen,
  setIsVariableSearchOpen,
  isTemplateSearchOpen,
  setIsTemplateSearchOpen,
  contextType,
  editor,
  variablesData,
  templatesData,
}: TemplateVariableSearchProps) {
  const { t } = useTranslation(['common']);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '100%',
        left: 0,
        right: 0,
        zIndex: 1000,
        ml: 1,
      }}
    >
      <Fade in={isVariableSearchOpen || isTemplateSearchOpen} unmountOnExit>
        <Box
          sx={{
            p: '4px',
            backgroundColor: 'background.paper',
            borderRadius: '20px 20px 0 0',
            boxShadow: '0px -3px 4px 0px #6366F12E',
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              setIsVariableSearchOpen(false);
              setIsTemplateSearchOpen(false);
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 2,
                borderRadius: '20px 20px 0 0',
                backgroundColor: 'slate.50',
              }}
            >
              <Stack display="flex" flexDirection="row" alignItems="center">
                <NewTemplateIcon />
                <Typography variant="body2" ml={1}>
                  {isVariableSearchOpen
                    ? t('common:variables')
                    : t('common:templates')}
                </Typography>
                {isVariableSearchOpen && contextType && (
                  <VariablesAutocomplete
                    setIsVariableSearchOpen={setIsVariableSearchOpen}
                    variablesData={variablesData}
                    editor={editor}
                  />
                )}
                {isTemplateSearchOpen && contextType && (
                  <TemplatesAutocomplete
                    setIsTemplateSearchOpen={setIsTemplateSearchOpen}
                    editor={editor}
                    templatesData={templatesData}
                  />
                )}
              </Stack>
            </Paper>
          </ClickAwayListener>
        </Box>
      </Fade>
    </Box>
  );
}
