import {
  Badge,
  Box,
  Card,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { useTranslation } from '@tyro/i18n';

import { AttendanceToggle } from '@tyro/attendance';

import {
  AttendanceCodeType,
  DyRel_EntityType,
  Notesv2_TagType,
  getPersonProfileLink,
} from '@tyro/api';
import {
  EditState,
  ListNavigatorType,
  type PartyListNavigatorMenuItemParams,
  RouterLink,
  useDisclosure,
  useListNavigatorSettings,
  usePreferredNameLayout,
  useResponsive,
  useToast,
} from '@tyro/core';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DocEditIcon,
  EditIcon,
} from '@tyro/icons';
import { StudentAvatar, useNotesV2 } from '@tyro/people';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useEffect, useMemo, useState } from 'react';
import {
  type GroupStudent,
  type StudentAttendance,
  useHandleLessonAttendance,
} from '../../../hooks';
import { AdditionalLessonsModal } from './additional-lessons-modal';
import { AttendanceGroupNotesModal } from './note-modal';
import { SaveBar } from './save-bar';

dayjs.extend(isBetween);

type AttendanceProps = {
  partyId: number;
  eventStartTime?: string | null;
  students: GroupStudent[];
  groupName: string | undefined;
  onSave?: (studentAttendance: StudentAttendance) => void;
};

const previousAttendanceCodeColor = {
  [AttendanceCodeType.Present]: 'text.secondary',
  [AttendanceCodeType.ExplainedAbsence]: 'pink.600',
  [AttendanceCodeType.UnexplainedAbsence]: 'pink.600',
  [AttendanceCodeType.Late]: 'sky.600',
  [AttendanceCodeType.NotTaken]: 'text.secondary',
} as const;

export const GroupAttendance = ({
  partyId,
  eventStartTime,
  students,
  groupName = '',
  onSave,
}: AttendanceProps) => {
  const { toast } = useToast();

  const { t } = useTranslation(['common', 'groups', 'attendance']);
  const { displayName, sortByDisplayName } = usePreferredNameLayout();
  const isXs = useResponsive('only', 'xs');
  const showAvatar = useResponsive('up', 370);
  const {
    isOpen: isNotesOpen,
    onOpen: onOpenNotes,
    onClose: onCloseNotes,
  } = useDisclosure();

  const {
    lessonId,
    currentLesson,
    attendance,
    updatedAt,
    updatedBy,
    unsavedChanges,
    someStudentsHaveNoAttendance,
    isAttendanceTaken,
    isLessonLoading,
    isEmptyLesson,
    formattedLessonDate,
    isSaveAttendanceLoading,
    additionalLessons,
    previousAttendanceTypeByPersonPartyId,
    nextLesson,
    previousLesson,
    getStudentEventDetails,
    getStudentAttendanceCode,
    setStudentAttendanceCode,
    editAttendance,
    saveAttendance,
    cancelAttendance,
    disableNextLesson,
    disablePrevLesson,
  } = useHandleLessonAttendance({
    partyId,
    eventStartTime,
    students,
  });

  const { data: notesData } = useNotesV2({
    tagTypes: [Notesv2_TagType.SubstitutionNote],
    noteIds: [],
    dyRel: [
      {
        type: DyRel_EntityType.Party,
        id: partyId.toString(),
      },
    ],
  });

  const [editingState, setEditingState] = useState<EditState>(EditState.Idle);
  const [showAdditionalLessonsModal, setShowAdditionalLessonsModal] =
    useState(false);

  useEffect(() => {
    setEditingState(EditState.Idle);
  }, [lessonId, unsavedChanges]);

  useEffect(() => {
    if (isSaveAttendanceLoading) {
      setEditingState(EditState.Saving);
    }
  }, [isSaveAttendanceLoading]);

  const handleSaveCurrentAttendance = () => {
    if (onSave) {
      return onSave(attendance);
    }
    return saveAttendance({
      lessonIds: [lessonId],
      onSuccess: (invalidateQuery) => {
        setEditingState(EditState.Saved);

        setTimeout(async () => {
          await invalidateQuery();
          setShowAdditionalLessonsModal(additionalLessons.length > 0);
        }, 250);
      },
    });
  };

  const handleSaveAdditionalLessons = (lessonIds: number[]) => {
    if (onSave) {
      return onSave(attendance);
    }

    return saveAttendance({
      lessonIds,
      onSuccess: async (invalidateQuery) => {
        await invalidateQuery();
        setShowAdditionalLessonsModal(false);
        toast(t('common:snackbarMessages.updateSuccess'));
      },
    });
  };

  const sortedStudents = useMemo(
    () => [...students].sort((a, b) => sortByDisplayName(a.person, b.person)),
    [students, sortByDisplayName],
  );
  const notesForLesson = useMemo(() => {
    if (!currentLesson) return [];

    const lessonStartTime = dayjs(currentLesson.startTime);
    const lessonEndTime = dayjs(currentLesson.endTime);

    return (
      notesData?.notes.filter(({ fromDate, toDate }) => {
        return (
          lessonStartTime.isBetween(
            dayjs(fromDate),
            dayjs(toDate),
            'day',
            '[]',
          ) ||
          lessonEndTime.isBetween(dayjs(fromDate), dayjs(toDate), 'day', '[]')
        );
      }) ?? []
    );
  }, [notesData, currentLesson]);

  const { storeList } =
    useListNavigatorSettings<PartyListNavigatorMenuItemParams>({
      type: ListNavigatorType.Student,
    });

  const goToStudentProfile = () => {
    storeList(
      groupName,
      students.map(({ person, classGroup }) => ({
        id: person.partyId,
        type: 'person',
        name: displayName(person),
        firstName: person.firstName,
        lastName: person.lastName,
        avatarUrl: person.avatarUrl,
        caption: classGroup?.name,
      })),
    );
  };

  const isLoading = isLessonLoading || isSaveAttendanceLoading;
  const showStudentsTable = !isLessonLoading && !isEmptyLesson;

  return (
    <>
      <Card
        key={lessonId}
        variant="outlined"
        sx={{ width: '100%', maxWidth: '540px', overflow: 'visible' }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            py: 1,
            borderTop: '0',
            borderLeft: '0',
            borderRight: '0',
            borderBottom: '1px',
            borderStyle: 'solid',
            borderColor: 'divider',
          }}
        >
          <IconButton
            size="small"
            color="primary"
            disabled={isLoading || disablePrevLesson}
            onClick={previousLesson}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              gap: 0.25,
              justifyContent: 'center',
              alignItems: 'center',
              px: 2,
            }}
          >
            {isLessonLoading ? (
              <Skeleton
                variant="text"
                height="26px"
                width="220px"
                sx={{ margin: '0 auto' }}
              />
            ) : (
              <>
                <Typography
                  component="h4"
                  variant="subtitle2"
                  noWrap
                  sx={{ textOverflow: 'ellipsis' }}
                >
                  {formattedLessonDate}
                </Typography>
                <Tooltip
                  title={
                    notesForLesson.length === 0
                      ? t('attendance:noNotes')
                      : t('attendance:xNotes', {
                          count: notesForLesson.length,
                        })
                  }
                >
                  <span>
                    <IconButton
                      sx={({ palette }) => ({
                        backgroundColor: palette.blue.main,
                        '&:hover': {
                          backgroundColor: alpha(palette.blue.main, 0.8),
                        },
                        '&.Mui-disabled': {
                          backgroundColor: alpha(palette.blue.main, 0.4),
                          color: 'white',
                        },
                        width: 20,
                        height: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        borderRadius: '50%',
                        mx: 1,
                      })}
                      disabled={notesForLesson.length === 0}
                      aria-label={
                        notesForLesson.length === 0
                          ? t('attendance:noNotes')
                          : t('attendance:xNotes', {
                              count: notesForLesson.length,
                            })
                      }
                      onClick={onOpenNotes}
                    >
                      <Badge
                        color="primary"
                        badgeContent={notesForLesson.length}
                        sx={{
                          '& .MuiBadge-badge': {
                            p: 0,
                            minWidth: '14px',
                            height: '14px',
                            fontSize: 10,
                            border: '1px solid white',
                          },
                        }}
                      >
                        <DocEditIcon
                          sx={{
                            width: 14,
                            height: 14,
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
          </Box>
          <IconButton
            size="small"
            color="primary"
            disabled={isLoading || disableNextLesson}
            onClick={nextLesson}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>
        {isEmptyLesson && (
          <Stack justifyContent="center" alignItems="center" minHeight={300}>
            <Typography variant="h6" component="span">
              {t('groups:lessonAttendanceNotFound')}
            </Typography>
          </Stack>
        )}
        {showStudentsTable && (
          <Table
            size="small"
            sx={{
              '& th': {
                background: 'transparent',
                color: 'text.primary',
                fontWeight: 600,
              },
              '& th:first-of-type': {
                width: '100%',
              },
              '& .MuiTableCell-root': {
                paddingX: {
                  xs: 1.5,
                  sm: 2,
                },
              },
              '& td:first-of-type': {
                width: '40%',
                paddingRight: 0,
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>{t('groups:students')}</TableCell>
                <TableCell>{t('groups:status')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedStudents.map((student) => {
                const eventDetails = getStudentEventDetails(student.partyId);
                const submittedBy = displayName(
                  eventDetails?.updatedBy ?? eventDetails?.createdBy,
                );
                const previousLessonCode =
                  previousAttendanceTypeByPersonPartyId.get(student.partyId) ??
                  AttendanceCodeType.NotTaken;

                return (
                  <TableRow key={student?.partyId}>
                    <TableCell>
                      <Stack
                        direction="row"
                        gap={isXs ? 1 : 2}
                        alignItems="center"
                      >
                        {showAvatar && (
                          <StudentAvatar
                            partyId={student?.partyId}
                            name={displayName(student?.person)}
                            isPriorityStudent={!!student?.extensions?.priority}
                            hasSupportPlan={!!student?.extensions?.aen}
                            hasMedical={!!student?.extensions?.medical}
                            currentGroup={{
                              partyId,
                              name: groupName,
                            }}
                            src={student?.person?.avatarUrl}
                            person={student?.person}
                            size={isXs ? 36 : undefined}
                          />
                        )}
                        <Stack direction="column">
                          <RouterLink
                            fontWeight={600}
                            to={getPersonProfileLink(student.person) || ''}
                            onClick={goToStudentProfile}
                          >
                            {displayName(student?.person)}
                          </RouterLink>
                          <Typography
                            variant="body2"
                            color={
                              previousAttendanceCodeColor[previousLessonCode]
                            }
                          >
                            {previousLessonCode === AttendanceCodeType.NotTaken
                              ? '-'
                              : t(
                                  `attendance:previousAttendanceCode.${previousLessonCode}`,
                                )}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {eventDetails?.isEditMode ? (
                        <AttendanceToggle
                          codeId={getStudentAttendanceCode(student.partyId)}
                          onChange={setStudentAttendanceCode(student.partyId)}
                        />
                      ) : (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={1}
                        >
                          <Stack direction="column">
                            <Typography variant="caption" fontWeight={600}>
                              {eventDetails?.attendanceCode?.name ?? '-'}
                            </Typography>
                            {eventDetails?.note && (
                              <Typography component="span" variant="caption">
                                {eventDetails?.note}
                              </Typography>
                            )}
                            {submittedBy && (
                              <Typography
                                component="span"
                                variant="caption"
                                color="text.secondary"
                              >
                                {`${t('common:submittedBy')} ${submittedBy}`}
                              </Typography>
                            )}
                          </Stack>
                          <IconButton
                            aria-label={t('common:actions.edit')}
                            color="primary"
                            onClick={() => editAttendance(student.partyId)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Card>

      {isEmptyLesson || isLessonLoading ? null : (
        <SaveBar
          someStudentsHaveNoAttendance={someStudentsHaveNoAttendance}
          isAttendanceTaken={isAttendanceTaken}
          editingState={editingState}
          attendance={attendance}
          updatedAt={updatedAt}
          updatedBy={updatedBy}
          unsavedChanges={unsavedChanges}
          onCancel={cancelAttendance}
          onSave={handleSaveCurrentAttendance}
        />
      )}

      {showAdditionalLessonsModal && (
        <AdditionalLessonsModal
          isSaving={editingState === EditState.Saving}
          attendance={attendance}
          currentLesson={currentLesson}
          updatedAt={updatedAt}
          updatedBy={updatedBy}
          lessons={additionalLessons}
          onClose={() => setShowAdditionalLessonsModal(false)}
          onSave={handleSaveAdditionalLessons}
        />
      )}

      <AttendanceGroupNotesModal
        isOpen={isNotesOpen}
        onClose={onCloseNotes}
        notes={notesForLesson}
      />
    </>
  );
};
