import { InputAdornment } from '@mui/material';
import type { Editor } from '@tiptap/react';
import type { TemplatingContextType } from '@tyro/api';
import { Autocomplete } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { SearchIcon } from '@tyro/icons';
import { useVariablesQuery } from '@tyro/templates';

type VariablesAutocompleteProps = {
  contextType: TemplatingContextType | undefined;
  setIsVariableSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editor: Editor | null;
};

type ChipValueTypes = {
  label: string;
  value: string;
};

export function VariablesAutocomplete({
  contextType,
  setIsVariableSearchOpen,
  editor,
}: VariablesAutocompleteProps) {
  const { t } = useTranslation(['common']);
  const { data: variables, isLoading } = useVariablesQuery({
    context: contextType,
  });

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleVariableSelect = (_event: any, selectedVariable: any) => {
    if (selectedVariable?.value && editor) {
      editor.commands.insertContent(`{{${selectedVariable.value}}}`);
      setIsVariableSearchOpen(false);
    }
  };

  const handleChipInsertion = ({ label, value }: ChipValueTypes) => {
    // @ts-expect-error
    editor?.chain().focus().addVariable({ label, value }).run();
    setIsVariableSearchOpen(false);
  };

  return (
    <Autocomplete
      fullWidth
      optionIdKey="id"
      optionTextKey={'name'}
      loading={isLoading}
      options={variables ?? []}
      onChange={handleVariableSelect}
      renderOption={(props, option) => (
        <li
          {...props}
          onClick={(event) => {
            event.stopPropagation();
            handleChipInsertion({
              label: option?.name ?? t('common:default'),
              value: option?.value ?? t('common:defaultLabelValue'),
            });
          }}
        >
          {option.name}
        </li>
      )}
      ChipProps={{ size: 'small' }}
      inputProps={{
        sx: {
          '& .MuiInputBase-root': {
            ml: 1,
            backgroundColor: 'background.paper',
            border: 'none',
            height: '40px',
          },
          '& fieldset': {
            border: 'none',
          },
        },
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
