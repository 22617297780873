import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFAutocomplete,
  naturalSort,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  type ReturnTypeFromUseAttendanceCodes,
  useAttendanceCodes,
} from '../api';
import { useMergeAttendanceCodes } from '../api/merge-codes';

interface MergeAttendanceCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  attendanceCodeToMergeFrom: ReturnTypeFromUseAttendanceCodes | null;
}

type FormState = {
  codeToMergeToo: ReturnTypeFromUseAttendanceCodes | null;
};

export function MergeAttendanceCodeModal({
  isOpen,
  onClose,
  attendanceCodeToMergeFrom,
}: MergeAttendanceCodeModalProps) {
  const { t } = useTranslation(['common', 'attendance']);
  const [step, setStep] = useState(0);

  const { data: attendanceCodes } = useAttendanceCodes({});
  const { mutateAsync: mergeAttendanceCode } = useMergeAttendanceCodes();

  const { resolver, rules } = useFormValidator<FormState>();
  const { control, handleSubmit, watch, reset } = useForm<FormState>({
    resolver: resolver({
      codeToMergeToo: rules.required(),
    }),
  });
  const codeToMergeToo = watch('codeToMergeToo');

  const submit = handleSubmit(() => {
    setStep(1);
  });

  const confirm = () => {
    mergeAttendanceCode(
      {
        attendanceCodeIdToDelete: attendanceCodeToMergeFrom!.id,
        attendanceCodeIdToKeep: codeToMergeToo!.id,
      },
      {
        onSuccess: onClose,
      },
    );
  };

  useEffect(() => {
    if (isOpen) {
      setStep(0);
      reset();
    }
  }, [isOpen, reset]);

  const selectOptions = useMemo(() => {
    if (!attendanceCodeToMergeFrom) return attendanceCodes;

    return attendanceCodes
      ?.filter(
        (attendanceCode) => attendanceCode.id !== attendanceCodeToMergeFrom.id,
      )
      .sort((a, b) => naturalSort(a.name, b.name));
  }, [attendanceCodes, attendanceCodeToMergeFrom]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t('attendance:mergeCodeIntoAnother')}</DialogTitle>
      <DialogContent>
        {step === 0 ? (
          <Stack spacing={2} mt={0.5}>
            <DialogContentText>
              {t('attendance:mergeAttendanceDescription', {
                codeName: attendanceCodeToMergeFrom?.name,
              })}
            </DialogContentText>
            <RHFAutocomplete
              options={selectOptions ?? []}
              optionTextKey="name"
              optionIdKey="id"
              controlProps={{
                name: 'codeToMergeToo',
                control,
              }}
              filterOptions={(options, { inputValue }) => {
                if (inputValue === '') return options;

                return options.filter(({ name, description }) => {
                  const lowerCaseSearchValue = inputValue.toLowerCase();

                  return (
                    name.toLowerCase().includes(lowerCaseSearchValue) ||
                    description?.toLowerCase().includes(lowerCaseSearchValue)
                  );
                });
              }}
              renderOption={(props, option) => (
                <Stack
                  component="li"
                  {...props}
                  sx={{ alignItems: 'flex-start !important' }}
                >
                  <Typography variant="subtitle2">{option.name}</Typography>
                  <Typography variant="caption">
                    {option.description ?? '-'}
                  </Typography>
                </Stack>
              )}
            />
          </Stack>
        ) : (
          <Alert severity="warning">
            <AlertTitle>{t('common:areYouSureYouWantToContinue')}</AlertTitle>
            {t('attendance:mergeAttendanceWarning', {
              codeFromName: attendanceCodeToMergeFrom?.name,
              codeTooName: codeToMergeToo?.name,
            })}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="soft" color="inherit" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
        {step === 0 ? (
          <Button variant="contained" onClick={submit}>
            {t('common:actions.continue')}
          </Button>
        ) : (
          <LoadingButton variant="contained" onClick={confirm}>
            {t('common:actions.confirm')}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
