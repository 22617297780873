import type { ReturnTypeFromUseRunReportCharts } from '../api/run-report';

const labelOrder = [
  'Present',
  'Late',
  'Explained Absence',
  'Unexplained Absence',
];

export function sortAttendanceChartSeries(
  chartData: ReturnTypeFromUseRunReportCharts | undefined,
) {
  if (!chartData) return chartData;

  return {
    ...chartData,
    series: chartData.series.sort((seriesA, seriesB) => {
      const indexA = labelOrder.indexOf(seriesA.name);
      const indexB = labelOrder.indexOf(seriesB.name);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return 0;
    }),
  };
}
