import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { FileTransferRiuType } from '@tyro/api';
import { VariableChipNode } from '@tyro/core';
import { type TFunction, useTranslation } from '@tyro/i18n';

import {
  FileRejectedCode,
  ImageResize,
  useFileErrorMessage,
  useUploadAndInsertImage,
} from '@tyro/core';

import { acceptedAttachmentType } from '../components/editor/attach-files';
import type { AttachedFilesListProps } from '../components/editor/attach-files/list';

interface ExtensionOptions {
  placeholder?: string;
  t: TFunction<'common'[]>;
}

const getExtensions = ({ placeholder, t }: ExtensionOptions) => [
  StarterKit.configure({
    heading: false,
  }),
  Underline,
  Placeholder.configure({
    placeholder,
  }),
  Link.configure({
    openOnClick: false,
    validate: (href) => /^https?:\/\//.test(href),
  }),
  ImageResize(t),
  VariableChipNode,
];

type useMailEditorProps = {
  defaultContent?: string;
  placeholder?: string;
  setAttachFiles: AttachedFilesListProps['setAttachFiles'];
};

enum VariableCustomChipData {
  customChip = 'Variable custom chip',
}

export function useMailEditor({
  defaultContent = '',
  placeholder = '',
  setAttachFiles,
}: useMailEditorProps) {
  const { t } = useTranslation(['common']);

  const { handleDropImageEditor } = useUploadAndInsertImage({
    riuType: FileTransferRiuType.Mail,
  });
  const { showErrorMessage } = useFileErrorMessage();

  return useEditor({
    extensions: getExtensions({ placeholder, t }),
    content: defaultContent,
    editorProps: {
      handleDrop: (view, event, _slice, moved) => {
        const getVariableCustomChipData = event.dataTransfer?.getData(
          'application/x-prosemirror-nodepos',
        );

        if (getVariableCustomChipData === VariableCustomChipData.customChip) {
          return true;
        }

        const [file] = event.dataTransfer?.files || [];
        if (!moved && file) {
          if (Object.keys(acceptedAttachmentType).includes(file.type)) {
            if (file.type.startsWith('image/')) {
              handleDropImageEditor(view, event, file);
              return true;
            }

            const fileUrl = URL.createObjectURL(file);
            setAttachFiles((currentFiles) => {
              currentFiles.set(fileUrl, Object.assign(file, { fileUrl }));
              return new Map(currentFiles);
            });

            return true;
          }
        }

        showErrorMessage(FileRejectedCode.FILE_INVALID_TYPE);
        return false;
      },
    },
  });
}
