import {
  ActionMenu,
  BehaviourLabelChip,
  type GridOptions,
  type ICellRendererParams,
  type ReturnTypeDisplayName,
  Table,
  commonActionMenuProps,
  usePreferredNameLayout,
} from '@tyro/core';
import { type TFunction, useTranslation } from '@tyro/i18n';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';

import { EditIcon, TrashIcon, VerticalDotsIcon } from '@tyro/icons';

import { Chip, Stack } from '@mui/material';
import {
  ConfirmDeleteBehaviour,
  type ReturnTypeFromUseStudentBehaviour,
  type StudentActionsContextValue,
  useStudentActions,
  useStudentBehaviour,
} from '@tyro/people';
import { behaviourColorByType } from '@tyro/settings';

dayjs.extend(LocalizedFormat);

type BehaviourNoteRow = NonNullable<
  ReturnTypeFromUseStudentBehaviour['behaviours']
>[number];

export interface GroupBehaviourTableProps {
  groupId: number;
}

const getColumns = (
  t: TFunction<('common' | 'people')[], undefined, ('common' | 'people')[]>,
  displayName: ReturnTypeDisplayName,
  getEditBehaviourButtonProps: StudentActionsContextValue['getEditBehaviourButtonProps'],
  onDelete: Dispatch<SetStateAction<number | null>>,
): GridOptions<BehaviourNoteRow>['columnDefs'] => [
  {
    field: 'incidentDate',
    headerName: t('common:date'),
    valueFormatter: ({ data }) => dayjs(data?.incidentDate).format('lll'),
    sort: 'desc',
    comparator: (dateA: string, dateB: string) =>
      dayjs(dateA).unix() - dayjs(dateB).unix(),
  },
  {
    colId: 'type',
    headerName: t('common:type'),
    valueGetter: ({ data }) => {
      const behaviourType = data?.tags?.[0]?.behaviourType;
      return behaviourType ? t(`people:behaviourTypes.${behaviourType}`) : '-';
    },
    cellRenderer: ({ data }: ICellRendererParams<BehaviourNoteRow>) => {
      const behaviourType = data?.tags?.[0]?.behaviourType;

      return behaviourType ? (
        <BehaviourLabelChip behaviourType={behaviourType} />
      ) : null;
    },
    filter: true,
  },
  {
    field: 'category',
    headerName: t('people:category'),
    filter: true,
    valueGetter: ({ data }) => data?.category || '-',
  },
  {
    field: 'tags',
    headerName: t('people:tags'),
    autoHeight: true,
    wrapText: true,
    width: 250,
    filter: true,
    valueGetter: ({ data }) => data?.tags?.map((tag) => tag?.name) ?? '-',
    cellRenderer: ({ data }: ICellRendererParams<BehaviourNoteRow>) => {
      if (!data?.tags || data?.tags.length === 0) return '-';

      return (
        <Stack gap={1} my={1} direction="row" flexWrap="wrap">
          {data?.tags?.map((tag) => (
            <Chip
              size="small"
              key={tag?.id}
              label={tag?.name}
              variant="soft"
              color={behaviourColorByType.get(tag?.behaviourType!) ?? 'slate'}
            />
          ))}
        </Stack>
      );
    },
  },
  {
    field: 'details',
    headerName: t('common:details'),
    autoHeight: true,
    wrapText: true,
    width: 250,
    cellStyle: {
      lineHeight: 2,
      paddingTop: 12,
      paddingBottom: 12,
      wordBreak: 'break-word',
    },
  },
  {
    field: 'referencedParties',
    headerName: t('common:students'),
    autoHeight: true,
    wrapText: true,
    width: 300,
    cellStyle: {
      lineHeight: 2,
      paddingTop: 12,
      paddingBottom: 12,
      wordBreak: 'break-word',
    },
    valueGetter: ({ data }) => {
      const students = data?.referencedParties?.map((person) =>
        displayName(person),
      );

      const LIMIT_TAGS = 3;

      if (students && students.length > LIMIT_TAGS) {
        return `${students.slice(0, LIMIT_TAGS).join(', ')}, +${
          students.length - LIMIT_TAGS
        }`;
      }

      return students && students.length > 0 ? students.join(', ') : '-';
    },
  },
  {
    field: 'takenBy',
    suppressSizeToFit: true,
    headerName: t('common:createdBy'),
    valueGetter: ({ data }) => displayName(data?.takenBy) || '-',
  },
  {
    ...commonActionMenuProps,
    cellRenderer: ({ data }: ICellRendererParams<BehaviourNoteRow>) =>
      data && (
        <ActionMenu
          iconOnly
          buttonIcon={<VerticalDotsIcon />}
          menuItems={[
            {
              label: t('common:actions.edit'),
              icon: <EditIcon />,
              ...getEditBehaviourButtonProps(data.noteId),
            },
            {
              label: t('common:actions.delete'),
              icon: <TrashIcon />,
              isDelete: true,
              onClick: () => onDelete(data?.noteId),
            },
          ]}
        />
      ),
  },
];

export function GroupBehaviourTable({ groupId }: GroupBehaviourTableProps) {
  const { t } = useTranslation(['common', 'groups', 'people', 'mail']);
  const { getEditBehaviourButtonProps } = useStudentActions();

  const [behaviourIdToDelete, setBehaviourIdToDelete] = useState<number | null>(
    null,
  );

  const { displayName } = usePreferredNameLayout();

  const { data: studentBehaviorData, isLoading: isBehaviorsLoading } =
    useStudentBehaviour({
      associatedPartyIds: [groupId ?? 0],
    });

  const subjectGroupBehaviourColumns = useMemo(
    () =>
      getColumns(
        t,
        displayName,
        getEditBehaviourButtonProps,
        setBehaviourIdToDelete,
      ),
    [t, displayName, getEditBehaviourButtonProps, setBehaviourIdToDelete],
  );

  return (
    <>
      <Table
        isLoading={isBehaviorsLoading}
        rowData={studentBehaviorData?.behaviours ?? []}
        columnDefs={subjectGroupBehaviourColumns}
        getRowId={({ data }) => String(data?.noteId)}
      />

      <ConfirmDeleteBehaviour
        idToDelete={behaviourIdToDelete}
        onClose={() => setBehaviourIdToDelete(null)}
      />
    </>
  );
}
