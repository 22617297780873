import dayjs from 'dayjs';

const format = 'YYYY-MM-DDTHH:mm';
const formatWithSeconds = 'YYYY-MM-DDTHH:mm:ss';

export const getValidEventStartTime = (
  eventStartTime: string | undefined | null,
) => {
  if (!eventStartTime) return undefined;
  const isValid = dayjs(eventStartTime, format).isValid();
  const startTime = isValid ? eventStartTime : dayjs();

  return dayjs(startTime).format(formatWithSeconds);
};
