import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from '@tyro/i18n';
import { StarIcon } from '@tyro/icons';

export function SearchFavoriteIcon({
  isFavorite,
  onClick,
}: {
  isFavorite: boolean;
  onClick: () => void;
}) {
  const { t } = useTranslation(['common']);
  return (
    <Tooltip
      title={isFavorite ? t('common:removeBookmark') : t('common:bookmark')}
      enterDelay={500}
    >
      <IconButton
        color="primary"
        sx={
          isFavorite
            ? {
                '& svg path': {
                  color: 'amber.500',
                  fill: 'currentcolor',
                },
              }
            : undefined
        }
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onClick();
        }}
        aria-label={
          isFavorite ? t('common:removeBookmark') : t('common:bookmark')
        }
      >
        <StarIcon />
      </IconButton>
    </Tooltip>
  );
}
