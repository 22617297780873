import {
  Calender_RootVisibilityType,
  getPermissionUtils,
  getUser,
} from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  lazyWithRetry,
} from '@tyro/core';
import { Calendar31Icon } from '@tyro/icons';
import dayjs from 'dayjs';
import { redirect } from 'react-router-dom';
import { getCalendarEvents } from './api/events';
import { DEFAULT_EVENT_TYPES } from './components/common/calendar/calendar';

const CalendarPage = lazyWithRetry(() => import('./pages/calendar'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'calendar',
        icon: <Calendar31Icon />,
        title: t('navigation:general.calendar.title'),
        hasAccess: (permissions) =>
          permissions.hasPermission('ps:1:calendar:view_calendar'),
        children: [
          {
            type: NavObjectType.NonMenuLink,
            index: true,
            loader: async () => {
              const { hasPermission } = await getPermissionUtils();

              if (hasPermission('ps:1:calendar:view_own_calendar')) {
                return redirect('personal');
              }

              return redirect('school');
            },
          },
          {
            type: NavObjectType.MenuLink,
            path: 'personal',
            title: t('navigation:general.calendar.personal'),
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:calendar:view_own_calendar'),
            loader: async ({ request }) => {
              const { activeProfile } = await getUser();

              const url = new URL(request.url);
              const defaultDateString = url.searchParams.get('date');
              const date = defaultDateString
                ? dayjs(defaultDateString).toDate()
                : dayjs().startOf('week').toDate();
              const visibleEventTypes = DEFAULT_EVENT_TYPES;

              return getCalendarEvents({
                date,
                resources: {
                  partyIds: [activeProfile?.partyId ?? 0],
                  roomIds: [],
                },
                eventTypeFilter: visibleEventTypes,
                period: 'week',
              });
            },
            element: <CalendarPage />,
          },
          {
            type: NavObjectType.MenuLink,
            path: 'school',
            title: t('navigation:general.calendar.school'),
            loader: async ({ request }) => {
              const { isContact, isStaffUser, isStudent } =
                await getPermissionUtils();
              const url = new URL(request.url);
              const defaultDateString = url.searchParams.get('date');
              const date = defaultDateString
                ? dayjs(defaultDateString).toDate()
                : dayjs().startOf('week').toDate();
              const visibleEventTypes = DEFAULT_EVENT_TYPES;

              return getCalendarEvents({
                date,
                resources: {
                  partyIds: [],
                  roomIds: [],
                },
                eventTypeFilter: visibleEventTypes,
                period: 'week',
                visibility: {
                  publicFilter: {
                    visibleToContacts: isContact,
                    visibleToStaff: isStaffUser,
                    visibleToStudents: isStudent,
                  },
                  types: [Calender_RootVisibilityType.Public],
                },
              });
            },
            element: <CalendarPage />,
          },
        ],
      },
    ],
  },
];
