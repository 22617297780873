import type { Virtualizer } from '@tanstack/react-virtual';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { TimelineItem, type TimelineItemProps } from './item';
import { TimelineSectionHeader } from './section-header';
import { TimelineContainer } from './timeline-container';

dayjs.extend(LocalizedFormat);

export function getEventDateTimes(event: TimelineItemProps['event']) {
  const eventStart = dayjs(event.startDateTime ?? event.date);
  let weekTimestamp = eventStart.format('ddd');
  let monthTimestamp = eventStart.format('L');

  if (event.startDateTime) {
    weekTimestamp += ` ${eventStart.format('LT')}`;
    monthTimestamp += ` ${eventStart.format('LT')}`;
  }

  if (event.endDateTime) {
    weekTimestamp += ` - ${dayjs(event.endDateTime).format('LT')}`;
    monthTimestamp += ` - ${dayjs(event.endDateTime).format('LT')}`;
  }

  return { weekTimestamp, monthTimestamp };
}

export function getTimelineForPrinting(
  rows: (TimelineItemProps['event'] | string)[],
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>,
  groupBy: 'week' | 'month',
) {
  return (
    <TimelineContainer
      sx={{
        contain: 'none',
        backgroundColor: 'transparent',
        p: 0,
        pt: 0,

        '& ul > div': {
          mb: 2,
        },
      }}
    >
      <ul
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          padding: '16px 0',
          marginTop: 0,
        }}
      >
        {rows.map((row, index) => {
          if (typeof row === 'string') {
            return (
              <TimelineSectionHeader
                key={row}
                ref={rowVirtualizer.measureElement}
                groupBy={groupBy}
                headerValue={row}
                style={{
                  position: 'relative',
                }}
              />
            );
          }

          const itemHeight = rowVirtualizer.measurementsCache[index].size;
          const itemMarginBottom = 16;
          const halfDotHeight = 6;

          return (
            <TimelineItem
              data-index={index}
              key={JSON.stringify(row.id)}
              id={`timeline-item-${JSON.stringify(row.id)}`}
              event={row}
              groupBy={groupBy}
              style={{
                position: 'relative',
              }}
              role="row"
              itemHeight={itemHeight}
              additionalDotStyles={{
                transform: 'translateY(0px)',
                position: 'relative',
                margin: 0,
                marginTop: -12,
                top: -(itemHeight / 2 + itemMarginBottom - halfDotHeight),
              }}
            />
          );
        })}
        <div className="timeline" role="presentation">
          <div className="inner-timeline" />
        </div>
      </ul>
    </TimelineContainer>
  );
}
