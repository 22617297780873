import { useQuery } from '@tanstack/react-query';
import {
  type Notesv2_NoteFilter,
  type QueryAdditionalOptions,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { peopleKeys } from '../keys';

const notesV2 = graphql(/* GraphQL */ `
  query notesv2_notes($filter: Notesv2_NoteFilter!) {
    notesv2_notes(filter: $filter) {
      notes {
        id
        note
        fromDate
        toDate
        createdOn
        updatedOn
        tags {
          id
          name
          colour
        }
        createdBy {
          person {
            partyId
            title {
              id
              name
              nameTextId
            }
            firstName
            lastName
            avatarUrl
            type
          }
        }
      }
    }
  }
`);

const notesQueryV2 = (filter: Notesv2_NoteFilter) => {
  return {
    queryKey: peopleKeys.notes.notesV2(filter),
    queryFn: async () =>
      gqlClient.request(notesV2, {
        filter,
      }),
  };
};

export function getNotesV2(filter: Notesv2_NoteFilter) {
  return queryClient.fetchQuery(notesQueryV2(filter));
}

export function useNotesV2(
  filter: Notesv2_NoteFilter,
  additionalOptions?: Omit<QueryAdditionalOptions, 'academicNamespaceId'>,
) {
  return useQuery({
    ...notesQueryV2(filter),
    ...additionalOptions,
    select: ({ notesv2_notes }) => notesv2_notes,
  });
}

export type ReturnTypeFromUseNotesV2 = UseQueryReturnType<typeof useNotesV2>;
