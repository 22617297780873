import {
  Box,
  Stack,
  type StackProps,
  Tooltip,
  Typography,
} from '@mui/material';
import type { Editor } from '@tiptap/react';
import { useFormatNumber, useTranslation } from '@tyro/i18n';
import { InfoCircleIcon } from '@tyro/icons';
import { useMemo } from 'react';
import { analyzeSmsTextString } from '../../utils/analyze-sms-text-string';
import { replaceVariablesWithPlaceholder } from '../../utils/remove-variable-html';

interface SmsSummaryProps extends StackProps<'dl'> {
  message: string;
  costPerSms?: number;
  totalCost: number;
  smsEditorConfig?: {
    hasSmsMessageVariables?: boolean;
    templateSelected?: boolean;
    editor?: Editor | null;
  };
}

export function SmsSummary({
  message,
  costPerSms,
  totalCost,
  smsEditorConfig,
  ...props
}: SmsSummaryProps) {
  const { t } = useTranslation(['sms']);
  const { formatCurrency } = useFormatNumber();

  const { hasSmsMessageVariables } = smsEditorConfig ?? {};
  const updatedMessageFormat = replaceVariablesWithPlaceholder(message);

  const summaryLines = useMemo(() => {
    const { numberOfMessages, characterCount } =
      analyzeSmsTextString(updatedMessageFormat);

    return {
      [t('sms:numberOfSms', { count: numberOfMessages })]:
        hasSmsMessageVariables
          ? t('sms:approxNumberOfCharacters', { count: characterCount })
          : t('sms:numberOfCharacters', { count: characterCount }),
      ...(costPerSms
        ? { [t('sms:costPerSms')]: formatCurrency(costPerSms) }
        : {}),
      [t('sms:totalCost')]: formatCurrency(totalCost),
    };
  }, [
    updatedMessageFormat,
    t,
    hasSmsMessageVariables,
    costPerSms,
    formatCurrency,
    totalCost,
  ]);

  const entries = Object.entries(summaryLines);
  const [firstEntry, ...restEntries] = entries;

  return (
    <Stack component="dl" {...props} sx={{ m: 0, ...props.sx }}>
      {firstEntry && (
        <Stack
          direction="row"
          justifyContent="space-between"
          key={firstEntry[0]}
        >
          <Typography
            component="dt"
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >
            {firstEntry[0]}
          </Typography>
          {hasSmsMessageVariables ? (
            <Box display="flex">
              <Typography component="dd" variant="body2">
                {firstEntry[1]}
              </Typography>
              <Tooltip title={t('sms:variableCharacterCountTooltip')} arrow>
                <span>
                  <InfoCircleIcon sx={{ ml: 1 }} />
                </span>
              </Tooltip>
            </Box>
          ) : (
            <Typography component="dd" variant="body2">
              {firstEntry[1]}
            </Typography>
          )}
        </Stack>
      )}

      {restEntries.map(([title, value]) => (
        <Stack direction="row" justifyContent="space-between" key={title}>
          <Typography
            component="dt"
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >
            {title}
          </Typography>
          <Typography component="dd" variant="body2">
            {value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
