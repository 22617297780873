import { Chip } from '@mui/material';
import type { SubjectFilter } from '@tyro/api';
import {
  Autocomplete,
  type AutocompleteProps,
  type CustomCellEditorProps,
  RHFAutocomplete,
  type RHFAutocompleteProps,
  TableAutocomplete,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { FieldValues } from 'react-hook-form';
import {
  type CatalogueSubjectOption,
  useCatalogueSubjects,
} from '../../api/subjects';

type RHFSubjectAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, CatalogueSubjectOption>,
  'options'
> & {
  filter?: SubjectFilter;
};

type SubjectAutocompleteProps = Omit<
  AutocompleteProps<
    Pick<CatalogueSubjectOption, 'id' | 'name' | 'nationalCode' | 'colour'>
  >,
  | 'optionIdKey'
  | 'optionTextKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
  | 'options'
> & {
  filter?: SubjectFilter;
};

export const RHFSubjectAutocomplete = <TField extends FieldValues>({
  filter,
  ...props
}: RHFSubjectAutocompleteProps<TField>) => {
  const { t } = useTranslation(['common']);
  const { data: subjectsData, isLoading } = useCatalogueSubjects(filter);

  return (
    <RHFAutocomplete<TField, CatalogueSubjectOption>
      label={t('common:subject')}
      {...props}
      fullWidth
      optionIdKey="id"
      optionTextKey="name"
      getOptionLabel={(option) =>
        `${option.name} (${option?.nationalCode ?? '-'})`
      }
      renderTags={(tags, getTagProps) =>
        tags.map((tag, index) => {
          const { key, ...tagProps } = getTagProps({ index });

          return (
            <Chip
              key={key}
              size="small"
              variant="soft"
              color={tag?.colour || 'primary'}
              label={tag.name}
              {...tagProps}
            />
          );
        })
      }
      loading={isLoading}
      options={subjectsData ?? []}
    />
  );
};

export const SubjectAutocomplete = ({
  filter,
  ...props
}: SubjectAutocompleteProps) => {
  const { t } = useTranslation(['common']);
  const { data: subjectsData, isLoading } = useCatalogueSubjects(filter);

  return (
    <Autocomplete
      label={t('common:subject')}
      fullWidth
      optionIdKey="id"
      getOptionLabel={(option) =>
        `${option.name} (${option?.nationalCode ?? '-'})`
      }
      renderTags={(tags, getTagProps) =>
        tags.map((tag, index) => {
          const { key, ...tagProps } = getTagProps({ index });

          return (
            <Chip
              key={key}
              size="small"
              variant="soft"
              color={tag?.colour || 'primary'}
              label={tag.name}
              {...tagProps}
            />
          );
        })
      }
      loading={isLoading}
      options={subjectsData ?? []}
      {...props}
    />
  );
};

type TableSubjectAutocompleteValue = Pick<
  CatalogueSubjectOption,
  'id' | 'name' | 'nationalCode'
>;

export function TableSubjectAutocomplete({
  filter,
  ...props
}: CustomCellEditorProps<unknown, TableSubjectAutocompleteValue> & {
  filter?: SubjectFilter;
}) {
  const { t } = useTranslation(['common']);
  const { data: subjectsData, isLoading } = useCatalogueSubjects(filter);

  return (
    // @ts-expect-error
    <TableAutocomplete<TableSubjectAutocompleteValue | null>
      getOptionLabel={(option) =>
        option?.name ?? option?.nationalCode
          ? `${option.name} (${option.nationalCode})`
          : ''
      }
      {...props}
      options={subjectsData ?? []}
      value={props.value}
      optionIdKey="id"
      AutocompleteProps={{
        autoHighlight: true,
        loading: isLoading,
        loadingText: t('common:loading'),
      }}
    />
  );
}

if (process.env.NODE_ENV !== 'production') {
  TableSubjectAutocomplete.displayName = 'TableSubjectAutocomplete';
}
