import { useQuery } from '@tanstack/react-query';
import type { UseQueryReturnType } from '../@types';
import { gqlClient } from '../clients';
import { graphql } from '../gql';
import type { Catalogue_SystemSettingsFilter } from '../gql/graphql';
import { queryClient } from '../query-client';
import { coreApiKeys } from './keys';

const settingsValues = graphql(/* GraphQL */ `
 query catalogue_systemSettingsValues($filter: Catalogue_SystemSettingsFilter!) {
    catalogue_systemSettingsValues(filter: $filter) {
      security_logout_inactiveTimeUntilLogoutMinutes
      substitution_display_showAbsenceReason
      substitution_display_teacherDisplayNameType
      people_display_teacherDisplayNameType
      noticeboard_display_alwaysShowPinned
      schoolActivity_attendance_defaultAttendanceCode
      timetable_twoWeekTimetable_enabled
      language_translation_enableSwitching
    }
  }
`);

const settingsValuesQuery = (filter: Catalogue_SystemSettingsFilter) => ({
  queryKey: coreApiKeys.settingsValues(filter),
  queryFn: () => gqlClient.request(settingsValues, { filter }),
});

export function getSettingsValues(filter: Catalogue_SystemSettingsFilter) {
  return queryClient.fetchQuery(settingsValuesQuery(filter));
}

export function useSettingsValues(filter: Catalogue_SystemSettingsFilter) {
  return useQuery({
    ...settingsValuesQuery(filter),
    select: ({ catalogue_systemSettingsValues }) =>
      catalogue_systemSettingsValues,
  });
}

export type ReturnTypeFromUseSettingsValues = UseQueryReturnType<
  typeof useSettingsValues
>;
