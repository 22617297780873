import { useMutation } from '@tanstack/react-query';

import {
  type Calendar_UpsertCustomTag,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { calendarKeys } from '../keys';

const upsertCalendarCustomTag = graphql(/* GraphQL */ `
  mutation calendar_upsertCustomTag($input: Calendar_UpsertCustomTag!) {
    calendar_upsertCustomTag(input: $input) {
      success
    }
  }
`);

export function useUpsertCalendarCustomTag() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: Calendar_UpsertCustomTag) =>
      gqlClient.request(upsertCalendarCustomTag, { input }),
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({ queryKey: calendarKeys.all });
      toast(
        id
          ? t('common:snackbarMessages.updateSuccess')
          : t('common:snackbarMessages.createSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}
