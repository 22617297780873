import type { BaseMenuTools } from '@tyro/core';
import type { TemplateFormState } from '../components/editor/types';

const emailTemplateOmittedTools = [
  'tables',
  'strikethrough',
  'columns',
  'headings',
  'alignment',
  'fontPicker',
] satisfies BaseMenuTools[];

const smsTemplateOmittedTools: BaseMenuTools[] = [
  'fontStyling',
  'fontPicker',
  'headings',
  'alignment',
  'listButtons',
  'tables',
  'columns',
  'strikethrough',
  'insertImage',
];

export function getOmittedTools(
  templateType: TemplateFormState['templateType'],
): BaseMenuTools[] | undefined {
  if (templateType === 'email') {
    return emailTemplateOmittedTools;
  }
  if (templateType === 'sms') {
    return smsTemplateOmittedTools;
  }
  return undefined;
}
