import { type YearGroupFilter, useYearGroups } from '@tyro/api';
import {
  Autocomplete,
  type AutocompleteProps,
  RHFAutocomplete,
  type RHFAutocompleteProps,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';

export interface YearGroupSelect {
  name: string;
  yearGroupId: number;
}

type RHFYearGroupAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, YearGroupSelect>,
  'options'
> & {
  filter?: YearGroupFilter;
  academicNamespaceId?: number;
};

type YearGroupAutocompleteProps = Omit<
  AutocompleteProps<YearGroupSelect>,
  | 'options'
  | 'optionIdKey'
  | 'optionTextKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
>;

export const RHFYearGroupAutocomplete = <TField extends FieldValues>({
  filter,
  academicNamespaceId,
  ...props
}: RHFYearGroupAutocompleteProps<TField>) => {
  const { t } = useTranslation(['common']);
  const { data: yearGroupData, isLoading } = useYearGroups(filter ?? {}, {
    academicNamespaceId,
  });

  const yearGroupOptions = useMemo(
    () =>
      yearGroupData?.map(({ name, yearGroupId }) => ({
        yearGroupId,
        name,
      })),
    [yearGroupData],
  );

  return (
    <RHFAutocomplete<TField, YearGroupSelect>
      label={t('common:year')}
      {...props}
      fullWidth
      optionIdKey="yearGroupId"
      optionTextKey="name"
      loading={isLoading}
      options={yearGroupOptions ?? []}
    />
  );
};

export const YearGroupAutocomplete = (props: YearGroupAutocompleteProps) => {
  const { t } = useTranslation(['common']);
  const { data: yearGroupData, isLoading } = useYearGroups({});

  const yearGroupOptions = useMemo(
    () =>
      yearGroupData?.map(({ name, yearGroupId }) => ({
        yearGroupId,
        name,
      })),
    [yearGroupData],
  );

  return (
    <Autocomplete
      label={t('common:year')}
      fullWidth
      optionIdKey="yearGroupId"
      optionTextKey="name"
      loading={isLoading}
      options={yearGroupOptions ?? []}
      ChipProps={{ size: 'small' }}
      {...props}
    />
  );
};
