import { Card, Stack, useMediaQuery } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { useAppShellConfig } from '@tyro/app-shell';
import { TextEditor } from '@tyro/core';
import { useState } from 'react';
import { type Control, useWatch } from 'react-hook-form';
import { SelectRecipientsModal } from '../modals/select-recipients-modal';
import { TemplateEditorActionButtons } from './template-editor-actions-buttons';
import { TemplateEditorFormFields } from './template-editor-form-fields';
import type { TemplateFormState } from './types';

type EditorInputFieldProps = {
  editor: Editor | null;
  control: Control<TemplateFormState>;
  isSavingTemplate: boolean;
  templateType?: string | null;
};

export default function TemplateEditorInputField({
  editor,
  control,
  isSavingTemplate,
  templateType,
}: EditorInputFieldProps) {
  const [isRecipientsModalOpen, setIsRecipientsModalOpen] = useState(false);

  const { isNavExpanded } = useAppShellConfig();
  const maxCardWidth = useMediaQuery('(max-width: 1200px)');

  const { context, createdOn } = useWatch({ control });

  const htmlContent = `<div class="template-wrapper">${editor?.getHTML()}</div>`;
  const smsTemplateTypeHeight = templateType === 'sms' ? '200px' : undefined;

  return (
    <>
      <Stack
        display="flex"
        flexDirection={
          maxCardWidth || (!isNavExpanded && maxCardWidth) ? 'column' : 'row'
        }
        sx={{
          width:
            maxCardWidth || (!isNavExpanded && maxCardWidth)
              ? 'max-content'
              : '100%',
          justifyContent: maxCardWidth ? undefined : 'space-between',
        }}
        mt={2}
      >
        <Card
          variant="soft"
          sx={{
            borderRadius: '26px',
            minWidth: '826px',
            maxWidth: '826px',
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',
            gap: 2,
          }}
        >
          <TemplateEditorFormFields control={control} />

          <TextEditor editor={editor} minHeight={smsTemplateTypeHeight} />
        </Card>

        <TemplateEditorActionButtons
          maxCardWidth={maxCardWidth}
          createdOn={createdOn}
          onPreviewClick={() => setIsRecipientsModalOpen(true)}
          isSavingTemplate={isSavingTemplate}
        />
      </Stack>
      <SelectRecipientsModal
        isOpen={isRecipientsModalOpen}
        context={context}
        htmlContent={htmlContent}
        onClose={() => setIsRecipientsModalOpen(false)}
      />
    </>
  );
}
