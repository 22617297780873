import { useMutation } from '@tanstack/react-query';
import {
  type FeeMigrationInput,
  gqlClient,
  graphql,
  queryClient,
  resolveBackendError,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { feeKeys } from './keys';

const migrateFee = graphql(/* GraphQL */ `
  mutation fees_migrateFee($input: FeeMigrationInput!) {
    fees_migrateFee(input: $input) {
      success
    }
  }
`);

export function useMigrateFee() {
  const { t } = useTranslation(['common', 'fees']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: (input: FeeMigrationInput) =>
      gqlClient.request(migrateFee, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: feeKeys.all });
      toast(t('fees:feeMigratedSuccessfully'));
    },
    onError: (error: unknown) => {
      const errorMessage = resolveBackendError(
        error,
        t('common:snackbarMessages.errorFailed'),
      );
      toast(errorMessage, { variant: 'error' });
    },
  });
}
