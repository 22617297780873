import { InputAdornment } from '@mui/material';
import type { Content, Editor } from '@tiptap/react';
import { Autocomplete } from '@tyro/core';
import { SearchIcon } from '@tyro/icons';
import type { ReturnTypeFromUseTemplates } from '@tyro/templates';

type TemplatesAutocompleteProps = {
  setIsTemplateSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editor: Editor | null;
  templatesData: TemplatesData;
};

export type TemplatesData = {
  templates?: ReturnTypeFromUseTemplates[];
  isLoading: boolean;
};

export function TemplatesAutocomplete({
  setIsTemplateSearchOpen,
  editor,
  templatesData: { templates, isLoading },
}: TemplatesAutocompleteProps) {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleTemplateSelect = (_event: any, selectedTemplate: any) => {
    if (selectedTemplate?.template && editor) {
      editor.commands.setContent(selectedTemplate.template as Content);
      setIsTemplateSearchOpen(false);
    }
  };

  return (
    <Autocomplete
      fullWidth
      optionIdKey="id"
      optionTextKey={'name'}
      loading={isLoading}
      options={templates ?? []}
      onChange={handleTemplateSelect}
      ChipProps={{ size: 'small' }}
      inputProps={{
        sx: {
          '& .MuiInputBase-root': {
            ml: 1,
            backgroundColor: 'background.paper',
            border: 'none',
            height: '40px',
            display: '-webkit-inline-box',
            pl: 0,
          },
          '& fieldset': {
            border: 'none',
          },
          '& .MuiInputAdornment-root': {
            marginRight: 0,
            marginLeft: 1,
          },
        },
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
