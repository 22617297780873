import { EmulateHeaders } from './emulate';

export function resolveAcademicHeader(
  academicNamespaceId?: number,
  additionalOptions?: Record<string, string>,
): Record<string, string> {
  if (academicNamespaceId) {
    return {
      [EmulateHeaders.ACADEMIC_NAMESPACE_ID]: academicNamespaceId.toString(),
      ...additionalOptions,
    };
  }
  return additionalOptions ?? {};
}
