import {
  type ButtonProps,
  IconButton,
  Stack,
  alpha,
  useTheme,
} from '@mui/material';
import { usePermissions } from '@tyro/api';
import { Avatar, usePreferredNameLayout } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { AddNoteIcon, EditIcon } from '@tyro/icons';
import { type ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStudentDetailsForNTK } from '../../../api/student/students';
import {
  type NeedToKnowGroup,
  useStudentActions,
} from '../../../store/student-actions';
import { getPrimaryContact } from '../../../utils/get-primary-contact';

type Action = {
  label: string;
  hasAccess?: boolean;
} & (
  | { icon: ReactNode; ButtonProps: () => ButtonProps }
  | { component: ReactNode }
);

interface NeedToKnowActionsProps {
  studentPartySettings:
    | { partyId: number; group?: NeedToKnowGroup }
    | undefined;
  open: boolean;
  onActionClick?: () => void;
  onActionClose?: () => void;
}

export function NeedToKnowActions({
  studentPartySettings,
  open,
  onActionClick,
  onActionClose,
}: NeedToKnowActionsProps) {
  const { partyId: studentPartyId = 0, group } = studentPartySettings ?? {};
  const { t } = useTranslation(['common', 'people']);
  const { palette } = useTheme();
  const { displayName } = usePreferredNameLayout();
  const { hasPermission } = usePermissions();
  const { getCreateNoteButtonProps, getCreateBehaviourButtonProps } =
    useStudentActions();

  const { data: studentData } = useStudentDetailsForNTK(studentPartyId, open);

  const primaryContact = useMemo(
    () => getPrimaryContact(studentPartyId, studentData?.contacts),
    [studentPartyId, studentData?.contacts],
  );

  const actions = useMemo<Action[]>(() => {
    return [
      {
        label: t('people:addNote'),
        ButtonProps: () =>
          getCreateNoteButtonProps(studentPartyId, {
            onClick: onActionClick,
            onClose: onActionClose,
          }),
        hasAccess: hasPermission('ps:1:notes:write_notes'),
        icon: <EditIcon />,
      },
      {
        label: t('people:addBehaviour'),
        ButtonProps: () =>
          getCreateBehaviourButtonProps(
            {
              defaultStudentPartyIds: [studentPartyId],
              associatedGroups: group ? [group] : [],
            },
            { onClose: onActionClose, onClick: onActionClick },
          ),
        hasAccess: hasPermission('ps:1:notes:write_behaviour'),
        icon: <AddNoteIcon />,
      },
      {
        label: t('common:contact'),
        hasAccess: !!primaryContact,
        component: (
          <Link
            to={`/people/contacts/${primaryContact?.partyId}`}
            onClick={onActionClick}
          >
            <Avatar
              src={primaryContact?.person?.avatarUrl}
              person={primaryContact?.person}
              name={displayName(primaryContact?.person)}
              size={52}
            />
          </Link>
        ),
      },
    ].filter((action) => action.hasAccess || action.hasAccess === undefined);
  }, [
    t,
    hasPermission,
    displayName,
    studentPartyId,
    getCreateNoteButtonProps,
    getCreateBehaviourButtonProps,
    primaryContact,
  ]);

  return (
    <Stack
      sx={{
        position: 'fixed',
        transform:
          'translate(min(444px, 100vw), calc(min(460px, 50vh) - 50% - 8px))',
        mx: 3,

        '& > div > span': {
          fontWeight: 'medium',
          fontSize: '0.75rem',
        },

        '& .MuiIconButton-root': {
          backgroundColor: alpha(palette.common.white, 0.4),
          backdropFilter: 'blur(16px)',
          transition: 'background-color 0.15s ease-in-out',

          '&:hover': {
            backgroundColor: alpha(palette.common.white, 1),
          },

          '& .MuiSvgIcon-root': {
            color: 'slate.800',
            width: 28,
            height: 28,
          },
        },

        a: {
          textDecoration: 'none',
        },
      }}
      spacing={2}
    >
      {actions.map((action) => {
        const { label } = action;

        if ('component' in action) {
          return (
            <Stack key={label} alignItems="center" spacing={0.5}>
              {action.component}
              <span>{label}</span>
            </Stack>
          );
        }

        if ('icon' in action) {
          return (
            <Stack key={label} alignItems="center" spacing={0.5}>
              <IconButton
                {...action.ButtonProps()}
                size="large"
                aria-label={label}
              >
                {action.icon}
              </IconButton>
              <span>{label}</span>
            </Stack>
          );
        }
      })}
    </Stack>
  );
}
