import { useMutation } from '@tanstack/react-query';
import {
  type Attendance_MergeAttendanceCodeInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { attendanceKeys } from './keys';

const mergeAttendanceCodes = graphql(/* GraphQL */ `
  mutation attendance_mergeAttendanceCodes($input: Attendance_MergeAttendanceCodeInput!) {
    attendance_mergeAttendanceCodes(input: $input) {
      success
    }
  }
`);

export function useMergeAttendanceCodes() {
  const { t } = useTranslation(['common', 'attendance']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: (input: Attendance_MergeAttendanceCodeInput) =>
      gqlClient.request(mergeAttendanceCodes, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: attendanceKeys.all });
      toast(t('attendance:mergeSuccessful'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}
