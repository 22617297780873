import { useQuery } from '@tanstack/react-query';
import {
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { userAccessKeys } from '../keys';

const userThirdParties = graphql(/* GraphQL */ `
  query users_thirdParties {
    users_thirdParties {
      id
      type
      createdBy {
        partyId
        firstName
        lastName
        avatarUrl
        type
        title {
          id
          name
          nameTextId
        }
      }
    }
  }
`);

const userThirdPartiesQuery = () => ({
  queryKey: userAccessKeys.userThirdParties(),
  queryFn: async () => gqlClient.request(userThirdParties),
});

export function getUserThirdParties() {
  return queryClient.fetchQuery(userThirdPartiesQuery());
}

export function useUserThirdParties() {
  return useQuery({
    ...userThirdPartiesQuery(),
    select: ({ users_thirdParties }) => users_thirdParties ?? [],
  });
}

export type ReturnTypeFromUseUserThirdParties = UseQueryReturnType<
  typeof useUserThirdParties
>[number];
