import type {
  BlockFilter,
  CalendarEventIteratorFilter,
  Catalogue_GroupTagFilter,
  Core_CustomGroupDefinitionFilter,
  Core_PartySource,
  Print_GroupMembers,
  SearchFilter,
  SubjectGroupFilter,
  YearGroupEnrollmentFilter,
} from '@tyro/api';
import { sortObjectEntries } from '@tyro/core';

export const groupsKeys = {
  all: ['groups'] as const,
  print: (filter: Print_GroupMembers) => [...groupsKeys.all, 'print', filter],
  tags: (filter: Catalogue_GroupTagFilter) =>
    [...groupsKeys.all, 'groupTags', filter] as const,
  search: (filter: SearchFilter) =>
    [...groupsKeys.all, 'search', filter] as const,
  class: {
    all: () => [...groupsKeys.all, 'class'] as const,
    groups: (academicNamespaceId?: number) =>
      [...groupsKeys.class.all(), 'list', academicNamespaceId] as const,
    details: (id: number | undefined) =>
      [...groupsKeys.class.all(), 'details', id] as const,
  },
  custom: {
    all: () => [...groupsKeys.all, 'custom'] as const,
    groups: (
      excludeGroup?: Core_PartySource.SchoolActivity,
      academicNamespaceId?: number | undefined,
    ) =>
      [
        ...groupsKeys.custom.all(),
        'list',
        excludeGroup,
        academicNamespaceId,
      ] as const,
    studentsSearch: (query: string) =>
      [...groupsKeys.custom.all(), 'search', query] as const,
    staffSearch: (query: string) =>
      [...groupsKeys.custom.all(), 'staffSearch', query] as const,
    details: (id: number | undefined) =>
      [...groupsKeys.custom.all(), 'details', id] as const,
    definition: (filter: Core_CustomGroupDefinitionFilter) =>
      [...groupsKeys.custom.all(), 'definition', filter] as const,
  },
  subject: {
    all: () => [...groupsKeys.all, 'subject'] as const,
    groups: (academicNamespaceId?: number) =>
      [...groupsKeys.subject.all(), 'list', academicNamespaceId] as const,
    details: (id: number | undefined) =>
      [...groupsKeys.subject.all(), 'details', id] as const,
    detailsByFilter: (filter: SubjectGroupFilter) =>
      [
        ...groupsKeys.subject.all(),
        'detailsByFilter',
        filter.partyIds,
      ] as const,
    staffMembers: (filter: SubjectGroupFilter) => [
      ...groupsKeys.subject.all(),
      'staffMembers',
      filter,
    ],
    allLessons: () => [...groupsKeys.subject.all(), 'lesson'],
    lesson: (filter: CalendarEventIteratorFilter) => [
      ...groupsKeys.subject.allLessons(),
      sortObjectEntries(filter),
    ],
  },
  support: {
    all: () => [...groupsKeys.all, 'support'] as const,
    groups: () => [...groupsKeys.support.all(), 'list'] as const,
    details: (id: number | undefined) =>
      [...groupsKeys.support.all(), 'details', id] as const,
    lesson: (filter: CalendarEventIteratorFilter) => [
      ...groupsKeys.support.all(),
      'lesson',
      sortObjectEntries(filter),
    ],
  },
  year: {
    all: () => [...groupsKeys.all, 'year'] as const,
    details: (filter: YearGroupEnrollmentFilter) =>
      [...groupsKeys.year.all(), 'details', filter] as const,
    groupEnrollments: (filter: YearGroupEnrollmentFilter) =>
      [...groupsKeys.all, 'listEnrollments', filter] as const,
  },
  blocks: {
    filter: (filter: BlockFilter) => [
      ...groupsKeys.support.all(),
      'blocks',
      filter,
    ],
  },
};
