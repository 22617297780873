import { Chip, Divider, Stack, Typography } from '@mui/material';
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { ReturnTypeFromUseNotesV2 } from '@tyro/people';
import dayjs from 'dayjs';
import { useMemo } from 'react';

interface AttendanceGroupNotesModalProps {
  isOpen: boolean;
  onClose: () => void;
  notes: ReturnTypeFromUseNotesV2['notes'];
}

export function AttendanceGroupNotesModal({
  isOpen,
  onClose,
  notes,
}: AttendanceGroupNotesModalProps) {
  const { t } = useTranslation(['common', 'groups']);
  const { displayName } = usePreferredNameLayout();

  const sortedNotes = useMemo(
    () =>
      notes.sort((a, b) => {
        const fromDateA = dayjs(a.fromDate);
        const fromDateB = dayjs(b.fromDate);
        const toDateA = dayjs(a.toDate);
        const toDateB = dayjs(b.toDate);

        if (fromDateA.isBefore(fromDateB)) return 1;
        if (fromDateA.isAfter(fromDateB)) return -1;

        if (toDateA.isBefore(toDateB)) return 1;
        if (toDateA.isAfter(toDateB)) return -1;

        return 0;
      }),
    [notes],
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>
        {t('groups:classNote', { count: notes.length })}
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <Stack gap={2}>
          {sortedNotes.map(
            ({ id, fromDate, toDate, note, tags, createdBy }, index) => {
              const createdName = displayName(createdBy?.person);

              return (
                <Stack gap={2} key={id}>
                  <Stack gap={2}>
                    <Stack
                      flexDirection="row"
                      gap={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        gap={2}
                        alignItems="center"
                        sx={({ palette }) => ({
                          backgroundColor: 'background.neutral',
                          p: 0.75,
                          px: 2,
                          borderRadius: 1,
                          border: `1px solid ${palette.divider}`,
                        })}
                      >
                        <Typography
                          variant="subtitle1"
                          component="h3"
                          color="text.secondary"
                        >
                          {t('common:date')}
                        </Typography>
                        <Stack
                          sx={({ palette }) => ({
                            p: 0.75,
                            px: 2,
                            backgroundColor: 'background.paper',
                            borderRadius: 1,
                            border: `1px solid ${palette.divider}`,
                          })}
                        >
                          {[fromDate, toDate]
                            .filter(Boolean)
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index,
                            )
                            .map((date) => dayjs(date).format('L'))
                            .join(' - ')}
                        </Stack>
                      </Stack>
                      <Stack flexDirection="row" gap={2} alignItems="center">
                        <Avatar
                          src={createdBy?.person?.avatarUrl}
                          name={createdName}
                        />
                        <Typography
                          variant="subtitle2"
                          component="span"
                          color="text.secondary"
                        >
                          {createdName}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      gap={2}
                      sx={({ palette }) => ({
                        backgroundColor: 'background.neutral',
                        p: 2,
                        borderRadius: 1,
                        border: `1px solid ${palette.divider}`,
                      })}
                    >
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        color="text.secondary"
                      >
                        {t('common:note')}
                      </Typography>
                      <Stack
                        sx={({ palette }) => ({
                          p: 2,
                          backgroundColor: 'background.paper',
                          borderRadius: 1,
                          border: `1px solid ${palette.divider}`,
                        })}
                      >
                        {note}
                      </Stack>
                    </Stack>
                    <Stack gap={1}>
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        color="text.secondary"
                      >
                        {t('common:tags')}
                      </Typography>
                      <Stack direction="row" gap={1} flexWrap="wrap">
                        {tags.length > 0 ? (
                          tags.map(({ id, name, colour }) => (
                            <Chip
                              key={id}
                              label={name}
                              variant="soft"
                              color={colour}
                            />
                          ))
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                  {sortedNotes.length === index + 1 ? null : (
                    <Divider
                      sx={{
                        borderColor: 'divider',
                      }}
                    />
                  )}
                </Stack>
              );
            },
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
