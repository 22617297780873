import { Box, type BoxProps, Stack, Typography } from '@mui/material';
import ApexChart from 'react-apexcharts';
import type { ChartProps } from './types';
import './styles.css';
import { useTranslation } from '@tyro/i18n';
import { LoadingPlaceholderContainer } from '../loading-placeholder';

export function Chart({
  sx,
  type,
  series,
  height,
  options,
  width = '100%',
  isLoading = false,
  ...other
}: BoxProps & ChartProps & { isLoading?: boolean }) {
  const { t } = useTranslation(['common']);
  const hasSeries = Array.isArray(series) && series.length > 0;
  return (
    <Box
      sx={{
        width,
        height,
        flexShrink: 0,
        borderRadius: 1.5,
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <LoadingPlaceholderContainer isLoading={isLoading}>
        {hasSeries ? (
          <ApexChart
            type={type}
            series={series}
            options={options}
            width="100%"
            height="100%"
          />
        ) : (
          <Stack
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            spacing={1}
          >
            <img
              src="/assets/emojis/bar_chart.png"
              role="presentation"
              alt={t('common:barChart')}
              width="48"
            />
            <Typography variant="body1" component="span" color="text.secondary">
              {t('common:noDataAvailable')}
            </Typography>
          </Stack>
        )}
      </LoadingPlaceholderContainer>
    </Box>
  );
}
