import {
  type AutocompleteValue,
  Chip,
  type ChipProps,
  InputAdornment,
  Autocomplete as MiAutocomplete,
  type AutocompleteProps as MiAutocompleteProps,
  Stack,
  TextField,
  type TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from '@tyro/i18n';
import type React from 'react';
import { type ForwardedRef, Fragment, useCallback, useMemo } from 'react';
import { usePreferredNameLayout } from '../../hooks';
import {
  getBaseColorBasedOnPerson,
  getBaseColorBasedOnString,
} from '../../utils';
import { Avatar, type AvatarProps } from '../avatar';

type TextfieldCustomProps = Omit<
  TextFieldProps,
  'label' | 'placeholder' | 'variant'
> & {
  variant?:
    | TextFieldProps['variant']
    | 'white-filled'
    | 'white-filled-outlined';
};

export type AutocompleteProps<
  T extends object | string,
  FreeSolo extends boolean | undefined = false,
> = Omit<MiAutocompleteProps<T, boolean, boolean, FreeSolo>, 'renderInput'> & {
  label?: TextFieldProps['label'];
  placeholder?: TextFieldProps['placeholder'];
  inputProps?: TextfieldCustomProps;
  optionIdKey?: keyof T;
  optionTextKey?: T extends object ? keyof T : never;
  customRef?: ForwardedRef<unknown>;
  unshiftMode?: boolean;
  renderTag?: (
    value: T,
    renderTag: (componentToRender: React.ReactNode) => React.ReactNode,
  ) => React.ReactNode;
  renderAvatarAdornment?: (
    value: T,
    renderTag: (avatarProps: AvatarProps) => React.ReactNode,
  ) => React.ReactNode;
  renderAvatarOption?: (
    option: T,
    renderOption: (
      avatarProps: AvatarProps & { caption?: string; hideAvatar?: boolean },
    ) => React.ReactNode,
  ) => React.ReactNode;
  renderAvatarTags?: (
    tag: T,
    renderTag: (
      avatarProps: AvatarProps & { hideAvatar?: boolean },
      chipProps?: ChipProps,
    ) => React.ReactNode,
  ) => React.ReactNode;
};

export const Autocomplete = <
  T extends object | string,
  FreeSolo extends boolean | undefined = false,
>({
  value,
  label,
  placeholder,
  optionIdKey,
  optionTextKey,
  options,
  inputProps,
  renderTag,
  renderAvatarAdornment,
  renderAvatarOption,
  renderAvatarTags,
  unshiftMode,
  customRef,
  multiple,
  ...restAutocompleteProps
}: AutocompleteProps<T, FreeSolo>) => {
  const { spacing, palette } = useTheme();
  const { t } = useTranslation(['common']);
  const { displayName } = usePreferredNameLayout();

  const getOptionKey = useCallback(
    (option: T) => {
      if (option && optionIdKey) {
        return option[optionIdKey];
      }

      if (restAutocompleteProps?.getOptionKey) {
        return restAutocompleteProps?.getOptionKey(option);
      }

      return JSON.stringify(option);
    },
    [optionIdKey, restAutocompleteProps?.getOptionKey],
  );

  const optionsByKey = useMemo(
    () => new Map(options.map((o) => [getOptionKey(o), o])),
    [getOptionKey, options],
  );

  const resolvedValue = useMemo(() => {
    if (restAutocompleteProps.freeSolo) {
      return value;
    }

    if (Array.isArray(value)) {
      return value
        .map((v) => optionsByKey.get(getOptionKey(v))!)
        .filter(Boolean);
    }
    return !value || typeof value === 'string'
      ? value
      : optionsByKey.get(getOptionKey(value))!;
  }, [value, optionsByKey, restAutocompleteProps]);

  const { variant } = inputProps ?? {};
  const isWhiteFilledVariant =
    variant === 'white-filled' || variant === 'white-filled-outlined';

  return (
    <MiAutocomplete<
      T,
      typeof multiple,
      typeof restAutocompleteProps.disableClearable,
      FreeSolo
    >
      ref={customRef}
      value={resolvedValue ?? null}
      isOptionEqualToValue={(option, newValue) =>
        getOptionKey(option) === getOptionKey(newValue)
      }
      loadingText={t('common:loading')}
      multiple={multiple}
      options={options}
      {...(optionTextKey && {
        getOptionLabel: (option) =>
          typeof option === 'string'
            ? option
            : (option[optionTextKey] as string),
      })}
      {...(optionIdKey && {
        getOptionKey: (option) =>
          typeof option === 'string' ? option : (option[optionIdKey] as string),
      })}
      popupIcon={null}
      {...restAutocompleteProps}
      ChipProps={{
        size: 'small',
        ...restAutocompleteProps.ChipProps,
      }}
      onChange={(event, newValue, ...restParams) => {
        const areOptionsStrings =
          options.length > 0 && typeof options[0] === 'string';
        const filteredNewValue =
          Array.isArray(newValue) && !areOptionsStrings
            ? newValue.filter((v) => typeof v !== 'string')
            : newValue;

        if (
          unshiftMode &&
          multiple &&
          Array.isArray(filteredNewValue) &&
          filteredNewValue.length > 0
        ) {
          const lastItem = filteredNewValue.at(
            filteredNewValue.length - 1,
          ) as T;
          filteredNewValue.pop();
          restAutocompleteProps.onChange?.(
            event,
            [lastItem, ...filteredNewValue] as AutocompleteValue<
              T,
              typeof multiple,
              typeof restAutocompleteProps.disableClearable,
              FreeSolo
            >,
            ...restParams,
          );
        } else {
          restAutocompleteProps.onChange?.(
            event,
            filteredNewValue,
            ...restParams,
          );
        }
      }}
      renderInput={(params) => (
        <TextField
          label={label}
          placeholder={placeholder}
          {...params}
          {...inputProps}
          InputProps={{
            ...params.InputProps,
            ...inputProps?.InputProps,
          }}
          variant={isWhiteFilledVariant ? 'filled' : variant}
          {...(renderAvatarAdornment && {
            InputProps: {
              ...params.InputProps,
              ...inputProps?.InputProps,
              ...(!multiple &&
                value && {
                  startAdornment: renderAvatarAdornment(
                    value as T,
                    (avatarProps) => (
                      <InputAdornment position="start" sx={{ ml: 0.75, mr: 0 }}>
                        <Avatar
                          sx={{ width: 24, height: 24, fontSize: '0.625rem' }}
                          {...avatarProps}
                        />
                      </InputAdornment>
                    ),
                  ),
                }),
            },
          })}
          {...(renderTag && {
            InputProps: {
              ...params.InputProps,
              ...inputProps?.InputProps,
              ...(!multiple &&
                value && {
                  value: '',
                  startAdornment: renderTag(value as T, (componentToRender) => (
                    <InputAdornment position="start" sx={{ ml: 0.75, mr: 0 }}>
                      {componentToRender}
                    </InputAdornment>
                  )),
                }),
            },
          })}
          sx={{
            ...inputProps?.sx,
            ...(renderTag && {
              '.MuiInputBase-input': {
                opacity: 0,
              },
            }),
            ...(isWhiteFilledVariant && {
              '& .MuiInputBase-root, & .MuiInputBase-root.Mui-focused': {
                backgroundColor: palette.background.default,
                borderRadius: spacing(1),
              },
              ...(variant === 'white-filled-outlined' && {
                '& .MuiInputBase-root.MuiInputBase-root': {
                  border: '1px solid',
                  borderColor: 'divider',
                  pt: '17px',
                },
              }),
              '& .MuiInputBase-root:hover': {
                backgroundColor: palette.primary.lighter,
              },
            }),
          }}
        />
      )}
      {...(renderAvatarOption && {
        renderOption: ({ key, ...props }, option) => (
          <Fragment key={String(optionIdKey ? option[optionIdKey] : option)}>
            {renderAvatarOption(
              option,
              ({ caption, hideAvatar, ...avatarProps }) => (
                <Stack
                  key={key}
                  component="li"
                  direction="row"
                  spacing={1}
                  {...props}
                >
                  {hideAvatar ? null : (
                    <Avatar
                      sx={{ width: 32, height: 32, fontSize: '0.75rem' }}
                      {...avatarProps}
                    />
                  )}
                  <Stack>
                    <Typography variant="subtitle2">
                      {avatarProps.name}
                    </Typography>
                    {caption && (
                      <Typography variant="caption">{caption}</Typography>
                    )}
                  </Stack>
                </Stack>
              ),
            )}
          </Fragment>
        ),
      })}
      {...(renderAvatarTags && {
        renderTags: (tags, getTagProps) =>
          tags.map((tag, index) =>
            renderAvatarTags(
              tag,
              ({ hideAvatar, ...avatarProps }, chipProps) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    size="small"
                    variant="soft"
                    color={
                      avatarProps.person?.firstName
                        ? getBaseColorBasedOnPerson(
                            displayName,
                            avatarProps.person,
                          )
                        : getBaseColorBasedOnString(avatarProps.name ?? '')
                    }
                    avatar={
                      hideAvatar ? undefined : <Avatar {...avatarProps} />
                    }
                    label={avatarProps.name}
                    {...tagProps}
                    {...chipProps}
                  />
                );
              },
            ),
          ),
      })}
    />
  );
};
