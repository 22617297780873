import { useQuery } from '@tanstack/react-query';
import type { QueryAdditionalOptions, UseQueryReturnType } from '../@types';
import { gqlClient } from '../clients';
import { graphql } from '../gql';
import type { YearGroupFilter } from '../gql/graphql';
import { queryClient } from '../query-client';
import { resolveAcademicHeader } from '../utils';
import { coreApiKeys } from './keys';

const yearGroups = graphql(/* GraphQL */ `
  query years($filter: YearGroupFilter) {
    catalogue_years(filter: $filter) {
      yearGroupId
      name
    }
  }
`);

const yearGroupsQuery = (
  filter: YearGroupFilter,
  academicNamespaceId?: number,
) => ({
  queryKey: coreApiKeys.yearGroups.all(),
  queryFn: () =>
    gqlClient.request(
      yearGroups,
      { filter },
      resolveAcademicHeader(academicNamespaceId),
    ),
});

export function getYearGroups(
  filter: YearGroupFilter,
  academicNamespaceId?: number,
) {
  return queryClient.fetchQuery(yearGroupsQuery(filter, academicNamespaceId));
}

export function useYearGroups(
  filter: YearGroupFilter,
  options?: QueryAdditionalOptions,
) {
  const { academicNamespaceId, ...additionalOptions } = options ?? {};
  return useQuery({
    ...yearGroupsQuery(filter, academicNamespaceId),
    ...additionalOptions,
    select: ({ catalogue_years }) => {
      if (!Array.isArray(catalogue_years)) return [];

      return catalogue_years;
    },
  });
}

export type ReturnTypeFromUseYearGroups = UseQueryReturnType<
  typeof useYearGroups
>[number];
