import { FormControlLabel, Stack, Switch } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { FileTransferRiuType, usePermissions } from '@tyro/api';
import type { TemplatingContextType } from '@tyro/api';
import { InsertImage, InsertLinkButton } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { AttachFiles } from './attach-files';
import type { AttachedFilesListProps } from './attach-files/list';
import { InsertTemplateButton } from './insert-template-button';
import { InsertVariableButton } from './insert-variable-button';
import { SendButton, type SendButtonProps } from './send-button';
import { TemplateVariableSelector } from './template-variable-selector';
import { MailEditorTextPopover } from './text-popover';

interface MailEditorToolbarProps extends SendButtonProps {
  editor: Editor | null;
  setAttachFiles: AttachedFilesListProps['setAttachFiles'];
  canReplyValue?: boolean;
  onCanReplyChange?: (canReply: boolean) => void;
  contextType?: TemplatingContextType | undefined;
  isTemplateSearchOpen?: boolean;
  setIsTemplateSearchOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isVariableSearchOpen?: boolean;
  setIsVariableSearchOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MailEditorToolbar({
  editor,
  onSend,
  isSending,
  setAttachFiles,
  canReplyValue,
  onCanReplyChange,
  contextType,
  isTemplateSearchOpen,
  setIsTemplateSearchOpen,
  isVariableSearchOpen,
  setIsVariableSearchOpen,
}: MailEditorToolbarProps) {
  const { t } = useTranslation(['mail']);
  const { hasPermission, isTyroUser, isStaffUser } = usePermissions();

  return (
    <>
      {(isTemplateSearchOpen || isVariableSearchOpen) && isStaffUser && (
        <TemplateVariableSelector
          isTemplateSearchOpen={Boolean(isTemplateSearchOpen)}
          isVariableSearchOpen={Boolean(isVariableSearchOpen)}
          setIsTemplateSearchOpen={(isOpen) =>
            setIsTemplateSearchOpen?.(isOpen)
          }
          setIsVariableSearchOpen={(isOpen) =>
            setIsVariableSearchOpen?.(isOpen)
          }
          contextType={contextType}
          editor={editor}
        />
      )}

      <Stack
        direction="row"
        py={2}
        pl={3}
        pr={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <SendButton
            onSend={onSend}
            isSending={isSending}
            disabled={isTyroUser}
          />
          <Stack direction="row" alignItems="center">
            {hasPermission('ps:1:documents:write_mail_attachments') && (
              <AttachFiles setAttachFiles={setAttachFiles} />
            )}
            <MailEditorTextPopover editor={editor} />
            <InsertLinkButton editor={editor} />
            <InsertImage editor={editor} riuType={FileTransferRiuType.Mail} />
            {isStaffUser && (
              <>
                <InsertVariableButton
                  onOpenChange={(isOpen) => setIsVariableSearchOpen?.(isOpen)}
                  contextType={contextType}
                />
                <InsertTemplateButton
                  onOpenChange={(isOpen) => setIsTemplateSearchOpen?.(isOpen)}
                  contextType={contextType}
                />
              </>
            )}
          </Stack>
        </Stack>
        {onCanReplyChange && (
          <FormControlLabel
            control={<Switch value={canReplyValue} />}
            label={t('mail:canReply')}
            checked={canReplyValue}
            onChange={(_e, checked) => onCanReplyChange(checked)}
          />
        )}
      </Stack>
    </>
  );
}
