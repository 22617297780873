import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type QueryAdditionalOptions,
  type UpdateYearGroupEnrollmentInput,
  type UseQueryReturnType,
  type YearGroupEnrollmentFilter,
  gqlClient,
  graphql,
  queryClient,
  resolveAcademicHeader,
} from '@tyro/api';
import { groupsKeys } from './keys';

const yearGroupEnrollmentsList = graphql(/* GraphQL */ `
  query yearGroupEnrollmentsList($filter: YearGroupEnrollmentFilter) {
    core_yearGroupEnrollments(filter: $filter) {
      partyId
      yearGroupEnrollmentPartyId
      name
      avatarUrl
      nationalCode
      yearGroupId
      shortName
      description
      yearGroupLeads {
        partyId
        title {
          id
          name
          nameTextId
        }
        firstName
        lastName
        avatarUrl
        type
      }
      studentMembers {
        memberCount
      }
    }
  }
`);

const yearGroupEnrollmentsDetails = graphql(/* GraphQL */ `
  query yearGroupEnrollmentsDetails($filter: YearGroupEnrollmentFilter) {
    core_yearGroupEnrollments(filter: $filter) {
      yearGroupEnrollmentPartyId
      name
      avatarUrl
      students {
        partyId
        person {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
        classGroup {
          partyId
          name
        }
        tutors {
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
        extensions {
          priority
          aen
          medical
        }
      }
      studentMembers {
        memberCount
      }
      yearGroupLeads {
        firstName
        lastName
        avatarUrl
      }
    }
  }
`);

const updateYearGroupEnrollmentsLeads = graphql(/* GraphQL */ `
  mutation core_updateYearGroupEnrollments(
    $input: [UpdateYearGroupEnrollmentInput!]
  ) {
    core_updateYearGroupEnrollments(input: $input) {
      success
    }
  }
`);

const yearGroupEnrollmentsQuery = (filter: YearGroupEnrollmentFilter = {}) => ({
  queryKey: groupsKeys.year.groupEnrollments(filter),
  queryFn: async () => {
    const { core_yearGroupEnrollments: yearGroupEnrollments } =
      await gqlClient.request(yearGroupEnrollmentsList, { filter });

    return {
      core_yearGroupEnrollments: yearGroupEnrollments.sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    };
  },
});

export function getYearGroupEnrollments(filter?: YearGroupEnrollmentFilter) {
  return queryClient.fetchQuery(yearGroupEnrollmentsQuery(filter));
}

export function useYearGroupEnrollments(filter?: YearGroupEnrollmentFilter) {
  return useQuery({
    ...yearGroupEnrollmentsQuery(filter),
    select: ({ core_yearGroupEnrollments }) => core_yearGroupEnrollments,
  });
}

const yearGroupEnrollmentsDetailsQuery = (
  filter: YearGroupEnrollmentFilter,
  academicNameSpaceId?: number,
) => ({
  queryKey: groupsKeys.year.details(filter),
  queryFn: async () =>
    gqlClient.request(
      yearGroupEnrollmentsDetails,
      {
        filter,
      },
      resolveAcademicHeader(academicNameSpaceId),
    ),
});

export function getYearGroupEnrollmentsDetails(
  filter: YearGroupEnrollmentFilter,
  academicNamespaceId?: number,
) {
  return queryClient.fetchQuery(
    yearGroupEnrollmentsDetailsQuery(filter, academicNamespaceId),
  );
}

export function useYearGroupEnrollmentsDetails(
  filter: YearGroupEnrollmentFilter,
  options?: QueryAdditionalOptions,
) {
  const { academicNamespaceId, ...additionalOptions } = options ?? {};
  return useQuery({
    ...yearGroupEnrollmentsDetailsQuery(filter, academicNamespaceId),
    ...additionalOptions,
    select: ({ core_yearGroupEnrollments }) => core_yearGroupEnrollments,
  });
}

export function useUpdateYearGroupEnrollmentLeads() {
  return useMutation({
    mutationFn: (input: UpdateYearGroupEnrollmentInput[]) =>
      gqlClient.request(updateYearGroupEnrollmentsLeads, { input }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: groupsKeys.year.all() });
    },
  });
}

export type ReturnTypeFromUseYearGroupEnrollments = UseQueryReturnType<
  typeof useYearGroupEnrollments
>[number];

export type ReturnTypeFromUseYearGroupEnrollmentsDetails = UseQueryReturnType<
  typeof useYearGroupEnrollmentsDetails
>;
