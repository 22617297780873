import { Box } from '@mui/material';
import { FileTransferRiuType } from '@tyro/api';
import { BaseMenuToolBar, useFormValidator, useTipTapEditor } from '@tyro/core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSaveTemplate } from '../../api/save-templates';
import { getOmittedTools } from '../../utils/get-omitted-tools';
import { mapTemplateTypeToTemplatingArea } from '../../utils/get-template-type';
import { ConfirmChangesModal } from '../modals/confirm-changes-modal';
import TemplateEditorInputField from './template-editor-input-field';
import { TemplateVariableSelector } from './template-variable-selector';
import type { TemplateFormState } from './types';

type TemplateEditorContainerProps = {
  initialState: TemplateFormState;
  onSuccess: () => void;
};

export function TemplateEditorContainer({
  onSuccess,
  initialState,
}: TemplateEditorContainerProps) {
  const {
    mutateAsync: saveTemplate,
    isPending: isSavingTemplate,
    isSuccess: hasTemplateBeenSaved,
  } = useSaveTemplate();

  const templateType = initialState?.templateType;

  const editor = useTipTapEditor({
    riuType: FileTransferRiuType.Templates,
  });

  const { resolver, rules } = useFormValidator<TemplateFormState>();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
    setValue,
  } = useForm<TemplateFormState>({
    resolver: resolver({
      name: rules.required(),
      context: rules.required(),
    }),
    defaultValues: initialState,
  });

  const onSubmit = handleSubmit(
    async ({ id, name, context, templateType }: TemplateFormState) => {
      if (!editor) return;

      const htmlContent = `<div class="template-wrapper">${editor.getHTML()}</div>`;

      const area = mapTemplateTypeToTemplatingArea(templateType);

      if (!area) {
        return;
      }

      await saveTemplate(
        {
          id,
          template: htmlContent,
          name,
          context,
          area,
        },
        {
          onSuccess,
        },
      );
    },
  );

  useEffect(() => {
    if (editor && initialState) {
      reset(initialState);
      setTimeout(
        () => editor.commands.setContent(initialState.template || ''),
        0,
      );
    }
  }, [editor, initialState]);

  useEffect(() => {
    const checkChanges = () => {
      setValue('template', editor?.getHTML() || '', { shouldDirty: true });
    };

    editor?.on('update', checkChanges);

    return () => {
      editor?.off('update', checkChanges);
    };
  }, [editor]);

  const [contextValue] = watch(['context']);

  const omittedTools = getOmittedTools(initialState?.templateType);

  if (!editor) return null;

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 2,
        maxWidth: 'fit-content',
      }}
    >
      <BaseMenuToolBar
        editor={editor}
        riuType={FileTransferRiuType.Templates}
        omittedTools={omittedTools}
        customTool={
          <TemplateVariableSelector
            contextValue={contextValue}
            editor={editor}
          />
        }
        templateType={templateType}
      />

      <TemplateEditorInputField
        control={control}
        editor={editor}
        isSavingTemplate={isSavingTemplate}
        templateType={templateType}
      />

      {!hasTemplateBeenSaved && !isSavingTemplate && (
        <ConfirmChangesModal isDirty={isDirty} />
      )}
    </Box>
  );
}
