import {
  RecipientSearchType,
  SmsRecipientType,
  usePermissions,
} from '@tyro/api';
import { type MenuItemConfig, useDisclosure } from '@tyro/core';
import { type TFunction, useTranslation } from '@tyro/i18n';
import { MobileIcon, SendMailIcon } from '@tyro/icons';
import { useMailSettings } from '@tyro/mail';
import { type RecipientsForSmsModal, SendSmsModal } from '@tyro/sms';
import { useMemo } from 'react';

type LabelFunc = (
  t: TFunction<('common' | 'people' | 'mail' | 'sms')[]>,
  selectedParties: RecipientsForSmsModal,
) => string;

export interface UsePeopleCommunicationActionsProps {
  selectedParties: (RecipientsForSmsModal[number] & {
    includeInSms?: boolean;
    allowedToContact?: boolean;
  })[];
  mailConfig: {
    type: RecipientSearchType;
    label: LabelFunc;
  }[];
  smsConfig: {
    type: SmsRecipientType;
    label: LabelFunc;
  }[];
}

export function usePeopleCommunicationActions({
  selectedParties,
  mailConfig,
  smsConfig,
}: UsePeopleCommunicationActionsProps): {
  actions: MenuItemConfig[];
  smsModal: JSX.Element;
} {
  const { t } = useTranslation(['common', 'people', 'mail', 'sms']);
  const { isStaffUserWithPermission, isStaffUserHasAllPermissions } =
    usePermissions();
  const { sendMailToParties } = useMailSettings();
  const {
    isOpen: isSendSmsOpen,
    onOpen: onOpenSendSms,
    onClose: onCloseSendSms,
  } = useDisclosure();

  return useMemo(() => {
    const smsParties = selectedParties.filter(
      (party) => party.includeInSms !== false,
    );
    const mailParties = selectedParties.filter(
      (party) => party.allowedToContact !== false,
    );
    const smsRecipientTypes = smsConfig.map(({ type, label }) => ({
      type,
      label: label(t, selectedParties),
    }));

    return {
      actions: [
        {
          label: t('people:sendSms'),
          icon: <MobileIcon />,
          onClick: onOpenSendSms,
          disabled: smsParties.length === 0,
          disabledTooltip: t('sms:recipientNotIncludedInSms', {
            count: selectedParties.length,
          }),
          hasAccess: () =>
            isStaffUserWithPermission('ps:1:communications:send_sms') &&
            selectedParties.length > 0,
        },
        {
          label: t('mail:sendMail'),
          icon: <SendMailIcon />,
          disabled: mailParties.length === 0,
          disabledTooltip: t('sms:recipientNotAllowedToContact', {
            count: selectedParties.length,
          }),
          hasAccess: () =>
            isStaffUserHasAllPermissions([
              'ps:1:communications:write_mail',
              'api:communications:read:search_recipients',
            ]) && selectedParties.length > 0,
          onClick: () => {
            sendMailToParties(
              mailParties.map(({ id }) => id),
              mailConfig.map(({ type, label }) => ({
                type,
                label: label(t, selectedParties),
              })),
            );
          },
        },
      ],
      smsModal: (
        <SendSmsModal
          isOpen={isSendSmsOpen}
          onClose={onCloseSendSms}
          recipients={smsParties}
          hideRecipientTypes={smsConfig.length <= 1}
          possibleRecipientTypes={smsRecipientTypes}
        />
      ),
    };
  }, [
    selectedParties,
    sendMailToParties,
    mailConfig,
    smsConfig,
    isSendSmsOpen,
    onOpenSendSms,
    onCloseSendSms,
    isStaffUserWithPermission,
  ]);
}

type CommunicationDefaultConfig = {
  sms: UsePeopleCommunicationActionsProps['smsConfig'];
  mail: UsePeopleCommunicationActionsProps['mailConfig'];
};

type PeopleCommunicationDefaultConfigs = {
  student: CommunicationDefaultConfig;
  studentContactsOnly: CommunicationDefaultConfig;
  contact: CommunicationDefaultConfig;
  staff: CommunicationDefaultConfig;
  classGroup: CommunicationDefaultConfig;
  customGroup: CommunicationDefaultConfig;
  subjectGroup: CommunicationDefaultConfig;
  supportGroup: CommunicationDefaultConfig;
  year: CommunicationDefaultConfig;
};

export const peopleCommunicationDefaultConfigs: PeopleCommunicationDefaultConfigs =
  {
    student: {
      sms: [
        {
          label: (t, selectParties) =>
            t('sms:contactsOfStudent', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.Student,
        },
        {
          label: (t, selectParties) =>
            t('sms:subjectTeachersOfStudent', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.StudentTeachers,
        },
      ],
      mail: [
        {
          label: (t, selectParties) =>
            t('mail:student', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.Student,
        },
        {
          label: (t, selectParties) =>
            t('mail:contactsOfStudent', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.ContactsOfStudent,
        },
        {
          label: (t, selectParties) =>
            t('mail:teachersOfStudent', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.StudentTeachers,
        },
      ],
    },
    studentContactsOnly: {
      sms: [
        {
          label: (t, selectParties) =>
            t('sms:contactsOfStudent', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.Student,
        },
      ],
      mail: [
        {
          label: (t, selectParties) =>
            t('mail:contactsOfStudent', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.ContactsOfStudent,
        },
      ],
    },
    contact: {
      sms: [
        {
          label: () => '',
          type: SmsRecipientType.Contact,
        },
      ],
      mail: [
        {
          label: (t, selectParties) =>
            t('mail:contact', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.Contact,
        },
      ],
    },
    staff: {
      sms: [
        {
          label: () => '',
          type: SmsRecipientType.Staff,
        },
      ],
      mail: [
        {
          label: (t) => t('common:staff'),
          type: RecipientSearchType.Staff,
        },
      ],
    },
    classGroup: {
      sms: [
        {
          label: (t, selectParties) =>
            t('sms:contactsOfStudentMembers', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.GeneralGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('sms:tutorsAndYearHeadsOfGroup', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.ClassGroupStaff,
        },
      ],
      mail: [
        {
          label: (t, selectParties) =>
            t('mail:contactsOfStudentsInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.GeneralGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('mail:studentInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.GeneralGroupStudent,
        },
        {
          label: (t, selectParties) =>
            t('mail:tutorsOfGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.ClassGroupTutors,
        },
        {
          label: (t, selectParties) =>
            t('mail:yearHeadsOfGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.ClassGroupYearHeads,
        },
      ],
    },
    customGroup: {
      sms: [
        {
          label: (t, selectParties) =>
            t('sms:contactsOfStudentMembers', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.GeneralGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('sms:staffInGroup', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.GeneralGroupStaff,
        },
      ],
      mail: [
        {
          label: (t, selectParties) =>
            t('mail:contactsOfStudentsInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.GeneralGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('mail:studentInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.GeneralGroupStudent,
        },
        {
          label: (t, selectParties) =>
            t('mail:staffInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.GeneralGroupStaff,
        },
      ],
    },
    subjectGroup: {
      sms: [
        {
          label: (t, selectParties) =>
            t('sms:contactsOfStudentMembers', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.SubjectGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('sms:teachersOfGroup', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.SubjectGroupStaff,
        },
      ],
      mail: [
        {
          label: (t, selectParties) =>
            t('mail:contactsOfStudentsInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.SubjectGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('mail:teachersOfGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.SubjectGroupStaff,
        },
        {
          label: (t, selectParties) =>
            t('mail:studentInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.SubjectGroupStudent,
        },
      ],
    },
    supportGroup: {
      sms: [
        {
          label: (t, selectParties) =>
            t('sms:contactsOfStudentMembers', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.GeneralGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('sms:staffInGroup', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.GeneralGroupStaff,
        },
      ],
      mail: [
        {
          label: (t, selectParties) =>
            t('mail:contactsOfStudentsInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.GeneralGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('mail:studentInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.GeneralGroupStudent,
        },
        {
          label: (t, selectParties) =>
            t('mail:staffInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.GeneralGroupStaff,
        },
      ],
    },
    year: {
      sms: [
        {
          label: (t, selectParties) =>
            t('sms:contactsOfStudentMembers', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.YearGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('sms:teachersOfGroup', {
              count: selectParties.length,
            }),
          type: SmsRecipientType.YearGroupStaff,
        },
      ],
      mail: [
        {
          label: (t, selectParties) =>
            t('mail:contactsOfStudentsInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.YearGroupContact,
        },
        {
          label: (t, selectParties) =>
            t('mail:studentInGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.YearGroupStudent,
        },
        {
          label: (t, selectParties) =>
            t('mail:yearHeadsOfGroup', {
              count: selectParties.length,
            }),
          type: RecipientSearchType.YearGroupStaff,
        },
      ],
    },
  };
