import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  Context,
  type SearchFilter,
  SearchType,
  gqlClient,
  graphql,
} from '@tyro/api';
import { groupsKeys } from './keys';

const groupSearch = graphql(/* GraphQL */ `
  query groupSearchQuery($filter: SearchFilter) {
    search_search(filter: $filter) {
      partyId
      type
      text
      avatarUrl
    }
  }
`);

export function useGroupSearch(query: string, filterOverride?: SearchFilter) {
  const trimmedQuery = query.trim();
  const filter: SearchFilter = {
    text: trimmedQuery,
    context: [Context.Party],
    includeSearchType: [
      SearchType.GeneralGroup,
      SearchType.SubjectGroup,
      SearchType.CustomGroup,
      SearchType.GeneralGroup,
    ],
    ...filterOverride,
  };

  return useQuery({
    queryKey: groupsKeys.search(filter),
    queryFn: async () =>
      gqlClient.request(groupSearch, {
        filter,
      }),
    enabled: trimmedQuery.length > 0,
    placeholderData: keepPreviousData,
    select: ({ search_search }) => search_search,
  });
}
