import type { AdminNotificationFilter } from '@tyro/api';

export const notificationKeys = {
  all: ['notifications'] as const,
  adminNotifications: (filter: AdminNotificationFilter) =>
    [...notificationKeys.all, 'adminNotifications', filter] as const,
  notificationRecipients: (
    filter: Pick<AdminNotificationFilter, 'notificationId'>,
  ) => [...notificationKeys.all, 'notificationRecipients', filter] as const,
};
