import type FullCalendar from '@fullcalendar/react';
import { Box, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePreference } from '@tyro/api';
import { ActionMenu, useResponsive } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  AddIcon,
  EditCalendarIcon,
  EyeIcon,
  EyeSlashIcon,
  PrinterIcon,
} from '@tyro/icons';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import type { Dispatch, RefObject, SetStateAction } from 'react';
import type { CalendarView } from '../../../../@types/calendar';
import { DateSwitcher } from './date-switcher';
import { CalendarViewSwitcher } from './view-switcher';

dayjs.extend(LocalizedFormat);

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

interface CalendarToolbarProps {
  calendarRef: RefObject<FullCalendar>;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  view: CalendarView;
  onEditCalendar: VoidFunction;
  onAddEvent: VoidFunction;
  onChangeView: (newView: CalendarView) => void;
  hasMultipleResources?: boolean;
  canAddEvent?: boolean;
  onPrint: VoidFunction;
}

export function CalendarToolbar({
  calendarRef,
  date,
  setDate,
  view,
  onEditCalendar,
  onAddEvent,
  onChangeView,
  hasMultipleResources = true,
  canAddEvent,
  onPrint,
}: CalendarToolbarProps) {
  const isDesktop = useResponsive('up', 'sm');
  const { t } = useTranslation(['common', 'calendar']);
  const [showWeekends, setShowWeekends] = usePreference(
    'calendar.show-weekends',
    false,
  );

  const currentDate = dayjs(date);
  const onPreviousDateClick = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const onNextDateClick = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const onChangeDate = (newDate: dayjs.Dayjs) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.gotoDate(newDate.toDate());
      setDate(calendarApi.getDate());
    }
  };

  return (
    <RootStyle className="toolbar">
      {isDesktop && (
        <CalendarViewSwitcher
          value={view}
          onChange={onChangeView}
          hasMultipleResources={hasMultipleResources}
        />
      )}

      <DateSwitcher
        onPreviousDateClick={onPreviousDateClick}
        onNextDateClick={onNextDateClick}
        date={currentDate}
        onChangeDate={onChangeDate}
      />

      <Stack direction="row" spacing={2}>
        <ActionMenu
          buttonProps={{
            size: 'small',
            color: 'primary',
            variant: 'text',
            sx: {
              '& .MuiButton-endIcon': {
                ml: 0.5,
              },
            },
          }}
          menuItems={[
            {
              label: t('calendar:addEvent'),
              onClick: onAddEvent,
              icon: <AddIcon />,
              hasAccess: () => !!canAddEvent,
            },
            {
              label: showWeekends
                ? t('calendar:hideWeekends')
                : t('calendar:showWeekends'),
              onClick: () => setShowWeekends(!showWeekends),
              icon: showWeekends ? <EyeSlashIcon /> : <EyeIcon />,
            },
            {
              label: t('common:actions.print'),
              onClick: onPrint,
              icon: <PrinterIcon />,
            },
          ]}
        />
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={onEditCalendar}
          startIcon={<EditCalendarIcon sx={{ width: 20, height: 20 }} />}
        >
          {t('calendar:filterCalendar')}
        </Button>
      </Stack>
    </RootStyle>
  );
}
