export const commonActionMenuProps = {
  colId: 'actions',
  suppressColumnsToolPanel: true,
  suppressHeaderMenuButton: true,
  sortable: false,
  filter: false,
  resizable: false,
  pinned: 'right',
  width: 56,
  cellStyle: { padding: '0 8px' },
  lockVisible: true,
} as const;
