import {
  setUser as setSentryUser,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import {
  getCoreAcademicNamespace,
  getPermissionUtils,
  getPersonalSettings,
  getUser,
} from '@tyro/api';
import {
  type HasAccessFunction,
  LazyLoader,
  LoadingScreen,
  type MenuLink,
  type NavCategory,
  type NonMenuLink,
  type RootGroup,
  type RootLink,
} from '@tyro/core';
import omit from 'lodash/omit';
import {
  Outlet,
  type RouteObject,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';

import { getRoutes as getAssessmentRoutes } from '@tyro/assessments';
import { getRoutes as getAttendanceRoutes } from '@tyro/attendance';
// External routers
import { routes as authRoutes } from '@tyro/authentication';
import { getRoutes as getCalendarRoutes } from '@tyro/calendar';
import { getRoutes as getClassListManagerRoutes } from '@tyro/class-list-manager';
import { getRoutes as getFeeRoutes } from '@tyro/fees';
import { getRoutes as getFormsRoutes } from '@tyro/forms';
import { getRoutes as getGroupRoutes } from '@tyro/groups';
import type { TFunction } from '@tyro/i18n';
import { getRoutes as getInfoRequestsRoutes } from '@tyro/info-requests';
import { getRoutes as getMailRoutes } from '@tyro/mail';
import { getRoutes as getNoticeBoardRoutes } from '@tyro/notice-board';
import { getRoutes as getNotificationsRoutes } from '@tyro/notifications';
import { getRoutes as getPeopleRoutes } from '@tyro/people';
import { getRoutes as getPrintingRoutes } from '@tyro/printing';
import { getRoutes as getReportingRoutes } from '@tyro/reporting';
import { getRoutes as getRoomBookingRoutes } from '@tyro/room-booking';
import { getRoutes as getRulesRoutes } from '@tyro/rules';
import { getRoutes as getSchoolActivitiesRoutes } from '@tyro/school-activities';
import { getRoutes as getSettingsRoutes } from '@tyro/settings';
import { getRoutes as getSmsRoutes } from '@tyro/sms';
import { getRoutes as getSubjectOptionsRoutes } from '@tyro/subject-options';
import { getRoutes as getSubstitutionRoutes } from '@tyro/substitution';
import { getRoutes as getTemplatesRoutes } from '@tyro/templates';
import { getRoutes as getTimetableRoutes } from '@tyro/timetable';
import { getRoutes as getAdminRoutes } from '@tyro/tyro-admin';
import { getRoutes as getTyroTuition } from '@tyro/tyro-tuition';

import { Box, CircularProgress } from '@mui/material';
import { ErrorElement } from './components/error-element';
import { Shell } from './components/shell';
import { getShellRoutes } from './routes';

async function checkHasAccess(hasAccess: HasAccessFunction) {
  const permissionUtils = await getPermissionUtils();
  if (!hasAccess(permissionUtils)) {
    throw new Response('Forbidden', { status: 403 });
  }
}

function getRoutesBasedOnPermissions(
  routes: (RootLink | RootGroup | MenuLink | NonMenuLink)[],
): RouteObject[] {
  return routes.reduce<RouteObject[]>((acc, route) => {
    let { loader } = route;

    if (route.hasAccess !== undefined) {
      const { hasAccess } = route;
      loader = async (...args) => {
        await checkHasAccess(hasAccess);

        if (route?.loader && typeof route.loader === 'function') {
          return route.loader(...args);
        }

        return null;
      };
    }
    const children = Array.isArray(route.children)
      ? getRoutesBasedOnPermissions(route.children)
      : undefined;

    const routeWithoutCustomProps = omit(route, [
      'type',
      'title',
      'icon',
      'hasAccess',
    ]);
    const routeObject = {
      ...routeWithoutCustomProps,
      loader,
      children,
    } as RouteObject;

    acc.push(routeObject);

    return acc;
  }, []);
}

function buildRouteTree(routes: NavCategory[]): RouteObject[] {
  return routes.reduce<RouteObject[]>((acc, route) => {
    if (Array.isArray(route.children)) {
      acc.push(...getRoutesBasedOnPermissions(route.children));
    }

    return acc;
  }, []);
}

const mockTFunction = ((key: string) => key) as TFunction<
  'navigation'[],
  undefined,
  'navigation'[]
>;

export const getNavCategories = (t: TFunction<'navigation'[]>) => [
  ...getShellRoutes(t),
  ...getCalendarRoutes(t),
  ...getNoticeBoardRoutes(t),
  ...getClassListManagerRoutes(t),
  ...getGroupRoutes(t),
  ...getAttendanceRoutes(t),
  ...getMailRoutes(t),
  ...getFeeRoutes(t),
  ...getInfoRequestsRoutes(t),
  ...getAssessmentRoutes(t),
  ...getSchoolActivitiesRoutes(t),
  ...getRoomBookingRoutes(t),
  ...getPeopleRoutes(t),
  ...getReportingRoutes(t),
  ...getTemplatesRoutes(t),
  ...getSmsRoutes(t),
  ...getNotificationsRoutes(t),
  ...getSubjectOptionsRoutes(t),
  ...getSubstitutionRoutes(t),
  ...getTimetableRoutes(t),
  ...getTyroTuition(t),
  ...getPrintingRoutes(t),
  ...getFormsRoutes(t),
  ...getRulesRoutes(t),
  ...getSettingsRoutes(t),
  ...getAdminRoutes(t),
];

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

function useAppRouter() {
  return sentryCreateBrowserRouter(
    [
      {
        loader: async () => {
          try {
            const responses = await Promise.all([
              getUser(),
              getCoreAcademicNamespace(),
            ]);
            const [user] = responses;

            if (user.activeProfile?.partyId) {
              const { activeProfile } = user;
              setSentryUser({
                id: String(activeProfile.partyId),
                segment: activeProfile.profileType?.userType ?? 'unknown',
                tenant: activeProfile.tenant?.tenant ?? 'unknown',
              });
            }

            return responses;
          } catch (error) {
            if (
              error instanceof Error &&
              error?.message === 'Failed to fetch'
            ) {
              throw new Response('Service Unavailable', { status: 503 });
            }

            throw error;
          }
        },
        element: (
          <LazyLoader>
            <Shell>
              <LazyLoader
                fallback={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: '100vh',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <Outlet />
              </LazyLoader>
            </Shell>
          </LazyLoader>
        ),
        errorElement: (
          <LazyLoader>
            <ErrorElement />
          </LazyLoader>
        ),
        children: [
          {
            path: '/',
            loader: async () => {
              const redirectPath =
                window.localStorage.getItem('tyro:initial-route');

              if (redirectPath) {
                window.localStorage.removeItem('tyro:initial-route');
                return redirect(redirectPath);
              }

              const permissions = await getPermissionUtils();
              await getPersonalSettings();

              if (permissions.isStudent || permissions.isContact) {
                return redirect('/people/students');
              }

              if (permissions.isTyroTenantAndUser) {
                return redirect('/admin/schools');
              }

              return redirect('/dashboard');
            },
          },
          ...buildRouteTree(getNavCategories(mockTFunction)),
        ],
      },
      ...authRoutes,
    ],
    {
      future: {
        v7_relativeSplatPath: true,
        // v7_skipActionErrorRevalidation: true,
        // v7_partialHydration: true,
        // v7_normalizeFormMethod: true,
        // v7_fetcherPersist: true,
      },
    },
  );
}

export function Router() {
  const appRouter = useAppRouter();

  return (
    <RouterProvider
      router={appRouter}
      fallbackElement={<LoadingScreen />}
      // Breaks navigation after create contact page
      // future={{
      //   v7_startTransition: true,
      // }}
    />
  );
}
