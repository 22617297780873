import type { Scheduling_ScheduleFilter } from '@tyro/api';
import type { AdminLookupFilter } from '@tyro/api';

export const adminKeys = {
  all: ['admin'] as const,
  tenants: () => [...adminKeys.all, 'tenants'] as const,
  people: (tenantId: number) => [...adminKeys.all, 'people', tenantId] as const,
  scheduledImports: (filter?: Scheduling_ScheduleFilter) =>
    [...adminKeys.all, 'scheduledImports', filter] as const,
  userLookup: (filter: AdminLookupFilter) =>
    [...adminKeys.all, 'userLookup', filter] as const,
};
