import { ConfirmDialog } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useArchiveAssessment } from '../../api/archive-assessment';
import type { ReturnTypeFromUseAssessments } from '../../api/assessments';

export interface DeleteAssessmentConfirmModalProps {
  isOpen: boolean;
  assessmentToDelete: number;
  onClose: () => void;
  assessmentType: ReturnTypeFromUseAssessments['assessmentType'];
  assessmentName: string;
}

export function DeleteAssessmentConfirmModal({
  isOpen,
  assessmentToDelete,
  onClose,
  assessmentType,
  assessmentName,
}: DeleteAssessmentConfirmModalProps) {
  const { t } = useTranslation(['assessments', 'common']);

  const { mutate: deleteAssessment } = useArchiveAssessment();

  const onSubmit = () => {
    deleteAssessment(
      { id: assessmentToDelete, type: assessmentType },
      { onSuccess: onClose },
    );
  };

  return (
    <ConfirmDialog
      open={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
      title={t('assessments:deleteAssessment')}
      description={t('assessments:deleteAssessmentDescription', {
        name: assessmentName,
      })}
      confirmText={t('common:actions.delete')}
      isDelete
    />
  );
}
