import { en } from './en';
import { ga } from './ga';
import { cs } from './cs';

export { en, ga, cs }

export const resources = {
  en,
  ga,
  cs,
} as const;
