import {
  type SmsRecipientType,
  SmsRecipientType as SmsRecipients,
  TemplatingContextType,
} from '@tyro/api';

export function getContextFromRecipient(
  recipientType: SmsRecipientType,
): TemplatingContextType {
  const contextMap: Record<SmsRecipientType, TemplatingContextType> = {
    [SmsRecipients.ClassGroupStaff]: TemplatingContextType.Staff,
    [SmsRecipients.Contact]: TemplatingContextType.Person,
    [SmsRecipients.ContactsOfStudent]: TemplatingContextType.Student,
    [SmsRecipients.GeneralGroupContact]: TemplatingContextType.Person,
    [SmsRecipients.GeneralGroupStaff]: TemplatingContextType.Staff,
    [SmsRecipients.Person]: TemplatingContextType.Person,
    [SmsRecipients.Staff]: TemplatingContextType.Staff,
    [SmsRecipients.Student]: TemplatingContextType.Student,
    [SmsRecipients.StudentTeachers]: TemplatingContextType.Staff,
    [SmsRecipients.SubjectGroupContact]: TemplatingContextType.Person,
    [SmsRecipients.SubjectGroupStaff]: TemplatingContextType.Staff,
    [SmsRecipients.YearGroupContact]: TemplatingContextType.Person,
    [SmsRecipients.YearGroupStaff]: TemplatingContextType.Staff,
  };

  return contextMap[recipientType];
}
