import { TemplatingArea } from '@tyro/api';

export enum TemplateTypeEnum {
  letter = 'letter',
  email = 'email',
  sms = 'sms',
}

export type TemplateType = keyof typeof TemplateTypeEnum;

const validTemplateTypes: TemplateType[] = Object.values(TemplateTypeEnum);

export function getTemplateType(path: string): TemplateType | null {
  // Regex to match the word following '/templates/'
  const match = path.match(/\/templates\/([^/]+)/);
  const type = match?.[1];

  return validTemplateTypes.includes(type as TemplateType)
    ? (type as TemplateType)
    : null;
}

export function mapTemplateTypeToTemplatingArea(
  templateType: string | null | undefined,
): TemplatingArea | null {
  if (typeof templateType === 'undefined') return null;
  switch (templateType) {
    case 'email':
      return TemplatingArea.Mail;
    case 'letter':
      return TemplatingArea.Letter;
    case 'sms':
      return TemplatingArea.Sms;
    default:
      return null;
  }
}
