import { ClickAwayListener } from '@mui/material';
import { Fade, Paper } from '@mui/material';
import { Box, Stack, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';
import type { TemplatingContextType } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { NewTemplateIcon } from '@tyro/icons';
import { TemplatesAutocomplete } from './templates-autocomplete';
import { VariablesAutocomplete } from './variables-autocomplete';

type TemplateVariableSelectorProps = {
  isTemplateSearchOpen: boolean;
  setIsTemplateSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isVariableSearchOpen: boolean;
  setIsVariableSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contextType: TemplatingContextType | undefined;
  editor: Editor | null;
};
export function TemplateVariableSelector({
  isTemplateSearchOpen,
  isVariableSearchOpen,
  setIsTemplateSearchOpen,
  setIsVariableSearchOpen,
  contextType,
  editor,
}: TemplateVariableSelectorProps) {
  const { t } = useTranslation(['common']);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsTemplateSearchOpen(false);
        setIsVariableSearchOpen(false);
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Fade in={isTemplateSearchOpen || isVariableSearchOpen} unmountOnExit>
          <Box
            sx={{
              p: '4px',
              backgroundColor: 'background.paper',
              borderRadius: '20px 20px 0 0',
              boxShadow: '0px -3px 4px 0px #6366F12E',
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 2,
                borderRadius: '20px 20px 0 0',
                backgroundColor: 'slate.50',
              }}
            >
              <Stack display="flex" flexDirection="row" alignItems="center">
                <NewTemplateIcon />
                <Typography variant="body2" ml={1}>
                  {isVariableSearchOpen
                    ? t('common:variables')
                    : t('common:templates')}
                </Typography>
                {isTemplateSearchOpen && (
                  <TemplatesAutocomplete
                    contextType={contextType}
                    setIsTemplateSearchOpen={setIsTemplateSearchOpen}
                    editor={editor}
                  />
                )}
                {isVariableSearchOpen && (
                  <VariablesAutocomplete
                    contextType={contextType}
                    setIsVariableSearchOpen={setIsVariableSearchOpen}
                    editor={editor}
                  />
                )}
              </Stack>
            </Paper>
          </Box>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
}
