import { useMutation } from '@tanstack/react-query';

import {
  type UpdateStaffInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { peopleKeys } from '../keys';

const updateStaff = graphql(/* GraphQL */ `
  mutation core_updateStaff($input: [UpdateStaffInput!]!) {
    core_updateStaff(input: $input) {
      success
    }
  }
`);

export function useUpdateStaff() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: UpdateStaffInput[]) =>
      gqlClient.request(updateStaff, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: peopleKeys.staff.all() });

      toast(t('common:snackbarMessages.updateSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}
