import { TemplatingContextType } from '@tyro/api';
import { PageContainer, PageHeading, TabPageContainer } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useLocation } from 'react-router-dom';
import { getTemplateType } from '../utils/get-template-type';

export const templatesPaths: Record<string, TemplatingContextType> = {
  students: TemplatingContextType.Student,
  staff: TemplatingContextType.Staff,
};

export default function TemplatesContainer() {
  const { t } = useTranslation(['common', 'templates']);
  const pathname = useLocation().pathname;
  const templateType = getTemplateType(pathname);
  const title =
    templateType === null
      ? t('templates:templateTitles.templates')
      : t(`templates:templateTitles.${templateType}`);

  return (
    <PageContainer title={t('templates:title')}>
      <PageHeading title={title} titleProps={{ variant: 'h3' }} />
      <TabPageContainer
        links={[
          {
            label: t('common:students'),
            value: 'students',
          },
          {
            label: t('common:staff'),
            value: 'staff',
          },
        ]}
      />
    </PageContainer>
  );
}
