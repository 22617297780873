import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogContentText,
  List,
  ListItem,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { ListItemText } from '@mui/material';
import {
  type BackendErrorResponse,
  CalendarEventAttendeeType,
  CalendarEventSource,
  CalendarEventType,
  Colour,
  type ParsedErrorDetail,
  RecurrenceEnum,
  type UsePermissionsReturn,
  UserType,
  usePermissions,
} from '@tyro/api';
import {
  ConfirmDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFAutocomplete,
  RHFCheckboxGroup,
  RHFColorPicker,
  RHFTextField,
  type ValidationError,
  useDisclosure,
  useFormValidator,
  usePreferredNameLayout,
  useToast,
  validations,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { LocationIcon, PersonGraduateIcon } from '@tyro/icons';
import { usePartySearchProps } from '@tyro/people';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { type Resolver, useForm } from 'react-hook-form';
import type { SchoolCalendarFilterArray } from '../../../../@types/calendar';
import { useCreateCalendarEvent } from '../../../../api/add-event';
import { useEditCalendarEvents } from '../../../../api/edit-events';
import {
  type ReturnTypeFromUseCalendarCustomTags,
  useCalendarCustomTags,
} from '../../../../api/tags/list';
import type { CalendarParty } from '../../../../hooks/use-calendar-search-props';
import { MINIMUM_EVENT_DURATION } from './constants';
import { useGetRecurrenceFilter } from './hooks/use-get-recurrence-filter';
import { RoomLocationOptions } from './room-location-options';
import { ScheduleEvent } from './schedule-event';
import type { ClashData, FormState } from './types';

export type CalendarEventViewProps = {
  isOpen: boolean;
  initialState?: Partial<FormState> | null;
  onClose: () => void;
  allowAddToPublicCalendar?: boolean;
};

const defaultFormStateValues: Partial<FormState> = {
  type: CalendarEventType.General,
  startDate: dayjs(),
  startTime: dayjs(),
  endTime: dayjs().add(MINIMUM_EVENT_DURATION, 'minutes'),
  recurrenceEnum: RecurrenceEnum.NoRecurrence,
  colour: Colour.Red,
  allowClashes: false,
  customTags: [],
  showOnPublicCalendarTypes: [],
};

type TeachersDetailsProps = {
  teacherNames: string;
};

const TeachersDetails = ({ teacherNames }: TeachersDetailsProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Stack gap={0.5} flexDirection="row" alignItems="center">
      <PersonGraduateIcon
        sx={{ width: 18, height: 18, color: 'text.secondary' }}
        aria-label={t('common:teachers')}
      />
      <Typography color="text.secondary">{teacherNames}</Typography>
    </Stack>
  );
};

type LocationsDetailsProps = {
  roomNames: string;
};

const LocationsDetails = ({ roomNames }: LocationsDetailsProps) => {
  const { t } = useTranslation(['calendar']);

  return (
    <Stack gap={0.5} flexDirection="row" alignItems="center">
      <LocationIcon
        sx={{ width: 18, height: 18, color: 'text.secondary' }}
        aria-label={t('calendar:room')}
      />
      <Typography color="text.secondary">{roomNames}</Typography>
    </Stack>
  );
};

const permissionToAddToStaffPublicCalendar =
  'ps:1:calendar:add_event_to_public_staff_calendar';
const permissionToAddToContactPublicCalendar =
  'ps:1:calendar:add_event_to_public_contact_calendar';
const permissionToAddToStudentPublicCalendar =
  'ps:1:calendar:add_event_to_public_student_calendar';

const getPublicCalendarOptions = ({ hasPermission }: UsePermissionsReturn) => {
  return [
    hasPermission(permissionToAddToStaffPublicCalendar)
      ? UserType.Teacher
      : null,
    hasPermission(permissionToAddToContactPublicCalendar)
      ? UserType.Contact
      : null,
    hasPermission(permissionToAddToStudentPublicCalendar)
      ? UserType.Student
      : null,
  ].filter(Boolean) as SchoolCalendarFilterArray;
};

export const CalendarEventDetailsModal = ({
  isOpen,
  initialState,
  onClose,
  allowAddToPublicCalendar = false,
}: CalendarEventViewProps) => {
  const { t } = useTranslation(['calendar', 'common']);
  const { toast } = useToast();
  const { displayNames } = usePreferredNameLayout();
  const permissions = usePermissions();
  const { hasPermission } = permissions;
  const hasPermissionToAddToStaffPublicCalendar = hasPermission(
    permissionToAddToStaffPublicCalendar,
  );
  const hasPermissionToAddToContactPublicCalendar = hasPermission(
    permissionToAddToContactPublicCalendar,
  );
  const hasPermissionToAddToStudentPublicCalendar = hasPermission(
    permissionToAddToStudentPublicCalendar,
  );

  const {
    isOpen: isConfirmClashOpen,
    onOpen: openConfirmClash,
    onClose: closeConfirmClash,
  } = useDisclosure();

  const participantsProps = usePartySearchProps();

  const { data: customTags, isLoading: isCustomTagsLoading } =
    useCalendarCustomTags({}, { enabled: isOpen });
  const { mutateAsync: createEvent, isPending: isCreateSubmitting } =
    useCreateCalendarEvent();

  const { mutateAsync: editEvent, isPending: isEditSubmitting } =
    useEditCalendarEvents();

  const [clashes, setClashes] = useState<ClashData[]>([]);

  const isSubmitting = isCreateSubmitting || isEditSubmitting;

  const { resolver, rules } = useFormValidator<FormState>();

  const rulesByEvent = useMemo<Resolver<FormState>>(() => {
    const generalRules = {
      name: rules.required(),
      allDayEvent: rules.required(),
      startDate: [rules.date(), rules.required()],
      startTime: [
        rules.required(),
        rules.date(t('common:errorMessages.invalidTime')),
      ],
      endTime: [
        rules.required(),
        rules.date(t('common:errorMessages.invalidTime')),
        rules.afterStartDate(
          'startTime',
          t('common:errorMessages.afterStartTime'),
        ),
        rules.validate<FormState['endTime']>(
          (endTime, throwError, { startTime }) => {
            if (
              dayjs(endTime).diff(startTime, 'minutes') < MINIMUM_EVENT_DURATION
            ) {
              throwError(
                t('calendar:errorMessages.minEventDuration', {
                  time: MINIMUM_EVENT_DURATION,
                }),
              );
            }
          },
        ),
      ],
      recurrenceEnum: rules.required(),
      ends: rules.required(),
      occurrences: rules.validate<FormState['occurrences']>(
        (occurrences, throwError, { ends }) => {
          if (ends === 'after') {
            try {
              validations.required(
                occurrences,
                t('common:errorMessages.required'),
              );
              validations.min(
                occurrences ?? 0,
                1,
                t('common:errorMessages.min', { number: 1 }),
              );
            } catch (error) {
              throwError((error as ValidationError).message);
            }
          }
        },
      ),
      endDate: [
        rules.date(),
        rules.afterStartDate('startDate'),
        rules.validate<FormState['endDate']>(
          (endDate, throwError, { ends }) => {
            if (ends === 'on') {
              try {
                validations.required(
                  endDate,
                  t('common:errorMessages.required'),
                );
              } catch (error) {
                throwError((error as ValidationError).message);
              }
            }
          },
        ),
      ],
      colour: rules.required(),
    };

    switch (initialState?.type) {
      case CalendarEventType.RoomBooking: {
        return resolver({ ...generalRules, locations: rules.required() });
      }

      default:
        return resolver({ ...generalRules });
    }
  }, [initialState, rules, resolver, t]);

  const { control, handleSubmit, watch, reset, setValue } = useForm<FormState>({
    resolver: rulesByEvent,
    defaultValues: defaultFormStateValues,
  });

  const [
    allDayEvent,
    startDate,
    startTime,
    endTime,
    recurrenceEnum,
    occurrences,
    endDate,
    participants,
    locations,
    eventName,
    selectedCustomTags,
  ] = watch([
    'allDayEvent',
    'startDate',
    'startTime',
    'endTime',
    'recurrenceEnum',
    'occurrences',
    'endDate',
    'participants',
    'locations',
    'name',
    'customTags',
  ]);

  const recurrenceFilter = useGetRecurrenceFilter({
    allDayEvent,
    startDate,
    startTime,
    endTime,
    recurrenceEnum,
    occurrences,
    endDate,
  });

  const onSubmit = handleSubmit(
    async ({
      type,
      participants,
      locations,
      allowClashes,
      calendarId,
      eventId,
      name,
      description,
      colour,
      recurrenceEnum,
      allDayEvent,
      startDate,
      startTime,
      endDate,
      endTime,
      customTags,
      showOnPublicCalendarTypes,
    }) => {
      if (!recurrenceFilter) return;
      try {
        const publicSchoolOptions = allowAddToPublicCalendar
          ? ({
              publicContactCalendar: hasPermissionToAddToContactPublicCalendar
                ? (showOnPublicCalendarTypes.includes(UserType.Contact) ??
                  false)
                : undefined,
              publicStaffCalendar: hasPermissionToAddToStaffPublicCalendar
                ? (showOnPublicCalendarTypes.includes(UserType.Teacher) ??
                  false)
                : undefined,
              publicStudentCalendar: hasPermissionToAddToStudentPublicCalendar
                ? (showOnPublicCalendarTypes.includes(UserType.Student) ??
                  false)
                : undefined,
            } as const)
          : {};

        if (eventId && calendarId) {
          const isSingle = recurrenceEnum === RecurrenceEnum.NoRecurrence;

          await editEvent({
            calendarId,
            editSource: CalendarEventSource.Manual,
            events: [
              {
                eventId,
                name,
                description,
                colour,
                schedule: {
                  startDate: startDate.format('YYYY-MM-DD'),
                  startTime: startTime.format('HH:mm'),
                  endTime: endTime.format('HH:mm'),
                  endDate:
                    isSingle || !endDate ? null : endDate.format('YYYY-MM-DD'),
                  recurrenceEnum: isSingle ? null : recurrenceEnum,
                },
                attendees: participants.map(({ partyId, attendeeType }) => ({
                  partyId,
                  type: attendeeType ?? CalendarEventAttendeeType.Attendee,
                  tags: [],
                })),
                rooms: locations.map(({ roomId }) => ({
                  roomId,
                  tags: [],
                })),
                customTagIds: customTags?.map(({ id }) => id) ?? [],
                ...publicSchoolOptions,
              },
            ],
          });
          setClashes([]);
        } else {
          await createEvent(
            {
              allowClashes,
              events: [
                {
                  name,
                  description,
                  colour,
                  type: type || CalendarEventType.General,
                  recurrenceEnum,
                  allDayEvent,
                  startDate: recurrenceFilter.fromDate,
                  startTime: recurrenceFilter.startTime,
                  endTime: recurrenceFilter.endTime,
                  endDate: recurrenceFilter.endDate ?? null,
                  occurrences: recurrenceFilter.occurrences ?? null,
                  attendees: participants.map(({ partyId, attendeeType }) => ({
                    partyId,
                    type: attendeeType ?? CalendarEventAttendeeType.Attendee,
                    // TODO: remove when tags are non mandatory
                    tags: [],
                  })),
                  rooms: locations.map(({ roomId }) => ({
                    roomId,
                    // TODO: remove when tags are non mandatory
                    tags: [],
                  })),
                  // TODO: remove when tags are non mandatory
                  tags: [],
                  customTagIds: customTags?.map(({ id }) => id) ?? [],
                  ...publicSchoolOptions,
                },
              ],
            },
            {
              onSuccess: ({ calendar_createCalendarEvents: responseData }) => {
                if (responseData.success) {
                  setClashes([]);
                } else {
                  setClashes(
                    (responseData.clashes || [])
                      .flatMap((clash) =>
                        clash
                          ? [
                              {
                                ...clash,
                                clashingTeachers: clash.clashingParties.filter(
                                  (party) => party.__typename === 'Staff',
                                ),
                                clashingRooms: clash.clashingRooms || [],
                              },
                            ]
                          : [],
                      )
                      .sort(
                        (clashA, clashB) =>
                          dayjs(clashA.startTime, 'HH:mm').unix() -
                          dayjs(clashB.startTime, 'HH:mm').unix(),
                      ),
                  );

                  openConfirmClash();
                }
              },
            },
          );
        }
      } catch (error: unknown) {
        let errorMessage = t('common:snackbarMessages.errorFailed');

        if (typeof error === 'object' && error !== null) {
          const backendError = error as BackendErrorResponse;
          try {
            const parsedError = JSON.parse(
              backendError.response.error,
            ) as ParsedErrorDetail;
            if (parsedError.detail.includes('clash')) {
              openConfirmClash();
              return;
            }

            errorMessage = parsedError.detail || errorMessage;
          } catch (parseError) {
            console.error('Error parsing the error message:', parseError);
          }
        }

        toast(errorMessage, { variant: 'error' });
      } finally {
        onClose();
      }
    },
  );

  useEffect(() => {
    if (initialState) {
      reset({
        ...defaultFormStateValues,
        ...initialState,
      });
    }
  }, [initialState]);

  useEffect(() => {
    if (!allowAddToPublicCalendar) return;

    const newValue = [];
    const typedCustomTags =
      selectedCustomTags as ReturnTypeFromUseCalendarCustomTags[];

    const schoolCalendarMeta = [
      {
        tagProp: 'staffSchoolCalendar',
        permission: hasPermissionToAddToStaffPublicCalendar,
        userType: UserType.Teacher,
      },
      {
        tagProp: 'contactSchoolCalendar',
        permission: hasPermissionToAddToContactPublicCalendar,
        userType: UserType.Contact,
      },
      {
        tagProp: 'studentSchoolCalendar',
        permission: hasPermissionToAddToStudentPublicCalendar,
        userType: UserType.Student,
      },
    ] as const;

    for (const { tagProp, permission, userType } of schoolCalendarMeta) {
      if (permission) {
        const updatedValue =
          typedCustomTags?.some(({ [tagProp]: value }) => value) ?? false;
        newValue.push(updatedValue ? userType : null);
      }
    }

    setValue(
      'showOnPublicCalendarTypes',
      newValue.filter(Boolean) as SchoolCalendarFilterArray,
    );
  }, [selectedCustomTags]);

  const isEditing = !!initialState?.eventId;
  const publicCalendarOptions = getPublicCalendarOptions(permissions);

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle onClose={onClose}>
          {isEditing ? t('calendar:editEvent') : t('calendar:addEvent')}
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <Stack gap={2} pt={0.5}>
              <RHFTextField
                label={t('calendar:inputLabels.title')}
                controlProps={{
                  name: 'name',
                  control,
                }}
              />

              <ScheduleEvent
                control={control}
                isEditing={isEditing}
                setValue={setValue}
              />

              <RHFAutocomplete<FormState, CalendarParty, true>
                {...participantsProps}
                controlProps={{
                  name: 'participants',
                  control,
                }}
              />

              <RoomLocationOptions
                recurrenceFilter={recurrenceFilter}
                control={control}
              />

              <RHFAutocomplete
                label={t('calendar:tags')}
                options={customTags ?? []}
                loading={isCustomTagsLoading}
                optionIdKey="id"
                optionTextKey="tag"
                multiple
                controlProps={{
                  name: 'customTags',
                  control,
                }}
              />

              <RHFTextField
                label={t('calendar:inputLabels.description')}
                controlProps={{
                  name: 'description',
                  control,
                }}
                textFieldProps={{
                  multiline: true,
                  rows: 4,
                }}
              />

              {allowAddToPublicCalendar && publicCalendarOptions.length > 0 && (
                <RHFCheckboxGroup
                  label={t('calendar:includeOnSchoolCalendarFor')}
                  controlProps={{ name: 'showOnPublicCalendarTypes', control }}
                  options={publicCalendarOptions}
                  getOptionLabel={(type) =>
                    t(`calendar:schoolCalendarUserTypes.${type}`)
                  }
                />
              )}

              <RHFColorPicker
                label={t('calendar:inputLabels.eventColor')}
                controlProps={{
                  name: 'colour',
                  control,
                }}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button variant="soft" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {isEditing ? t('common:actions.save') : t('common:actions.add')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmDialog
        open={isConfirmClashOpen}
        title={t('calendar:clashDetected')}
        confirmText={t('common:actions.continue')}
        content={
          <Stack gap={2}>
            <DialogContentText>
              {t('calendar:clashDetectedWouldYouLikeToContinue', {
                count: clashes.length,
              })}
            </DialogContentText>
            <Stack gap={1}>
              <Typography variant="subtitle2" color="text.secondary" mb={1}>
                {t('calendar:newEvent')}
              </Typography>
              <Typography variant="body2">
                {eventName}{' '}
                {[startTime, endTime]
                  .filter(Boolean)
                  .map((time) => time.format('HH:mm'))
                  .join(' - ')}
              </Typography>
              <TeachersDetails
                teacherNames={(participants || [])
                  .map((participant) => participant.text)
                  .join(' ,')}
              />
              <LocationsDetails
                roomNames={(locations || [])
                  .map((location) => location.name)
                  .join(', ')}
              />
            </Stack>
            <List
              aria-labelledby="list-title"
              subheader={
                <ListSubheader id="list-title" disableGutters>
                  {t('calendar:clashingEvent', { count: clashes.length })}
                </ListSubheader>
              }
            >
              {clashes.map(
                ({
                  eventId,
                  event,
                  startTime: clashStartTime,
                  endTime: clashEndTime,
                  clashingTeachers,
                  clashingRooms,
                }) => {
                  return (
                    <ListItem disableGutters key={eventId}>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                        primary={`${event} ${[clashStartTime, clashEndTime].filter(Boolean).join(' - ')}`}
                        secondary={
                          <Stack>
                            {clashingTeachers.length > 0 && (
                              <TeachersDetails
                                teacherNames={displayNames(
                                  clashingTeachers.map(({ person }) => person),
                                )}
                              />
                            )}
                            {clashingRooms.length > 0 && (
                              <LocationsDetails
                                roomNames={clashingRooms
                                  .map(({ name }) => name)
                                  .join(', ')}
                              />
                            )}
                          </Stack>
                        }
                      />
                    </ListItem>
                  );
                },
              )}
            </List>
          </Stack>
        }
        onClose={closeConfirmClash}
        onConfirm={() => {
          setValue('allowClashes', true);
          return onSubmit();
        }}
      />
    </>
  );
};
