import { useMemo } from 'react';

interface FontStylingOptions {
  textColor: boolean;
  highlight: boolean;
}

enum TemplateType {
  EMAIL = 'email',
  SMS = 'sms',
  LETTER = 'letter',
}

export const getFontStylingOptions = (
  templateType?: string | null,
): FontStylingOptions => {
  return useMemo(() => {
    switch (templateType) {
      case TemplateType.LETTER:
        return { textColor: true, highlight: true };
      case TemplateType.EMAIL:
        return { textColor: false, highlight: false };
      case TemplateType.SMS:
        return { textColor: false, highlight: false };
      default:
        return { textColor: false, highlight: false };
    }
  }, [templateType]);
};
