export const replaceVariablesWithPlaceholder = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const customNodes = doc.querySelectorAll('span[data-custom-node]');

  // When calculating SMS character count with variables, the variable is being replaced
  // below with a 15-character placeholder
  //  This helps estimate the possible length of the SMS
  //  when variables are replaced with actual values
  const placeholderText = 'abcdefgehijklmn';
  // biome-ignore lint/complexity/noForEach: <explanation>
  customNodes.forEach((node) => {
    node.replaceWith(`${placeholderText}`);
  });

  return doc.body.textContent || '';
};
