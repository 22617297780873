import { InputAdornment } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { Autocomplete } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { SearchIcon } from '@tyro/icons';
import type { ReturnTypeFromUseVariablesQuery } from '@tyro/templates';

type VariablesAutocompleteProps = {
  setIsVariableSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editor: Editor | null;
  variablesData: VariablesData;
};

type ChipValueTypes = {
  label: string;
  value: string;
};

export type VariablesData = {
  variables?: ReturnTypeFromUseVariablesQuery[];
  isLoading: boolean;
};

export function VariablesAutocomplete({
  setIsVariableSearchOpen,
  editor,
  variablesData: { variables, isLoading },
}: VariablesAutocompleteProps) {
  const { t } = useTranslation(['common']);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleVariableSelect = (_event: any, selectedVariable: any) => {
    if (selectedVariable?.value && editor) {
      editor.commands.insertContent(`{{${selectedVariable.value}}}`);
      setIsVariableSearchOpen(false);
    }
  };

  const handleChipInsertion = ({ label, value }: ChipValueTypes) => {
    editor?.chain().focus().addVariable({ label, value }).run();
    setIsVariableSearchOpen(false);
  };

  return (
    <Autocomplete
      fullWidth
      optionIdKey="id"
      optionTextKey={'name'}
      loading={isLoading}
      options={variables ?? []}
      onChange={handleVariableSelect}
      renderOption={(props, option) => (
        <li
          {...props}
          onClick={(event) => {
            event.stopPropagation();
            handleChipInsertion({
              label: option?.name ?? t('common:default'),
              value: option?.value ?? t('common:defaultLabelValue'),
            });
          }}
        >
          {option.name}
        </li>
      )}
      ChipProps={{ size: 'small' }}
      inputProps={{
        sx: {
          '& .MuiInputBase-root': {
            ml: 1,
            backgroundColor: 'background.paper',
            border: 'none',
            height: '40px',
            display: '-webkit-inline-box',
            pl: 0,
          },
          '& fieldset': {
            border: 'none',
          },
          '& .MuiInputAdornment-root': {
            marginRight: 0,
            marginLeft: 1,
          },
        },
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
