import { useQuery } from '@tanstack/react-query';
import {
  type FormsAdmin_InformationRequestTableFilter,
  type FormsFd_FormDefinitionFieldSelectOptions,
  type Forms_InformationRequestResponseTableColumn,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { formsKeys } from './keys';

const infoRequestTableView = graphql(/* GraphQL */ `
  query formsAdmin_informationRequestTableView($filter: FormsAdmin_InformationRequestTableFilter!) {
    formsAdmin_informationRequestTableView(filter: $filter) {
      id {
        id
        provider
      }
      informationRequest {
        name
        informationRequestType
        isConfirmable
      }
      headers {
        id
        label
        type
      }
      rows {
        aboutParty {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
        responderParty {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
        completionStatus
        confirmationStatus
        columns {
          id
          value
        }
      }
      form {
        fields {
          id
          fields {
            id {
              formId
              fieldGroupId
              fieldId
            }
            selectInfo {
              options {
                id
                value
              }
            }
          }
        }
      }
    }
  }
`);

const infoRequestTableViewQuery = (
  filter: FormsAdmin_InformationRequestTableFilter,
) => ({
  queryKey: formsKeys.infoRequestTableView(filter),
  queryFn: async () => {
    const { formsAdmin_informationRequestTableView: tableViewData } =
      await gqlClient.request(infoRequestTableView, { filter });

    return {
      ...tableViewData,
      headers: tableViewData?.headers,
      formOptions: (tableViewData?.form.fields || [])
        .flatMap((field) => field.fields)
        .reduce(
          (acc, field) => {
            if (field?.selectInfo?.options) {
              acc[
                `${field.id.formId}:${field.id.fieldGroupId}:${field.id.fieldId}`
              ] = field.selectInfo?.options?.reduce(
                (accOptions, option) => {
                  if (option.id) {
                    accOptions[option.id] = option.value;
                  }

                  return accOptions;
                },
                {} as Record<
                  string,
                  FormsFd_FormDefinitionFieldSelectOptions['value']
                >,
              );
            }

            return acc;
          },
          {} as Record<
            string,
            Record<string, FormsFd_FormDefinitionFieldSelectOptions['value']>
          >,
        ),
      rows: (tableViewData?.rows || []).map((row) => ({
        ...row,
        columns: row.columns.reduce(
          (acc, column) => {
            if (column.id) {
              acc[column.id] = column;
            }

            return acc;
          },
          {} as Record<string, Forms_InformationRequestResponseTableColumn>,
        ),
      })),
    };
  },
});

export function useInfoRequestTableView(
  filter: FormsAdmin_InformationRequestTableFilter,
) {
  return useQuery({
    ...infoRequestTableViewQuery(filter),
    select: (formsAdmin_informationRequestTableView) =>
      formsAdmin_informationRequestTableView,
  });
}

export function getInfoRequestTableView(
  filter: FormsAdmin_InformationRequestTableFilter,
) {
  return queryClient.fetchQuery(infoRequestTableViewQuery(filter));
}

export type ReturnTypeFromUseInfoRequestTableView = UseQueryReturnType<
  typeof useInfoRequestTableView
>;
