import type { Editor } from '@tiptap/react';
import { handleDrop } from '@tyro/core';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { EditorTextField, type EditorTextFieldProps } from '../text-editor';

export type RHFEditorTextFieldProps<TField extends FieldValues> = {
  label?: string;
  variant?: EditorTextFieldProps['variant'];
  textFieldProps?: EditorTextFieldProps;
  controlProps: UseControllerProps<TField>;
  setEditor?: (editor: Editor | null) => void;
  editor?: Editor | null;
};

export const RHFEditorTextField = <TField extends FieldValues>({
  label,
  variant,
  textFieldProps,
  controlProps,
  setEditor,
  editor,
}: RHFEditorTextFieldProps<TField>) => {
  const {
    field: { ref, value, onChange, ...restField },
    fieldState: { error },
  } = useController(controlProps);

  return (
    <EditorTextField
      {...textFieldProps}
      {...restField}
      onChange={(value) => {
        onChange(value);
        textFieldProps?.onChange?.(value);
      }}
      variant={variant}
      value={value ?? ''}
      label={label}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      setEditor={setEditor}
      onDrop={(e) => {
        if (editor) handleDrop(e, editor);
      }}
    />
  );
};
