import {
  Badge,
  Button,
  Drawer,
  Fade,
  Stack,
  Typography,
  alpha,
} from '@mui/material';
import { NotificationType, queryClient, useUser } from '@tyro/api';
import { Scrollbar, useDisclosure, useToast } from '@tyro/core';

import { useTranslation } from '@tyro/i18n';
import { BellIcon, DoubleCheckmarkIcon } from '@tyro/icons';
import { mailKeys } from '@tyro/mail';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shellKeys } from '../../../api/keys';
import {
  type ReturnTypeFromNotifications,
  getNotifications,
  useNotifications,
} from '../../../api/notifications/list';
import { useMarkNotificationRead } from '../../../api/notifications/read-notification';
import { useNotificationsUnreadCount } from '../../../api/notifications/unread-count';
import { useSwitchProfile } from '../../../api/switch-profile';
import { useNotificationLinks } from '../../../hooks/use-notification-links';
import { IconButtonAnimate } from '../../icon-button-animate';
import { VirtualizedNotificationsList } from './virtualized-notifications-list';

dayjs.extend(relativeTime);

export function NotificationsPopover() {
  const latestNotificationRef = useRef<
    ReturnTypeFromNotifications | 'empty' | null
  >(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, activeProfile } = useUser();
  const { t } = useTranslation(['common', 'calendar']);
  const { toast, closeAllToasts } = useToast();

  const { data: unreadCount } = useNotificationsUnreadCount();
  const {
    data: notificationsPage,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useNotifications({
    globalUserId: user?.id ?? 0,
  });

  const { mutateAsync: markNotificationRead } = useMarkNotificationRead();
  const { mutateAsync: switchProfile } = useSwitchProfile();

  const notifications = useMemo(() => {
    if (!notificationsPage?.pages) {
      return [];
    }
    return notificationsPage.pages;
  }, [notificationsPage?.pages]);

  const notificationUrls = useNotificationLinks(notifications);

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const showMarkAllAsRead = useMemo(
    () =>
      notifications?.some(
        (notification) => notification.recipients?.[0].readOn === undefined,
      ) ?? false,
    [notifications],
  );

  const handleMarkAllAsRead = useCallback(async () => {
    const notificationIdsToMarkAsRead =
      notifications
        ?.filter(({ recipients }) => recipients?.[0].readOn === undefined)
        .map(({ id }) => id) ?? [];

    if (notificationIdsToMarkAsRead.length === 0) return;

    markNotificationRead(
      { notificationIds: notificationIdsToMarkAsRead },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: shellKeys.notifications.all(),
          });
        },
        onError: () => {
          toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
        },
      },
    );
  }, []);

  const checkLatestNotification = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: shellKeys.notifications.list({
        globalUserId: user?.id ?? 0,
      }),
    });
    const { communications_notifications: latestNotifications } =
      await getNotifications({
        globalUserId: user?.id ?? 0,
      });
    const sortedLatestNotifications = latestNotifications.sort(
      (a, b) => dayjs(b.sentOn).unix() - dayjs(a.sentOn).unix(),
    );

    let count = 0;
    const [firstNotification] = sortedLatestNotifications;
    const latestRef = latestNotificationRef.current;

    if (latestRef !== 'empty' && firstNotification?.id === latestRef?.id)
      return;

    if (latestRef === 'empty') {
      count = sortedLatestNotifications.reduce(
        (acc, notification) =>
          notification.recipients?.[0].readOn === undefined ? acc + 1 : acc,
        0,
      );
    } else if (latestRef !== null) {
      count = sortedLatestNotifications.reduce(
        (acc, notification) =>
          notification.recipients?.[0].readOn === undefined &&
          dayjs(notification.sentOn).isAfter(dayjs(latestRef.sentOn))
            ? acc + 1
            : acc,
        0,
      );
    }

    if (count > 0) {
      toast(t('xNewNotifications', { count }), {
        action: (
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              closeAllToasts();
              onOpen();
            }}
          >
            {t('common:actions.view')}
          </Button>
        ),
      });
    }
    latestNotificationRef.current = firstNotification;
  }, []);

  const onNotificationClick = useCallback(
    async (e: React.MouseEvent, notification: ReturnTypeFromNotifications) => {
      const recipient = notification.recipients?.[0];
      const isUnread = !recipient?.readOn;
      const isActiveProfile =
        activeProfile?.partyId === recipient?.recipientPartyId;

      if (isUnread) {
        await markNotificationRead(
          { notificationIds: [notification.id] },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: shellKeys.notifications.all(),
              });
              if (notification.notificationType === NotificationType.Mail) {
                queryClient.invalidateQueries({
                  queryKey: mailKeys.unreadCounts(),
                });
                queryClient.invalidateQueries({ queryKey: mailKeys.lists() });
              }
            },
          },
        );
      }

      if (!isActiveProfile) {
        e.preventDefault();
        const notificationUrl = notificationUrls.get(notification.id);
        const requestedProfile = user?.profiles?.find(
          (profile) => profile.partyId === recipient?.recipientPartyId,
        );

        if (requestedProfile && notificationUrl) {
          e.preventDefault();
          await switchProfile(
            {
              globalUserId: user!.id!,
              requestedProfileId: requestedProfile.id,
              partyId: requestedProfile.partyId!,
              tenant: requestedProfile.tenantId!,
            },
            {
              onSuccess: () => {
                window.location.href = notificationUrl;
              },
              onError: () => {
                toast(t('common:failedToSwitchProfile'), { variant: 'error' });
              },
            },
          );
        }
      }

      onClose();
    },
    [],
  );

  useEffect(() => {
    if (unreadCount === 0 || latestNotificationRef.current === null) return;

    checkLatestNotification();
  }, [unreadCount]);

  useEffect(() => {
    if (latestNotificationRef.current === null && notifications !== undefined) {
      const sortedNotifications = notifications.sort(
        (a, b) => dayjs(b.sentOn).unix() - dayjs(a.sentOn).unix(),
      );
      latestNotificationRef.current = sortedNotifications?.[0] ?? 'empty';
    }
  }, [notifications]);

  return (
    <>
      <IconButtonAnimate
        color={isOpen ? 'primary' : 'default'}
        onClick={onOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge
          badgeContent={unreadCount}
          max={99}
          color="error"
          sx={{
            '& .MuiBadge-badge': { minWidth: 16, height: 16, padding: '0 4px' },
          }}
          overlap="circular"
        >
          <BellIcon />
        </Badge>
      </IconButtonAnimate>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        ModalProps={{
          sx: {
            '& .MuiBackdrop-root': {
              opacity: '0 !important',
            },
          },
        }}
        PaperProps={{
          sx: ({ palette }) => ({
            width: '100%',
            maxWidth: 320,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(20px)',
            borderRadius: '24px 0 0 24px',
            border: '1px solid',
            borderRight: 'none',
            borderColor: 'indigo.50',
            boxShadow: `0px 4px 74px ${alpha(
              palette.indigo[300],
              0.3,
            )} !important`,
          }),
        }}
      >
        <Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              p: 1,
              pl: 2,
              borderBottom: 'solid 1px',
              borderBottomColor: 'divider',
              backgroundColor: 'background.paper',
              position: 'sticky',
              top: 0,
              zIndex: 2,
            }}
          >
            <Typography component="h2" variant="h6">
              {t('common:notifications')}
            </Typography>
            <Fade in={showMarkAllAsRead}>
              <Button
                size="small"
                color="inherit"
                onClick={handleMarkAllAsRead}
                endIcon={<DoubleCheckmarkIcon color="success" />}
                sx={{
                  color: 'text.secondary',
                }}
              >
                {t('common:markAllRead')}
              </Button>
            </Fade>
          </Stack>
          {/* IMPORTANT: Virtualizer needs a scrollable container with a fixed height.
          /* Without `height: 100vh`, `getScrollElement()` will return a container with 0 height
          /* causing all virtualized items to render at once (breaking virtualization). */}
          <Scrollbar ref={setContainerRef} style={{ height: '100vh' }}>
            <VirtualizedNotificationsList
              containerRef={containerRef}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              notifications={notifications || []}
              onNotificationClick={onNotificationClick}
            />
          </Scrollbar>
        </Stack>
      </Drawer>
    </>
  );
}
