import { useQuery } from '@tanstack/react-query';
import {
  type FormsAdmin_FormDefinitionViewFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { formsKeys } from './keys';

const previewFormDefinition = graphql(/* GraphQL */ `
  query formsAdmin_blankForm($filter: FormsAdmin_FormDefinitionViewFilter) {
    formsAdmin_blankForm(filter: $filter) {
      id {
        name
        provider
        forPartyId
        objectId
      }
      title
      description
      allowPartialCompletion
      gridWidth {
        xs
        sm
        md
        lg
        xl
      }
      readOnly
      fields {
        header
        fields {
          __typename
          ... on Forms_FormFieldSubGroup {
            formFieldType
            header
            fields {
              formFieldType
              id
              label
              type
              required
              options {
                id
                name
              }
              gridWidth {
                xs
                sm
                md
                lg
                xl
              }
            }
            gridWidth {
              xs
              sm
              md
              lg
              xl
            }
          }
          ... on Forms_FormFieldItem {
            formFieldType
            id
            label
            type
            required
            options {
              id
              name
            }
            gridWidth {
              xs
              sm
              md
              lg
              xl
            }
            defaultValue
          }
        }
      }
    }
  }
`);

const previewFormDefinitionQuery = (
  filter: FormsAdmin_FormDefinitionViewFilter,
) => ({
  queryKey: formsKeys.previewFormDefinition(filter),
  queryFn: () => gqlClient.request(previewFormDefinition, { filter }),
});

export function usePreviewFormDefinition(
  filter: FormsAdmin_FormDefinitionViewFilter,
) {
  return useQuery({
    ...previewFormDefinitionQuery(filter),
    select: ({ formsAdmin_blankForm }) => formsAdmin_blankForm,
  });
}

export function getPreviewFormDefinition(
  filter: FormsAdmin_FormDefinitionViewFilter,
) {
  return queryClient.fetchQuery(previewFormDefinitionQuery(filter));
}

export type ReturnTypeFromUsePreviewFormDefinition = UseQueryReturnType<
  typeof usePreviewFormDefinition
>;
