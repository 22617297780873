import type { ButtonProps } from '@mui/material';
import { usePermissions } from '@tyro/api';
import { useDebouncedValue, useDisclosure } from '@tyro/core';
import {
  type MouseEventHandler,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { getStudentsForSelect } from '../api/student/students';
import {
  UpsertBehaviourModal,
  type UpsertBehaviourModalProps,
} from '../components/behaviour/upsert-behaviour-modal';
import {
  UploadPartyPhotoModal,
  type UploadPartyPhotoModalProps,
} from '../components/common/upload-party-photo-modal';
import { NeedToKnowModal } from '../components/students/need-to-know-modal';
import {
  UpsertNoteModal,
  type UpsertNoteModalProps,
} from '../components/students/note/upsert-note-modal';

export type NeedToKnowGroup = {
  partyId: number;
  name: string;
};

export type StudentActionsContextValue = {
  getNeedToKnowButtonProps: (
    studentPartyId: number,
    group?: NeedToKnowGroup,
  ) => ButtonProps;
  openNeedToKnowForStudent: (
    studentPartyId: number,
    group?: NeedToKnowGroup,
  ) => void;
  getCreateNoteButtonProps: (
    studentPartyId: number,
    additionalOptions?: AdditionalActionOptions,
  ) => ButtonProps;
  getEditNoteButtonProps: (
    studentPartyId: number,
    initialState: UpsertNoteModalProps['initialState'],
    additionalOptions?: AdditionalActionOptions,
  ) => ButtonProps;
  getCreateBehaviourButtonProps: (
    defaultState: {
      defaultStudentPartyIds?: number[];
      associatedGroups?: NeedToKnowGroup[];
    },
    additionalOptions?: AdditionalActionOptions,
  ) => ButtonProps;
  getEditBehaviourButtonProps: (
    behaviourId: number,
    additionalOptions?: AdditionalActionOptions,
  ) => ButtonProps;
  getAvatarUploadButtonProps: (
    uploadSettings: Pick<UploadPartyPhotoModalProps, 'partyId' | 'uploadType'>,
    additionalOptions?: AdditionalActionOptions,
  ) => ButtonProps;
};

const blankContextValue: StudentActionsContextValue = {
  getNeedToKnowButtonProps: () => ({}),
  openNeedToKnowForStudent: () => {},
  getCreateNoteButtonProps: () => ({}),
  getEditNoteButtonProps: () => ({}),
  getCreateBehaviourButtonProps: () => ({}),
  getEditBehaviourButtonProps: () => ({}),
  getAvatarUploadButtonProps: () => ({}),
};

export const StudentActionsContext = createContext<
  StudentActionsContextValue | undefined
>(undefined);

type StudentActionsProps = {
  children: ReactNode;
};

type AdditionalActionOptions = {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  onClose?: () => void;
};

export function StudentActionsProvider({ children }: StudentActionsProps) {
  const { isStaffUser } = usePermissions();
  const {
    isOpen: isNeedToKnowOpen,
    onOpen: openNeedToKnow,
    getButtonProps: getNeedToKnowButtonProps,
    getDisclosureProps: needToKnowDisclosureProps,
  } = useDisclosure();
  const {
    getButtonProps: getNoteButtonProps,
    getDisclosureProps: noteDisclosureProps,
  } = useDisclosure();
  const {
    getButtonProps: getBehaviourButtonProps,
    getDisclosureProps: behaviourDisclosureProps,
  } = useDisclosure();
  const {
    getButtonProps: getAvatarUploadButtonProps,
    getDisclosureProps: avatarUploadDisclosureProps,
  } = useDisclosure();

  const {
    value: ntkStudentPartySettings,
    debouncedValue: ntkStudentPartySettingsDebounce,
    setValue: setNtkStudentPartySettings,
  } = useDebouncedValue<
    { partyId: number; group?: NeedToKnowGroup } | undefined
  >({
    defaultValue: undefined,
  });
  const {
    value: noteMeta,
    debouncedValue: debouncedNoteMeta,
    setValue: setNoteMeta,
  } = useDebouncedValue<
    | {
        studentPartyId: number;
        initialState: UpsertNoteModalProps['initialState'];
        additionalOptions?: AdditionalActionOptions;
      }
    | undefined
  >({ defaultValue: undefined });
  const {
    value: behaviourMeta,
    debouncedValue: debouncedBehaviourMeta,
    setValue: setBehaviourMeta,
  } = useDebouncedValue<
    | (Pick<UpsertBehaviourModalProps, 'behaviourId' | 'defaultState'> & {
        additionalOptions?: AdditionalActionOptions;
      })
    | undefined
  >({ defaultValue: undefined });
  const {
    value: uploadSettings,
    debouncedValue: debouncedUploadSettings,
    setValue: setUploadSettings,
  } = useDebouncedValue<
    | (Pick<UploadPartyPhotoModalProps, 'partyId' | 'uploadType'> & {
        additionalOptions?: AdditionalActionOptions;
      })
    | undefined
  >({ defaultValue: undefined });

  const value = useMemo<StudentActionsContextValue>(
    () => ({
      getNeedToKnowButtonProps: (studentPartyId, group) => {
        if (isStaffUser) {
          return getNeedToKnowButtonProps({
            onClick: () => {
              setNtkStudentPartySettings({
                partyId: studentPartyId,
                group,
              });
            },
          });
        }

        return {};
      },
      openNeedToKnowForStudent: (studentPartyId, group) => {
        setNtkStudentPartySettings({
          partyId: studentPartyId,
          group,
        });
        openNeedToKnow();
      },
      getCreateNoteButtonProps: (
        studentPartyId: number,
        additionalOptions?: AdditionalActionOptions,
      ) => {
        if (isStaffUser) {
          return getNoteButtonProps({
            onClick: (...args) => {
              additionalOptions?.onClick?.(...args);
              setNoteMeta({
                studentPartyId,
                initialState: {},
                additionalOptions,
              });
            },
          });
        }

        return {};
      },
      getEditNoteButtonProps: (
        studentPartyId: number,
        initialState: UpsertNoteModalProps['initialState'],
        additionalOptions?: AdditionalActionOptions,
      ) => {
        if (isStaffUser) {
          return getNoteButtonProps({
            onClick: (...args) => {
              additionalOptions?.onClick?.(...args);
              setNoteMeta({ studentPartyId, initialState, additionalOptions });
            },
          });
        }

        return {};
      },
      getCreateBehaviourButtonProps: (
        { defaultStudentPartyIds, associatedGroups },
        additionalOptions?: AdditionalActionOptions,
      ) => {
        if (isStaffUser) {
          return getBehaviourButtonProps({
            onClick: async (...args) => {
              const [{ core_students: students }] = await Promise.all([
                defaultStudentPartyIds && defaultStudentPartyIds.length > 0
                  ? await getStudentsForSelect({
                      partyIds: defaultStudentPartyIds,
                    })
                  : { core_students: [] },
              ]);

              additionalOptions?.onClick?.(...args);

              setBehaviourMeta({
                defaultState: {
                  students,
                  associatedGroups: associatedGroups?.map(
                    ({ partyId, name }) => ({
                      partyId,
                      text: name,
                    }),
                  ),
                },
                additionalOptions,
              });
            },
          });
        }

        return {};
      },
      getEditBehaviourButtonProps: (
        behaviourId: number,
        additionalOptions?: AdditionalActionOptions,
      ) => {
        if (isStaffUser) {
          return getBehaviourButtonProps({
            onClick: (...args) => {
              additionalOptions?.onClick?.(...args);
              setBehaviourMeta({ behaviourId, additionalOptions });
            },
          });
        }

        return {};
      },
      getAvatarUploadButtonProps: (
        uploadSettings: Pick<
          UploadPartyPhotoModalProps,
          'partyId' | 'uploadType'
        >,
        additionalOptions?: AdditionalActionOptions,
      ) => {
        if (isStaffUser) {
          return getAvatarUploadButtonProps({
            onClick: (...args) => {
              additionalOptions?.onClick?.(...args);
              setUploadSettings({ ...uploadSettings, additionalOptions });
            },
          });
        }

        return {};
      },
    }),
    [],
  );

  useEffect(() => {
    if (!isNeedToKnowOpen) {
      setNtkStudentPartySettings(undefined);
    }
  }, [isNeedToKnowOpen]);

  const resolvedStudentPartySettings =
    ntkStudentPartySettings ?? ntkStudentPartySettingsDebounce;
  const resolvedNoteMeta = noteMeta ?? debouncedNoteMeta;
  const resolvedBehaviourMeta = behaviourMeta ?? debouncedBehaviourMeta;
  const resolvedUploadSettings = uploadSettings ?? debouncedUploadSettings;

  return (
    <>
      <StudentActionsContext.Provider value={value}>
        {children}
        {isStaffUser && (
          <>
            <NeedToKnowModal
              studentPartySettings={resolvedStudentPartySettings}
              {...needToKnowDisclosureProps()}
            />
            <UpsertNoteModal
              studentId={resolvedNoteMeta?.studentPartyId}
              initialState={resolvedNoteMeta?.initialState ?? null}
              {...noteDisclosureProps({
                onClose: () => {
                  setNoteMeta(undefined);
                  resolvedNoteMeta?.additionalOptions?.onClose?.();
                },
              })}
            />
            <UpsertBehaviourModal
              {...behaviourDisclosureProps({
                onClose: () => {
                  setBehaviourMeta(undefined);
                  resolvedBehaviourMeta?.additionalOptions?.onClose?.();
                },
              })}
              defaultState={resolvedBehaviourMeta?.defaultState}
              behaviourId={resolvedBehaviourMeta?.behaviourId}
            />
            <UploadPartyPhotoModal
              {...avatarUploadDisclosureProps({
                onClose: () => {
                  setUploadSettings(undefined);
                  resolvedUploadSettings?.additionalOptions?.onClose?.();
                },
              })}
              partyId={resolvedUploadSettings?.partyId}
              uploadType={resolvedUploadSettings?.uploadType}
            />
          </>
        )}
      </StudentActionsContext.Provider>
    </>
  );
}

export function useStudentActions() {
  const context = useContext(StudentActionsContext);
  if (!context) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        'useStudentActions must be used within a StudentActionsProvider. But if this is when you are exporting the component to HTML, then it is fine.',
      );
    }
    return blankContextValue;
  }

  return context ?? blankContextValue;
}
