import { Chip, Stack } from '@mui/material';
import {
  type FeeFilter,
  getColorBasedOnIndex,
  usePermissions,
} from '@tyro/api';
import {
  ActionMenu,
  type BreadcrumbsProps,
  type GridOptions,
  type ICellRendererParams,
  type ReturnTypeDisplayName,
  RouterLink,
  Table,
  TableBooleanValue,
  TablePersonAvatar,
  type TableProps,
  commonActionMenuProps,
  useDebouncedValue,
  usePreferredNameLayout,
} from '@tyro/core';
import { type TFunction, useFormatNumber, useTranslation } from '@tyro/i18n';
import {
  CheckmarkCircleIcon,
  EditIcon,
  ReplyIcon,
  StopIcon,
  TrashIcon,
  VerticalDotsIcon,
} from '@tyro/icons';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { type Dispatch, type SetStateAction, useMemo } from 'react';
import { type ReturnTypeFromUseFees, useFees } from '../../api/fees';
import { ConfirmMigrateFeeModal } from '../fees/confirm-migrate-fee-modal';
import { DeleteFeeConfirmModal } from '../fees/delete-fee-confirm-modal';
import { PublishFeeConfirmModal } from '../fees/publish-fee-confirm-modal';
import { FeeStatusChip } from './fee-status-chip';

dayjs.extend(LocalizedFormat);

type LinkState = {
  from?: string;
  breadCrumbs?: BreadcrumbsProps['links'];
};

const getColumnDefs = (
  t: TFunction<('fees' | 'common')[]>,
  displayName: ReturnTypeDisplayName,
  formatCurrency: ReturnType<typeof useFormatNumber>['formatCurrency'],
  hasPermission: boolean,
  onDeleteClick: Dispatch<SetStateAction<ReturnTypeFromUseFees | null>>,
  onPublishClick: Dispatch<SetStateAction<ReturnTypeFromUseFees | null>>,
  onMigrateClick: Dispatch<SetStateAction<ReturnTypeFromUseFees | null>>,
  linkState?: LinkState,
): GridOptions<ReturnTypeFromUseFees>['columnDefs'] => [
  {
    field: 'name',
    headerName: t('common:name'),
    cellRenderer: ({ data }: ICellRendererParams<ReturnTypeFromUseFees>) =>
      data && (
        <RouterLink
          to={`/fees/view/${data.id || ''}/overview`}
          state={linkState}
        >
          {data.name}
        </RouterLink>
      ),
    pinned: 'left',
  },
  {
    field: 'amount',
    headerName: t('fees:amount'),
    valueFormatter: ({ value }) => formatCurrency(value ?? 0),
    comparator: (a: number, b: number) => a - b,
    aggFunc: 'sum',
    type: 'numericColumn',
  },
  {
    field: 'categories',
    headerName: t('common:category'),
    valueGetter: ({ data }) => data?.categories?.map(({ name }) => name),
    cellRenderer: ({ data }: ICellRendererParams<ReturnTypeFromUseFees>) =>
      data?.categories ? (
        <Stack direction="row" gap={1} my={1} flexWrap="wrap">
          {data.categories.map(({ id, name }) => (
            <Chip
              key={id}
              size="small"
              label={name}
              variant="soft"
              color={getColorBasedOnIndex(id)}
            />
          ))}
        </Stack>
      ) : null,
    enableRowGroup: true,
  },
  {
    field: 'accountingCode',
    headerName: t('fees:generalLedgerCode'),
    valueGetter: ({ data }) => data?.accountingCode?.code ?? '-',
    cellRenderer: ({ data }: ICellRendererParams<ReturnTypeFromUseFees>) => {
      const { accountingCode } = data || {};
      if (!accountingCode) return '-';

      return (
        <Chip
          key={accountingCode.id}
          size="small"
          label={accountingCode.code}
          variant="soft"
          color={getColorBasedOnIndex(accountingCode.id)}
        />
      );
    },
    enableRowGroup: true,
  },
  {
    field: 'feeType',
    headerName: t('common:type'),
    valueGetter: ({ data }) =>
      data?.feeType ? t(`fees:feesType.${data?.feeType}`) : '-',
    enableRowGroup: true,
  },
  {
    field: 'dueDate',
    headerName: t('fees:dueBy'),
    valueFormatter: ({ data }) =>
      data?.dueDate ? dayjs(data.dueDate).format('LL') : '-',
    sort: 'asc',
    comparator: (dateA: string, dateB: string) =>
      dayjs(dateA).unix() - dayjs(dateB).unix(),
  },
  {
    field: 'total',
    headerName: t('common:total'),
    valueFormatter: ({ value }) => formatCurrency(value ?? 0),
    comparator: (a: number, b: number) => a - b,
    aggFunc: 'sum',
    type: 'numericColumn',
  },
  {
    field: 'paid',
    headerName: t('common:paid'),
    valueFormatter: ({ value }) => formatCurrency(value ?? 0),
    comparator: (a: number, b: number) => a - b,
    aggFunc: 'sum',
    type: 'numericColumn',
  },
  {
    field: 'writtenOff',
    headerName: t('fees:writtenOff'),
    valueFormatter: ({ value }) => formatCurrency(value ?? 0),
    comparator: (a: number, b: number) => a - b,
    aggFunc: 'sum',
    type: 'numericColumn',
  },
  {
    field: 'due',
    headerName: t('fees:due'),
    valueFormatter: ({ value }) => formatCurrency(value ?? 0),
    comparator: (a: number, b: number) => a - b,
    aggFunc: 'sum',
    type: 'numericColumn',
  },
  {
    field: 'feeStatus',
    headerName: t('common:status'),
    valueGetter: ({ data }) =>
      data?.feeStatus ? t(`fees:status.${data.feeStatus}`) : '-',
    cellRenderer: ({ data }: ICellRendererParams<ReturnTypeFromUseFees>) =>
      data?.feeStatus ? <FeeStatusChip status={data.feeStatus} /> : '-',
    sort: 'asc',
    enableRowGroup: true,
    sortIndex: 0,
  },
  {
    field: 'allowPartialPayments',
    headerName: t('fees:allowPartialPayments'),
    cellRenderer: ({ data }: ICellRendererParams<ReturnTypeFromUseFees>) => (
      <TableBooleanValue value={!!data?.allowPartialPayments} />
    ),
  },
  {
    field: 'published',
    headerName: t('common:published'),
    cellRenderer: ({ data }: ICellRendererParams<ReturnTypeFromUseFees>) => (
      <TableBooleanValue value={!!data?.published} />
    ),
  },
  {
    field: 'createdBy',
    headerName: t('common:createdBy'),
    valueGetter: ({ data }) => displayName(data?.createdBy),
    cellRenderer: ({ data }: ICellRendererParams<ReturnTypeFromUseFees>) =>
      data?.createdBy ? <TablePersonAvatar person={data?.createdBy} /> : '-',
    enableRowGroup: true,
  },
  {
    ...commonActionMenuProps,
    cellRenderer: ({ data }: ICellRendererParams<ReturnTypeFromUseFees>) =>
      data &&
      hasPermission && (
        <ActionMenu
          iconOnly
          buttonIcon={<VerticalDotsIcon />}
          menuItems={[
            {
              label: t('common:actions.edit'),
              icon: <EditIcon />,
              navigateTo: `/fees/edit/${data.id || ''}`,
              state: linkState,
            },
            ...(data.published
              ? [
                  {
                    label: t('common:actions.unpublish'),
                    icon: <StopIcon />,
                    onClick: () => onPublishClick(data),
                  },
                ]
              : [
                  {
                    label: t('common:actions.publish'),
                    icon: <CheckmarkCircleIcon />,
                    onClick: () => onPublishClick(data),
                  },
                ]),
            {
              label: t('fees:migrateToNextYear'),
              icon: <ReplyIcon sx={{ transform: 'scaleX(-1)' }} />,
              onClick: () => {
                onMigrateClick(data);
              },
            },
            {
              label: t('common:actions.delete'),
              icon: <TrashIcon />,
              isDelete: true,
              disabled: data.paid > 0,
              disabledTooltip: t('fees:cantDeleteFeeWithPayments'),
              onClick: () => {
                onDeleteClick(data);
              },
            },
          ]}
        />
      ),
  },
];

export interface FeeListTableProps {
  filter?: FeeFilter;
  tableProps?: Partial<TableProps<ReturnTypeFromUseFees>>;
  linkState?: LinkState;
}

export function FeeListTable({
  filter,
  tableProps,
  linkState,
}: FeeListTableProps) {
  const { t } = useTranslation(['fees']);
  const { displayName } = usePreferredNameLayout();
  const { formatCurrency } = useFormatNumber();

  const { data: feesData } = useFees(filter ?? {});

  const { isStaffUserWithPermission } = usePermissions();
  const hasPermission = isStaffUserWithPermission('ps:1:fees:write_fees');

  const {
    value: feeToDelete,
    debouncedValue: debouncedFeeToDelete,
    setValue: setFeeToDelete,
  } = useDebouncedValue<ReturnTypeFromUseFees | null>({ defaultValue: null });

  const {
    value: feeToPublish,
    debouncedValue: debouncedFeeToPublish,
    setValue: setFeeToPublish,
  } = useDebouncedValue<ReturnTypeFromUseFees | null>({ defaultValue: null });

  const {
    value: feeToMigrate,
    debouncedValue: debouncedFeeToMigrate,
    setValue: setFeeToMigrate,
  } = useDebouncedValue<ReturnTypeFromUseFees | null>({ defaultValue: null });

  const columnDefs = useMemo(
    () =>
      getColumnDefs(
        t,
        displayName,
        formatCurrency,
        hasPermission,
        setFeeToDelete,
        setFeeToPublish,
        setFeeToMigrate,
        linkState,
      ),
    [
      t,
      displayName,
      formatCurrency,
      hasPermission,
      setFeeToDelete,
      setFeeToPublish,
      linkState,
    ],
  );

  return (
    <>
      <Table
        rowData={feesData || []}
        columnDefs={columnDefs}
        getRowId={({ data }) => String(data?.id)}
        statusBar={{
          statusPanels: [
            {
              statusPanel: 'tyroAggStatusPanel',
              statusPanelParams: {
                columns: [
                  {
                    id: 'total',
                    aggregationTypes: ['sum'],
                    labelOverrides: {
                      sum: {
                        selected: t('fees:totalSelected'),
                        total: t('fees:total'),
                      },
                    },
                  },
                  {
                    id: 'paid',
                    aggregationTypes: ['sum'],
                    labelOverrides: {
                      sum: {
                        selected: t('fees:totalSelectedPaid'),
                        total: t('fees:totalPaid'),
                      },
                    },
                  },
                  {
                    id: 'writtenOff',
                    aggregationTypes: ['sum'],
                    labelOverrides: {
                      sum: {
                        selected: t('fees:totalSelectedWrittenOff'),
                        total: t('fees:totalWrittenOff'),
                      },
                    },
                  },
                  {
                    id: 'due',
                    aggregationTypes: ['sum'],
                    labelOverrides: {
                      sum: {
                        selected: t('fees:totalSelectedDue'),
                        total: t('fees:totalDue'),
                      },
                    },
                  },
                ],
              },
            },
          ],
        }}
        {...tableProps}
      />
      <DeleteFeeConfirmModal
        open={!!feeToDelete}
        feeToDelete={feeToDelete || debouncedFeeToDelete}
        onClose={() => setFeeToDelete(null)}
      />
      <PublishFeeConfirmModal
        open={!!feeToPublish}
        feeToPublish={feeToPublish || debouncedFeeToPublish}
        onClose={() => setFeeToPublish(null)}
      />
      <ConfirmMigrateFeeModal
        open={!!feeToMigrate}
        fee={feeToMigrate || debouncedFeeToMigrate}
        onClose={() => setFeeToMigrate(null)}
      />
    </>
  );
}
