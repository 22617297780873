import { useMutation } from '@tanstack/react-query';
import { AssessmentType, gqlClient, graphql, queryClient } from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { assessmentsKeys } from './keys';

const archiveClassAssessment = graphql(/* GraphQL */ `
  mutation assessment_archiveClassAssessment($input: Long!) {
    assessment_archiveClassAssessment(input: $input) {
      success
    }
  }
`);

const archiveStateCbaAssessment = graphql(/* GraphQL */ `
  mutation assessment_archiveStateCbaAssessment($input: Long!) {
    assessment_archiveStateCbaAssessment(input: $input) {
      success
    }
  }
`);

const archiveTermAssessment = graphql(/* GraphQL */ `
  mutation assessment_archiveTermAssessment($input: Long!) {
    assessment_archiveTermAssessment(input: $input) {
      success
    }
  }
`);

const getArchiveMutation = (type: AssessmentType) => {
  switch (type) {
    case AssessmentType.InClass:
      return archiveClassAssessment;
    case AssessmentType.StateCba:
      return archiveStateCbaAssessment;
    case AssessmentType.Term:
      return archiveTermAssessment;
  }
};

export function useArchiveAssessment() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: ({ id, type }: { id: number; type: AssessmentType }) => {
      const mutation = getArchiveMutation(type);
      return gqlClient.request(mutation, { input: id });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: assessmentsKeys.all });
      toast(t('common:snackbarMessages.deleteSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}
